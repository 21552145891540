import React from 'react';
import { WEB_SITE_LINK, CLIENT_LINK, APP_LINK_ANDROID, APP_LINK_IOS, APP_LINK } from 'common_constants/routes';
import { translation } from 'common_constants/translation';
import EAdvokat from '../../images/e-advokat.png';

import appstoreImg from '../../images/appstore-app.svg';
import googleplayImg from '../../images/googleplay-app.svg';

export default function AboutEadvokat({ lang }) {
  return (
    <div className="eadvokat-wrapper">
      <div className="eadvokat-image-wrapper">
        <img
          className="eadvokat-image"
          src={EAdvokat}
          alt={translation.eAdvokatImageDescription[lang]}
          title={translation.eAdvokatImageDescription[lang]}
        />
      </div>
      <div>
        <div className="eAdvokat-logo-image-wrapper">
          <img alt="Logo_Image" src={'/logo.svg'}></img>
        </div>
        <h2>{translation._appTitle[lang]}</h2>
        <br />
        <p>{translation.yourCabinet[lang]}</p>
        <a rel="noopener noreferrer" target="_blank" href={CLIENT_LINK}>
          {CLIENT_LINK}
        </a>
        <br />
        <br />
        {translation.appForSmartphones[lang]}
        <br />
        <br />
        <div className="app-download">
          <a rel="noopener noreferrer" target="_blank" href={APP_LINK_ANDROID}>
            <div>
              <img className="app-download-link" src={googleplayImg} alt="googleplay" />
            </div>
          </a>
          <a rel="noopener noreferrer" target="_blank" href={APP_LINK_IOS}>
            <div>
              <img className="app-download-link" src={appstoreImg} alt="appstore" />
            </div>
          </a>
        </div>
      </div>
      <p>{translation.hotlineMeetInviteCardText12[lang]}</p>
    </div>
  );
}
