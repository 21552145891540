import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { translation } from 'common_constants/translation';
import { PERSONAL_CONSULTANT_CHAT } from 'common_constants/modals';
import { CLUB_CARDS_TYPES } from 'common_constants/business';
import { CLIENT_ROUTES } from 'common_constants/routes';

import { setManagerInfo, setModal } from '../../store/commonReducer';
import { UserAvatar } from '../../components';
import { error, request } from '../../tools';
import aiAssistantImg from '../../images/woman-ai-assistant.png';

const PersonalConsultantChatItem = ({ loading }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const lang = useSelector((state) => state.common.language);
  const personalConsultantChat = useSelector((state) => state.common.personalConsultantChat);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const managerInfo = useSelector((state) => state.common.managerInfo);
  const lastMessage = personalConsultantChat?.messages?.[personalConsultantChat?.messages.length - 1];
  const hasOnlySilverCard = !clientInfo?.clubCard || ![CLUB_CARDS_TYPES.GOLD, CLUB_CARDS_TYPES.PLATINUM].includes(clientInfo?.clubCard?.cardType);

  const openPersonalConsultantChatItem = () => {
    if (hasOnlySilverCard) {
      history.push(CLIENT_ROUTES.CLIENT_QUICK_CHAT.route);
      return;
    }

    dispatch(setModal({ name: PERSONAL_CONSULTANT_CHAT }));
  };

  const getManagerData = () => {
    request(
      '/filii/getFilManagerInfoByFil',
      { clientFil: clientInfo.f },
      ({ data }) => {
        dispatch(setManagerInfo(data));
      },
      error,
    );
  };

  useEffect(() => {
    if (!clientInfo?._id || managerInfo?._id) return;

    getManagerData();
  }, [clientInfo?._id]);

  return (
    <div className="chats" onClick={openPersonalConsultantChatItem}>
      <UserAvatar
        customUrl={
          hasOnlySilverCard
            ? aiAssistantImg
            : managerInfo?.upa
            ? process.env.REACT_APP_API + `/avatars/${managerInfo.upa}_200.jpeg`
            : 'https://th.bing.com/th/id/OIP.NmdbjgnCEbGC6o4A43nhJgHaHs?pid=ImgDet&w=195&h=202&c=7&dpr=1.5'
        }
        size={50}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexShrink: 0 }}
      />
      <div className="info" style={{ flexDirection: hasOnlySilverCard ? 'row' : 'column', alignItems: hasOnlySilverCard ? 'center' : 'flex-start' }}>
        <span className="name">{hasOnlySilverCard ? translation.aiAssistantTitle[lang] : translation.personalConsultantChatTitle[lang]}</span>
        {loading ? (
          <div className="spinner-opacity"></div>
        ) : (
          <span className="message">
            {hasOnlySilverCard
              ? ''
              : lastMessage?.message
              ? lastMessage?.message
              : lastMessage?.fileId
              ? translation.file[lang]
              : translation.notMessages[lang]}
          </span>
        )}
      </div>
    </div>
  );
};

export default PersonalConsultantChatItem;
