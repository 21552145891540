import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { request, error } from '../../tools';
import { Button, Spin } from '../../components';
import PaymentManagementBox from './PaymentManagementBox';
import { CURRENCIES, PAYMENT_STATUS } from 'common_constants/business';

import './PaymentManagement.scss';
import PaymentManagmentInfo from './PaymentManagmentInfo';
import { CheckClientInfo, usePayContractDebt, useToaster } from '../../tools/hooks';
import reactNativeService from '../../tools/reactNativeService';
import DebtContractItem from '../DebtContractItem';

const PaymentManagement = ({ contract, totalBalance, expandDetails = () => {}, debtContract }) => {
  const lang = useSelector((state) => state.common.language);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const currentCurrency = clientInfo?.currency ?? CURRENCIES.UAH;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [totalPayByClient, setTotalPayByClient] = useState(0);
  const [remainingMoney, setRemainingMoney] = useState(0);
  const [isPaidStatus, setIsPaidStatus] = useState({
    firstPayment: false,
    monthlyPayments: [],
    paymentManagement: [],
    assignments: [],
  });
  const paymentManagement = contract?.paymentManagement || [];
  const monthlyPayments = contract?.monthlyPaymentsManagement || [];
  const assignments = contract?.assignments?.filter((item) => !item?.incorrect && dayjs(item?.date) > dayjs(contract?.md).startOf('day')) || [];

  const countRemainingMoney = () => {
    setLoading(true);

    const reqData = {
      contractI: contract?.i,
    };

    request(
      '/contracts/paymentManagementRemainingMoneyClient',
      reqData,
      ({ data }) => {
        setIsPaidStatus(data?.paymentManagementPaid);
        setRemainingMoney(data?.remainingMoney);
        setTotalPayByClient(data?.totalClientPaid);
        setLoading(false);
      },
      () => {
        setLoading(false);
        error();
      },
    );
  };

  useEffect(() => {
    countRemainingMoney();
  }, [debtContract]);

  const { success, error } = useToaster();
  const [selectedMonthlyPaymentsBoxes, setSelectedMonthlyPaymentsBoxess] = useState([]);
  const [selectedAssignmentsBoxes, setSelectedAssignmentsBoxes] = useState([]);
  const [toPay, setToPay] = useState(0);
  const addToPay = (amount) => {
    setToPay((prev) => prev + amount);
  };
  const removeToPay = (amount) => {
    setToPay((prev) => prev - amount);
  };

  const pay = async () => {
    setLoading(true);

    let totalAmount = 0;
    totalAmount += selectedMonthlyPaymentsBoxes.reduce((total, box) => total + Number(box.amount), 0);
    totalAmount += selectedAssignmentsBoxes.reduce((total, box) => total + Number(box.clientAmount), 0);
    const withCashback = selectedMonthlyPaymentsBoxes.every((box) => dayjs().isBefore(dayjs(box.date).startOf('day')));

    const paymentData = {
      clientId: contract?.C,
      amount: totalAmount,
      contractId: contract?._id,
      contractIndex: contract?.i,
      fil: contract?.f,
      withCashback,
      currency: currentCurrency,
      payFor: {
        monthlyPayments: selectedMonthlyPaymentsBoxes,
        assignments: selectedAssignmentsBoxes,
      },
    };

    const handlePaymentStatus = (res, timerId) => {
      if (res?.paymentStatus === PAYMENT_STATUS.SUCCESS) {
        clearInterval(timerId);
        setLoading(false);
        CheckClientInfo(dispatch);
        countRemainingMoney();
        success(translation.paymentSuccess[lang]);
      } else if (res?.paymentStatus === PAYMENT_STATUS.FAILURE) {
        clearInterval(timerId);
        setLoading(false);
        error(translation.paymentError[lang]);
      }
    };

    const checkPaymentStatus = (invoiceId) => {
      const timerId = setInterval(() => {
        request('/payment/mono/status', { invoiceId }, (res) => handlePaymentStatus(res, timerId));
      }, 1000);
    };

    const onPaymentSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location = res.checkoutUrl;
        return;
      }
      if (res?.paymentStatus === PAYMENT_STATUS.SUCCESS) {
        setTimeout(() => {
          CheckClientInfo(dispatch);
          countRemainingMoney();
          setLoading(false);
          success(translation.paymentSuccess[lang]);
        }, 2000);
      } else if (res?.paymentStatus === PAYMENT_STATUS.PROCESSING) {
        checkPaymentStatus(res.invoiceId);
      } else if (res?.paymentStatus === PAYMENT_STATUS.FAILURE) {
        setLoading(false);
        error(translation.paymentError[lang]);
      } else {
        success(translation.paymentCheckoutUrlGenerated[lang]);
        setLoading(false);
      }
    };

    try {
      await request('/payment/mono/payContractInAdvance/link', paymentData, onPaymentSuccess);
    } catch (err) {
      error(translation.paymentError[lang]);
      setLoading(false);
      console.log(err);
    } finally {
      setToPay(0);
      setSelectedMonthlyPaymentsBoxess([]);
    }
  };

  const { payContractDebt, isPending } = usePayContractDebt();
  const handlePayContractDebt = ({ contract, clientInfo, lang }) => {
    payContractDebt({ contract, clientInfo, lang });
  };
  return (
    <div className="payment-management-comp">
      <p className={`${totalBalance ? 'totalBalance-management-title' : 'payment-management-title'}`}>{translation.paymentManagement[lang]}:</p>
      <Spin spinning={loading}>
        <PaymentManagmentInfo
          totalPayByClient={totalPayByClient}
          currentCurrency={currentCurrency}
          isPaidStatus={isPaidStatus}
          contract={contract}
          lang={lang}
        />
        {!!debtContract && (
          <DebtContractItem
            contract={debtContract}
            payContractDebt={handlePayContractDebt}
            clientInfo={clientInfo}
            lang={lang}
            disabled={isPending}
          />
        )}
        {paymentManagement?.length > 0 ? (
          <div className="payment-management-block">
            <div className="payment-management-block-info">
              <p>
                {translation.installment[lang]}: {contract?.pa - contract?.fp ?? 0}
                {CURRENCIES[currentCurrency].symbol}
              </p>
              <p>
                {translation.numberOfMonths[lang]}: {contract?.installmentMonths ?? 0}
              </p>
            </div>
            <div className="management-wrapper">
              {paymentManagement?.map((item, index) => (
                <PaymentManagementBox key={index} amount={item.amount} date={item.date} isPaid={isPaidStatus?.paymentManagement?.[index]} />
              ))}
            </div>
          </div>
        ) : null}
        {assignments?.length > 0 ? (
          <div className="payment-management-block">
            <div className="payment-management-block-info">
              <p>{translation.assignments[lang]}:</p>
            </div>
            <div className="management-wrapper">
              {assignments?.map((item, index) => (
                <PaymentManagementBox key={index} amount={item.clientAmount} date={item.date} isPaid={isPaidStatus?.assignments?.[index]} />
              ))}
              {/* <Assigments
                selectedBoxes={selectedAssignmentsBoxes}
                setSelectedBoxes={setSelectedAssignmentsBoxes}
                assignments={assignments}
                isPaidStatus={isPaidStatus}
                addToPay={addToPay}
                removeToPay={removeToPay}
              /> */}
            </div>
          </div>
        ) : null}
        {monthlyPayments?.length > 0 ? (
          <div className="payment-management-block">
            <div className="payment-management-block-info">
              <p>
                {translation.monthlyPayment[lang]}: {contract?.monthlyFee ?? 0}
                {CURRENCIES[currentCurrency]?.symbol}
              </p>
            </div>
            <div className="management-wrapper">
              <MonthlyPayments
                expandDetails={expandDetails}
                selectedBoxes={selectedMonthlyPaymentsBoxes}
                setSelectedBoxes={setSelectedMonthlyPaymentsBoxess}
                monthlyPayments={monthlyPayments}
                isPaidStatus={isPaidStatus}
                addToPay={addToPay}
                removeToPay={removeToPay}
              />
            </div>
          </div>
        ) : null}
        {toPay !== 0 && (
          <Button onClick={pay} disabled={loading}>
            {translation.payBtn[lang]}: {toPay}
            {CURRENCIES[currentCurrency].symbol}
          </Button>
        )}
        <div className={clsx('remaining-money', remainingMoney > 0 ? 'overpayment' : remainingMoney < 0 ? 'debt' : '')}>
          <p>
            {remainingMoney >= 0 ? translation.overpayment[lang] : translation.debt[lang]}:{' '}
            <span>
              {Math.abs(remainingMoney)}
              {CURRENCIES[currentCurrency].symbol}
            </span>
          </p>
        </div>
      </Spin>
    </div>
  );
};

const MonthlyPayments = ({ selectedBoxes, expandDetails, setSelectedBoxes, monthlyPayments, isPaidStatus, addToPay, removeToPay }) => {
  const handleSelect = (item) => {
    expandDetails();
    if (selectedBoxes?.find((box) => box.date === item.date)) {
      removeToPay(item.amount);
      setSelectedBoxes(selectedBoxes.filter((box) => box.date !== item.date));
    } else {
      addToPay(item.amount);
      setSelectedBoxes([...selectedBoxes, item]);
    }
  };

  return (
    <>
      {monthlyPayments?.map((item, index) => (
        <PaymentManagementBox
          key={index}
          amount={item.amount}
          date={item.date}
          isPaid={isPaidStatus?.monthlyPayments?.[index]}
          isSelected={selectedBoxes?.some((box) => box.date === item.date)}
          onClick={() => handleSelect(item)}
        />
      ))}
    </>
  );
};
const Assigments = ({ selectedBoxes, setSelectedBoxes, assignments, isPaidStatus, addToPay, removeToPay }) => {
  const handleSelect = (item) => {
    if (selectedBoxes?.find((box) => box.date === item.date)) {
      removeToPay(Number(item.clientAmount));
      setSelectedBoxes(selectedBoxes.filter((box) => box.date !== item.date));
    } else {
      addToPay(Number(item.clientAmount));
      setSelectedBoxes([...selectedBoxes, item]);
    }
  };

  return (
    <>
      {assignments?.map((item, index) => (
        <PaymentManagementBox
          key={index}
          amount={item.clientAmount}
          date={item.date}
          isPaid={isPaidStatus?.assignments?.[index]}
          isSelected={selectedBoxes?.some((box) => box.date === item.date)}
          onClick={() => handleSelect(item)}
        />
      ))}
    </>
  );
};

export default PaymentManagement;
