import { useState } from 'react';
import QRCode from '../QRCode/QRCode';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import { CLUB_CARDS_TYPES } from 'common_constants/business';

import CARDFRONTSILVER from '../../images/club-card-front-silver.jpg';
import CARDFRONTGOLD from '../../images/club-card-front-gold.jpg';
import CARDFRONTPLATINUM from '../../images/club-card-front-platinum.jpg';

import './ClubCard.scss';

const ClubCard = ({ cardType }) => {
  const lang = useSelector((state) => state.common.language);

  let cardImageFront;
  switch (cardType) {
    case CLUB_CARDS_TYPES.GOLD:
      cardImageFront = CARDFRONTGOLD;
      break;
    case CLUB_CARDS_TYPES.PLATINUM:
      cardImageFront = CARDFRONTPLATINUM;
      break;
    default:
      cardImageFront = CARDFRONTSILVER;
  }

  return (
    <div className="club-card">
      <h3 className="title">{translation.clubCardTitleFront[lang]}</h3>
      <p className="type-card">{translation.clubCardType[cardType.toLowerCase()][lang]}</p>
      <h3 className="text">{translation.clubCard[lang]}</h3>
      <img src={cardImageFront} alt="" className="img" />
    </div>
  );
};

export default ClubCard;
