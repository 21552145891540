import { useState } from 'react';
import ModalWindow from '../../Modals/ModalWindow';
import { translation } from 'common_constants/translation';
import { useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';

import { Spin } from '../../components';

import SendEmail from '../../Modals/AddClientEmail/SendEmail.jsx';
import ConfirmEmail from '../../Modals/AddClientEmail/ConfirmEmail';
import PolicyConfirm from '../../Modals/PolicyConfirm/PolicyConfirm.jsx';

import Button from '../Button/Button.jsx';
import './MandatoryConfirmationEmail.scss';

const MandatoryConfirmationEmail = ({ text }) => {
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const lang = useSelector((state) => state.common.language);
  const { _id, token, email, emailVerified, phoneVerified, policyConfirmed } = clientAuth;
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  if (pathname.match(/^\/.{24}$/)) return;

  const emailVerifiedBtnClick = () => {
    setOpen(true);
  };

  return (
    <>
      {' '}
      <div className="MandatoryConfirmationEmail-main-wrapper">
        <div className="MandatoryConfirmationEmail-wrapper">
          <h3 className="title">{translation.emailNotVerifiedTitle[lang]}</h3>
          <p className="text">{text}</p>
          <div className="button">
            <Button children={translation.verifyEmailButton[lang]} onClick={emailVerifiedBtnClick} />
          </div>
        </div>
      </div>
      <ModalWindow title={translation.emailAuthenticationTitle[lang]} setModal={setOpen} modal={open}>
        <Spin tip={translation.loader[lang]} spinning={loading} renderWrapped>
          {email && !emailVerified ? (
            <ConfirmEmail token={token} email={email} clientId={_id} setLoading={setLoading} />
          ) : (
            <SendEmail token={token} setLoading={setLoading} />
          )}
          {!policyConfirmed && emailVerified && phoneVerified ? <PolicyConfirm setModal={setOpen} /> : null}
        </Spin>
      </ModalWindow>
    </>
  );
};

export default MandatoryConfirmationEmail;
