import React, { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, NiceBtn } from 'common_components';
import { CLIENT_ROUTES } from 'common_constants/routes';
import { setModal, editContracts } from '../../store/commonReducer';
import Modal from '../../components/Modal';
import { Button } from '../../components';

import { translation } from 'common_constants/translation';
import { request, info, error, success } from '../../tools';
import { ADD_CLIENT_EMAIL, FIRST_LOGIN_OF_THE_CLIENT, FIRST_LOGIN_AUTO_PAY } from 'common_constants/modals';
import './styles.scss';

const QuestionnaireArchiveAdvertising = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { transaction } = useSelector((state) => state.common.modal) || {};
  const missingEmail = useSelector((state) => state.common.missingEmail);
  const lang = useSelector((state) => state.common.language);
  const filii = useSelector((state) => state.common.filii);
  const contract = useSelector((state) => state.common.contract);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const [fbBonus, setfbBonus] = useState(contract?.fbBonus ? contract.fbBonus : false);

  const findFil = (fil) => fil.i === contract?.f;
  const currentFilli = filii ? filii.find(findFil) : [];
  const isShowFbBonus = currentFilli?.feedback && transaction?.qa.sq >= 7 && transaction?.qa.as === 0 && transaction?.qa.ts === 0 && !fbBonus;

  const chainFirstModals = () =>
    missingEmail
      ? dispatch(setModal({ name: ADD_CLIENT_EMAIL }))
      : sessionStorage.getItem('modalFirst') !== 'true'
      ? dispatch(setModal({ name: FIRST_LOGIN_AUTO_PAY }))
      : dispatch(setModal());

  const onCancel = () => {
    info('', translation.questionnaireArchiveAdvertisingInfo[lang]);
    chainFirstModals();
  };

  const onClick = () => {
    dispatch(setModal());
    history.push(CLIENT_ROUTES.INVITE_FRIEND.route);
  };

  const onClickFeedback = () => {
    if (fbBonus) return;
    setfbBonus(true);

    const getFBBonus = {
      fbBonus: true,
      contractId: contract._id,
      clientInId: clientInfo._id,
      B: clientInfo.B,
    };
    const updateData = {
      fbBonus: true,
      _id: contract._id,
    };

    const onSuccess = () => {
      dispatch(editContracts(updateData));
      success(`${translation.bonusTransactionSuccess[lang]}`);
    };
    const onError = () => {
      error(`${translation.bonusTransactionFailure[lang]}`);
    };

    request('/contracts/gettingFeedbackBonus', getFBBonus, onSuccess, onError);

    window.open(currentFilli?.feedback);
  };

  return (
    <Modal className="questionnaire-archive-advertising" title="" open onCancel={onCancel} footer={null}>
      <Box
        style={{
          borderBottom: '1px solid #040404',
          paddingBottom: '16px',
          marginBottom: '16px',
        }}
      >
        {translation.questionnaireArchiveAdvertisingText1[lang]}
        {!isShowFbBonus && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px', marginTop: '10px' }}>
            <b>
              {translation.questionnaireArchiveAdvertisingFeedbackText1_1[lang]}{' '}
              <span style={{ color: 'green' }}>{translation.questionnaireArchiveAdvertisingFeedbackText1_2[lang]}</span>{' '}
              {translation.questionnaireArchiveAdvertisingFeedbackText1_3[lang]}
            </b>
            <Button style={{ width: 'auto', padding: '0 4px' }} onClick={onClickFeedback}>
              {translation.agree[lang]}
            </Button>
          </div>
        )}
        {fbBonus && (
          <p>
            <b>{translation.questionnaireArchiveAdvertisingFeedbackText1_4[lang]} </b>
          </p>
        )}
      </Box>
      <b>{translation.questionnaireArchiveAdvertisingText2[lang]}</b>
      <br />
      <br />
      <b>
        {translation.questionnaireArchiveAdvertisingText3[lang]} <span style={{ color: 'green' }}>300 {translation.currency[lang]}</span>{' '}
        {translation.questionnaireArchiveAdvertisingText3_1[lang]}
      </b>
      <br />
      <br />
      {translation.questionnaireArchiveAdvertisingText4_1[lang]} <b>{translation.questionnaireArchiveAdvertisingText4_2[lang]}</b>{' '}
      {translation.questionnaireArchiveAdvertisingText3[lang]} <b> {translation.questionnaireArchiveAdvertisingText4_4[lang]}</b>{' '}
      {translation.questionnaireArchiveAdvertisingText4_5[lang]}
      <br />
      <br />
      <b>{translation.questionnaireArchiveAdvertisingText5[lang]}</b>
      <br />
      <br />
      <div className="video_container">
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/-6uU4WrKsIk?autoplay=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <Box
        style={{
          textAlign: 'center',
          margin: '24px auto 8px',
        }}
      >
        <NiceBtn onClick={onClick}>{translation.learnMoreBtn[lang]}</NiceBtn>
      </Box>
    </Modal>
  );
};

export default QuestionnaireArchiveAdvertising;
