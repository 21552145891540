import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { ClockCircleFilled } from '@ant-design/icons';
import { POSLUGI } from 'common_constants/business';
import { getCurrentMounth } from 'common_components/src/dateUtils';
import { QUESTIONNAIRE_HOTLINE, PAYMENT_FRAME } from 'common_constants/modals';
import { CLIENT_ROUTES } from 'common_constants/routes';
import { translation } from 'common_constants/translation';

import Button from '../../Button';
import Col from '../../Col';
import Tag from '../../Tag';
import GoogleMap from '../../GoogleMap';
import UserAvatar from '../../UserAvatar';
import reactNativeService from '../../../tools/reactNativeService';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { setModal } from '../../../store/commonReducer';
import { request } from '../../../tools';
import { PAYMENT_STATUS } from 'common_constants/business';

const ClientMainPageHotlineItem = ({
  fil,
  address,
  type,
  startT = '',
  endT = '',
  date,
  hotlineType,
  consultant,
  statusOfMeet,
  transactions = [],
  paymentUrl,
  cost,
  qh,
  s,
  H,
  chn,
  setOpen,
  isPending,
  hasViewedNotifications,
  setHotlineId,
  id,
  i,
  inProcess,
  vip,
  clubCard,
  paymentByClubCard,
  isPaidByClubCard,
  isMoneyOnBalance,
  isPartner,
}) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const [justPaid, setJustPaid] = useState({ inProcess, status: PAYMENT_STATUS.PROCESSING });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!justPaid.inProcess || !i) return;

    request('/hotline/getByI', { i }, async (res) => {
      const checkPaymentStatus = async () => {
        await request('/payment/mono/status', { invoiceId: res.data.transactions[0].order_id }, (response) => {
          if (response.paymentStatus === PAYMENT_STATUS.PROCESSING) {
            // If the payment is still processing, call the function again after 10 seconds
            setTimeout(checkPaymentStatus, 5000);
          }
          setJustPaid({ isProcess: false, status: response.paymentStatus });
        });
      };

      // Start the first check
      checkPaymentStatus();
    });
  }, []);

  const hasPaidConsultation = transactions?.[0]?.amount >= cost;
  const correntMonth = getCurrentMounth(clubCard?.createdAt, clubCard?.expiredAt);
  const isNewCard = clubCard?.services?.[1][0] >= 0;
  const consultationType = vip ? '4' : type === POSLUGI[1] ? '3' : '1';
  const isclubCardActiv =
    correntMonth && isNewCard ? clubCard?.services[correntMonth][consultationType] > 0 : clubCard?.services[consultationType] > 0;

  let textButtonPayByClubCard;

  switch (consultationType) {
    case '4':
      textButtonPayByClubCard = translation.consultation_button_text_2[lang];
      break;

    case '3':
      textButtonPayByClubCard = translation.consultation_button_text_3[lang];
      break;

    default:
      textButtonPayByClubCard = translation.consultation_button_text_1[lang];
      break;
  }
  const handleOpenPaymentFrame = () => {
    window.location = paymentUrl;
  };

  const handleClubCardDebits = async () => {
    setLoading(true);
    await paymentByClubCard?.(vip, type, correntMonth, id);
    setLoading(false);
  };

  const linkContent = (
    <Col className="client-hotlines__map__info">
      {consultant?.name && (
        <div className="client-hotlines__history__info-header">
          <Link className="curator__name-wrapper" to={`/cv/${consultant?._id}`}>
            <UserAvatar
              style={{ width: '32px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 8 }}
              user={consultant}
            />
          </Link>
          <span className="curator-purple">{consultant?.name}</span>
        </div>
      )}
      <div className="client-hotlines__map__info-time">
        <span>
          {translation.typeConsultation[lang]} {type}
        </span>
        <span>{date}</span>
      </div>
      <div className="client-hotlines__map__info-place">
        <span children={fil} />
        <span children={[String(startT), ' - ', String(endT)]} />
      </div>
      <div className="client-hotlines__map__info-address">{address}</div>
      {isPending && (
        <div className="pending">
          <ClockCircleFilled /> {translation.clientMPHItemClockCircleFilled[lang]}
        </div>
      )}
    </Col>
  );

  return (
    <>
      {hotlineType === 'CURRENT' ? (
        <div
          className={clsx({
            'client-hotlines__map': true,
            hasViewedNotifications: hasViewedNotifications,
          })}
        >
          {isPartner ? (
            <div>{linkContent}</div>
          ) : (
            <Link className="hotline-link" to={`/${H}`}>
              {linkContent}
            </Link>
          )}
          <div style={{ padding: '0 32px 32px' }}>
            {!justPaid.inProcess && !hasPaidConsultation && !paymentByClubCard && !isMoneyOnBalance && (
              <Button className="btn-gratitude" onClick={handleOpenPaymentFrame}>
                {translation.payConsultation[lang]}
              </Button>
            )}
            {!justPaid.inProcess && isclubCardActiv && !hasPaidConsultation && !isPaidByClubCard && !isMoneyOnBalance && !isPartner && (
              <Button loading={loading} className="btn-gratitude" onClick={handleClubCardDebits}>
                {textButtonPayByClubCard}
              </Button>
            )}
            {!justPaid.inProcess && !isclubCardActiv && !hasPaidConsultation && !isPartner && (
              <>
                <h4 className="club-card-info">{translation.buy_club_card_slogan[lang]} </h4>
                <Link to={CLIENT_ROUTES.CLUB_CARD_PAGE.route}>
                  <Button className="btn-gratitude">{translation.buy_club_card_btn[lang]}</Button>
                </Link>
              </>
            )}
            {justPaid.inProcess ? (
              <Fragment>
                <br />
                {justPaid.status === PAYMENT_STATUS.PROCESSING && <Tag color="processing" children={translation.inProgressConsultation[lang]} />}
                {justPaid.status === PAYMENT_STATUS.SUCCESS && <Tag color="success" children={translation.payedConsultation[lang]} />}
              </Fragment>
            ) : (
              <Fragment>
                {!isPending && hasPaidConsultation && (
                  <>
                    <br />
                    <Tag color="success" children={translation.payedConsultation[lang]} />
                  </>
                )}
                {!isPending && isPaidByClubCard && (
                  <>
                    <br />
                    <Tag color="success" children={translation.payByClubCardSuccess[lang]} />
                  </>
                )}
              </Fragment>
            )}
          </div>

          {!isPending && <GoogleMap address={address} />}
        </div>
      ) : (
        <div
          className={clsx({
            'client-hotlines__history': true,
            hasViewedNotifications: hasViewedNotifications,
          })}
        >
          <div className="client-hotlines__history__info">
            <Link className="hotline-link" to={`/${H}`}>
              <div className="client-hotlines__history__info-tags">
                <Tag
                  color={statusOfMeet === '1' ? 'success' : 'error'}
                  children={translation[statusOfMeet === '1' ? 'okConsultation' : 'missedConsultation'][lang]}
                />

                {transactions?.length > 0 && (
                  <Tag color="success">
                    {translation.payedTag[lang]} ₴{transactions[0]?.amount}
                  </Tag>
                )}
              </div>
              {consultant?.name && (
                <div className="client-hotlines__history__info-header">
                  <Link className="curator__name-wrapper" to={`/cv/${consultant?._id}`}>
                    <UserAvatar
                      style={{ width: '32px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 8 }}
                      user={consultant}
                    />
                  </Link>
                  <span className="curator-purple">{consultant?.name}</span>
                </div>
              )}
              <br />
              <div className="client-hotlines__history__info-time">
                <span>
                  {translation.typeConsultation[lang]} {type}
                </span>
                <span>{date}</span>
              </div>
              <div className="client-hotlines__history__info-place">
                <span children={fil} />
                <span children={[String(startT), ' - ', String(endT)]} />
              </div>
              <div className="client-hotlines__history__info-address">{address}</div>
            </Link>

            {s === '1' && !qh && !isPartner && (
              <Button className="btn-gratitude" onClick={() => dispatch(setModal({ name: QUESTIONNAIRE_HOTLINE, data: { H, chn, filName: fil } }))}>
                {translation.quality[lang]}
              </Button>
            )}

            <br />
            {qh && <Tag color="success">{translation.qualityOk[lang]}</Tag>}

            {s === '1' && !isPartner && (
              <Button
                className="btn-gratitude"
                onClick={() => {
                  setOpen(true);
                  setHotlineId(id);
                }}
              >
                {translation.giveTipsToLawyer[lang]}
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ClientMainPageHotlineItem;
