import React from 'react';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import './GoogleMap.scss';

const GoogleMap = ({ address }) => {
  const lang = useSelector((state) => state.common.language);

  return (
    <a className="google-map" rel="noreferrer" target="_blank" href={'https://www.google.com/maps/dir/?api=1&destination=' + address}>
      <iframe
        title={translation.googleMapTitle[lang]}
        style={{ pointerEvents: 'none' }}
        className="google-map__map"
        width="100%"
        height="250"
        id="gmap_canvas"
        src={`https://maps.google.com/maps?q=${address}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
        loading="lazy"
      ></iframe>
    </a>
  );
};

export default GoogleMap;
