import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';

import { request } from '../../tools';
import { Button, Modal, InputNew, Input } from '../../components';
import { setModal } from '../../store/commonReducer';

import './ApproveDeleteAccountModal.scss';

const ApproveDeleteAccountModal = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const { _id: clientId, ph } = useSelector((state) => state.common.clientInfo);

  const [approve, setApprove] = useState('');

  const removeAccount = () => {
    request('/auth/clientRemoveAccaunt', { _id: clientId }, () => {
      window.localStorage.removeItem('clientAccessToken');
      window.location.href = '/';
    });
  };

  const handleCloseModal = () => {
    dispatch(setModal());
  };

  return (
    <Modal
      title={translation.accountDeletionModalTitle[lang]}
      open
      onCancel={handleCloseModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      className="approve-delete-account-modal"
    >
      <p className="text">{translation.accountDeletionModalEnterPhone[lang]}</p>
      <Input
        type="text"
        placeholder={translation.accountDeletionModalEnterPhonePlaceholder[lang]}
        value={approve}
        onChange={(e) => setApprove(e.target.value)}
      />

      {approve && approve !== ph && <p style={{ color: 'red' }}>{translation.accountDeletionModalEnterPhoneError[lang]}</p>}
      <Button disabled={ph !== approve} onClick={removeAccount}>
        {translation.deleteBtn[lang]}
      </Button>
    </Modal>
  );
};
export default ApproveDeleteAccountModal;
