import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Spin from '../../components/Spin';
import RHForm from '../../components/RHForm';

import { MailOutlined } from '@ant-design/icons';
import { ZahistCred } from 'common_components';
import { CLIENT_ROUTES } from 'common_constants/routes';
import { translation } from 'common_constants/translation';
import { useSelector } from 'react-redux';

import { warn, success, request } from '../../tools';

const ClientChangePass = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isEmailSended, setIsEmailSended] = useState(false);
  const lang = useSelector((state) => state.common.language);

  const onSubmitEmail = (data) => {
    setLoading(true);
    console.log(data.email);
    fetch(process.env.REACT_APP_API + '/auth/requestChangePass', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: data.email }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          setLoading(false);
          setIsEmailSended(true);
          return;
        }
        setLoading(false);
        warn(translation.clientChangePassWarn1[lang], ' ');
      })
      .catch((err) => console.error(err));
  };

  const recoverByPhone = async (data) => {
    setLoading(true);
    await request(
      '/auth/recoverPassByPhone',
      data,
      (res) => {
        if (res.limit) warn(translation.clientChangePassWarn2_1[lang], translation.clientChangePassWarn2_2[lang]);
        else success(translation.clientChangePassSuccess[lang]);

        history.push(CLIENT_ROUTES.CLIENT_LOGIN.route);
      },
      () => {
        warn(translation.clientChangePassWarn3_1[lang], translation.clientChangePassWarn3_2[lang]);
      },
    );

    setLoading(false);
  };

  return (
    <div className="clientLogin_page">
      <div className="auth_page" style={{ margin: '8px' }}>
        <div className="menu">
          <div className="menu-wrapper">
            <MailOutlined />
            <p className="title"> {translation.clientChangePassMenuItem[lang]}</p>
          </div>
        </div>
        <Spin spinning={loading}>
          {isEmailSended ? (
            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{translation.clientCPIsEmailSended[lang]}</div>
          ) : (
            <>
              <RHForm onFinish={onSubmitEmail} className="email-form">
                {[
                  {
                    name: 'email',
                    rHFormOptions: {
                      required: translation.clientCPEmailMessage2[lang],
                      pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: translation.clientCPEmailMessage1[lang] },
                    },
                    options: { label: 'Email' },
                  },
                  {
                    name: 'submit',
                    options: { value: translation.send[lang] },
                  },
                ]}
              </RHForm>
            </>
          )}
          <RHForm onFinish={recoverByPhone}>
            {[
              {
                name: 'phone',
                rHFormOptions: {
                  required: translation.requiredField[lang],
                  maxLength: {
                    value: 10,
                    message: translation.clientLoginPhoneMes[lang],
                  },
                },
                options: { label: translation.phone[lang] },
              },
              {
                name: 'submit',
                options: { value: translation.clientCPPhoneBtn[lang] },
              },
            ]}
          </RHForm>
          <div style={{ textAlign: 'center' }}>
            {translation.clientCPQuestionLink[lang]} <Link to={CLIENT_ROUTES.CLIENT_LOGIN.route}>{translation.enterLink[lang]}</Link>
          </div>
        </Spin>

        <ZahistCred />
      </div>
    </div>
  );
};

export default ClientChangePass;
