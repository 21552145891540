import React from 'react';
import { useState, useEffect } from 'react';

import './ShowPassword.scss';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const ShowPassword = ({ inputName }) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const changeInputType = () => {
    setPasswordVisible((prev) => !prev);
  };

  useEffect(() => {
    const passwordInput = document.getElementsByName(inputName)[0];

    isPasswordVisible ? passwordInput.setAttribute('type', 'text') : passwordInput.setAttribute('type', 'password');
  }, [isPasswordVisible]);

  return (
    <div onClick={changeInputType} className="show-password-icon">
      {!isPasswordVisible ? <EyeOutlined></EyeOutlined> : <EyeInvisibleOutlined></EyeInvisibleOutlined>}
    </div>
  );
};

export default ShowPassword;
