import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import { CHAT_FOR_CONTRACT, QUESTIONNAIRE_HOTLINE } from 'common_constants/modals';
import { translation } from 'common_constants/translation';
import { getFormattedDateWithRelativeDays } from 'common_components';
import {
  NOTIFICATION,
  ASSIGNMENTS_SERVICES_TYPE,
  PHONE_OFFICE_VIEW_CRM_SHORT,
  SERVICE_PAYMENT_TYPE,
  RED_BUTTON,
  CHAT_TYPES,
  PAYMENT_STATUS,
  SERVICE_PAYMENT_TYPE_FOR_EADVOKAT,
} from 'common_constants/business';
import clsx from 'clsx';

import { fetchHotlines, fetchRemoveNotifications } from '../ClientContracts/ClientContractsHelpers';
import ModalWindow from '../../Modals/ModalWindow';
import { setHotlines, editContracts, setModal, setContract } from '../../store/commonReducer';
import reactNativeService from '../../tools/reactNativeService';
import ContractTasks from '../../Modals/ContractTasks';
import AutoPayModal from '../../Modals/AutoPayModal/AutoPayModal';
import ComplaintForm from '../ClientContracts2/ComplaintForm';
import CuratorButton from './CuratorButton/CuratorButton';
import CuratorImage from './CuratorImage/CuratorImage';
import Details from './Details/Details';
import ClientPaymentManagement from '../../Modals/ClientPaymentManagement';

import { ClientBalancePayModal, ClientButton, Button } from '../../components';
import { countContractDebt, error, request, success } from '../../tools';
import { icon_call, icon_danger, icon_events, icon_message, icon_payments, icon_moneyRules, icon_tasks } from './images';
import { CorrespondenceBtnAndModal, TabBtnAndModal } from './GroupModalWindow';

import './ClientContractInfo.scss';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import 'dayjs/locale/ru';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/cs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import LastEvents from './LastEvents/LastEvents';
import DebtContractItem from '../../components/DebtContractItem';
import { CheckClientInfo, usePayContractDebt } from '../../tools/hooks';
import { CLIENT_ROUTES } from 'common_constants/routes';

dayjs.extend(isSameOrAfter);

const ClientContractInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const openChatParam = searchParams.get('open_chat');

  const [lang, contracts, clientInfo, hotlines, FILII, users] = [
    useSelector((state) => state.common.language),
    useSelector((state) => state.common.contracts) ?? [],
    useSelector((state) => state.common.clientInfo) ?? [],
    useSelector((state) => state.common.hotlines) ?? [],
    useSelector((state) => state.common.FILII) ?? [],
    useSelector((state) => state.common.usersData) ?? [],
  ];

  dayjs.locale(`${lang === 'ua' ? 'uk' : lang}`);

  const [open, setOpen] = useState(false); //For ComplaintForm
  const [transactionsModal, setTransactionsModal] = useState(false);
  const [autoPay, setAutoPay] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [openBalancePayModal, setOpenBalancePayModal] = useState(false);
  const [paymentManagementModal, setPaymentManagementModal] = useState(false);
  const [megaState, setMegaState] = useState({
    eventsModal: false,
    tasksModal: false,
    correspondenceModal: false,
  });
  const [loading, setLoading] = useState(false);
  const [debtContracts, setDebtContracts] = useState([]);

  const contractId = params.id;
  const contract = useMemo(() => contracts.find((i) => i._id === params.id), [contracts, contractId]);

  const paymentCard = clientInfo?.paymentCard ?? '';

  const complitedConsult = hotlines?.filter((item) => item.s).map((item) => ({ ...item, complited: 1 }));
  const curator = users?.find((user) => user._id === contract?.us);
  const assignments = contract?.assignments || [];

  const events = [...hotlines, ...assignments, ...complitedConsult].sort((a, b) => {
    const date = (i) => new Date(i.crAt || i.createdAt);
    const splitTime = (i) => i?.split(':');
    const timeStart = (i) => date(i).setHours(+splitTime(i.startT)[0], +splitTime(i.startT)[1], 0, 0);
    const timeEnd = (i) => date(i).setHours(+splitTime(i.endT)[0], +splitTime(i.endT)[1], 0, 0);
    const time = (i) => timeEnd(i) - timeStart(i);
    const complitedDate = (i) => date(i).setTime(date(i).getTime() + time(i));
    return (b.complited ? complitedDate(b) : date(b)) - (a.complited ? complitedDate(a) : date(a));
  });

  const [bargeNotificationEventsHotlines, bargeNotificationEventsAssignments, bargeNotificationEventsComplitedConsult] = [
    hotlines?.filter((item) => item.s)?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0,
    hotlines?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0,
    contract?.assignments?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0,
  ];
  const [bargeNotificationTasks, bargeNotificationTasksComments, bargeNotificationEvents] = [
    contract?.tasks?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0, //* Кількість непереглянутих задач
    contract?.tasks?.reduce((sum, item) => sum + (item?.comments?.reduce((acc, curr) => (curr.userNotSaw ? acc + 1 : acc), 0) ?? 0), 0), //* Кількість непереглянутих коментарів
    bargeNotificationEventsHotlines + bargeNotificationEventsAssignments + bargeNotificationEventsComplitedConsult, //* Кількість непереглянутих подій
  ]; //* filter watched for 3 element events

  const [removeNotifTasksClick, removeNotifEventsClick] = [
    async () => {
      if ((bargeNotificationTasks || bargeNotificationTasksComments >= 0) && contract?._id) {
        const notif = await fetchRemoveNotifications(NOTIFICATION.TASKS, contract._id, null, null);
        if (notif) {
          const _contract = {
            ...contract,
            tasks: notif ?? [],
          };

          dispatch(editContracts(_contract));
        }
      }
    },
    async () => {
      if (bargeNotificationEvents && clientInfo?.ph) {
        const notif = await fetchRemoveNotifications(
          NOTIFICATION.EVENTS,
          bargeNotificationEventsComplitedConsult ? contract._id : null,
          null,
          bargeNotificationEventsHotlines + bargeNotificationEventsAssignments ? clientInfo?.ph : null,
        );

        if (notif?.hotline) {
          const _hotlines =
            hotlines?.map((ho) => (ho = { ...ho, notif: Array.isArray(ho.notif) ? [...ho.notif, { watched: true }] : [{ watched: true }] })) ?? [];

          dispatch(setHotlines(_hotlines));
        }

        if (notif?.contract) {
          const _contract = {
            ...contract,
            assignments: notif.contract ?? [],
          };
          dispatch(editContracts(_contract));
        }
      }
    },
  ];

  const contractTransactions = useMemo(
    () => (clientInfo?.transactions?.filter((i) => contract._id === i.contractId && contract.amount !== 0) || [])?.reverse(),
    [clientInfo?.transactions, contract],
  );

  const openChat = () => {
    if (window?.ReactNativeWebView) {
      reactNativeService.sendMessageToWebview({ type: 'navigateToChat', data: { chatId: contractId, type: CHAT_TYPES.chatWithClient.key } });
      return;
    }

    dispatch(
      setModal({
        name: CHAT_FOR_CONTRACT,
        data: {
          contractId: contractId,
          contractTitle:
            translation.clientContractInfoChat[lang] +
            (contract?.cn ? (contract.cn === RED_BUTTON.value ? RED_BUTTON.label : contract.cn) : `№${contract.i}`),
          curator: curator?.name,
        },
      }),
    );
  };

  const openComplaint = () => setOpen(!open);

  const toCvPage = () => {
    history.push(`/cv/${curator?._id}`);
  };

  const transactionsModalList = contractTransactions.map((item) => (
    <div key={item._id} className="assignment">
      <div className="date-time">
        {getFormattedDateWithRelativeDays(item.date, lang)} - {dayjs(item.date).format('HH:mm')}
      </div>
      <div className="service-type">{SERVICE_PAYMENT_TYPE_FOR_EADVOKAT?.find((s) => s.value === item.type)?.translations[lang]}</div>
      <div className="comment">{item.amount}₴</div>
    </div>
  ));

  const timingFunc = (date, times) => {
    const isTimeString = typeof times === 'string' && /^\d{1,2}:\d{2}$/.test(times);

    const [validNewDate, validDayjs, validStringDate] = [
      date instanceof Date,
      dayjs(date).isValid(),
      typeof date === 'string' && date.trim() !== '' && !isNaN(new Date(date)),
    ];

    if (isTimeString) {
      const [hours, minutes] = times.split(':').map(Number);
      if (!isNaN(hours) && !isNaN(minutes) && hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
        date = dayjs(date).set('hour', hours).set('minute', minutes);
      }
    }

    const textTimeFixed = <div className="date-time">{`${getFormattedDateWithRelativeDays(date)} - ${dayjs(date).format('HH:mm')}`}</div>;

    return validNewDate || validDayjs || validStringDate ? textTimeFixed : null;
  };

  const eventsList = events.map((item, index) => {
    const consultResult =
      {
        1: { title: translation.clientCInfConsultResult1[lang], class: 'consult-succes' },
        2: { title: translation.clientCInfConsultResult2[lang], class: 'consult-failed' },
      }[item.s] || null;
    const hasViewedNotifications = !(item.notif?.some((notifItem) => notifItem.watched) ?? false);

    if (item.crAt && !item.complited) {
      return (
        <div
          key={item._id}
          className={clsx({
            consult: true,
            hasViewedNotifications: hasViewedNotifications,
          })}
        >
          {/* <span className={consultResult?.class}>{consultResult?.title}</span> */}
          <div className="consult-row">
            <span>{translation.clientCInfConsultTitle[lang]}</span> <span>{getFormattedDateWithRelativeDays(item.date)}</span>
          </div>
          <div className="consult-row">
            <span>{FILII[item.fil]}</span> <span>{item.startT}</span>
          </div>
        </div>
      );
    } else if (item.complited) {
      return (
        <div
          key={index}
          className={clsx({
            consult: true,
            hasViewedNotifications: hasViewedNotifications,
          })}
        >
          <div className="consult-row">
            <span className={consultResult?.class}> {consultResult?.title} </span>
            <span>{getFormattedDateWithRelativeDays(item.date)}</span>
          </div>
          <div className="consult-row">
            <span>{FILII[item.fil]}</span>
            <span>
              {item.startT} - {item.endT}
            </span>
          </div>
          {item.s === '1' && !item.qh && (
            <Button onClick={() => dispatch(setModal({ name: QUESTIONNAIRE_HOTLINE, data: { H: item._id, chn: item.chn } }))}>
              {translation.qualityArchiveBtn[lang]}
            </Button>
          )}
        </div>
      );
    } else if (item.createdAt) {
      return (
        <div
          key={item._id}
          className={clsx({
            assignment: true,
            hasViewedNotifications: hasViewedNotifications,
          })}
        >
          {timingFunc(item.date, item.selectedTime)}
          <div className="service-type">{ASSIGNMENTS_SERVICES_TYPE[item.serviceType]}</div>
          <div className="comment">{item.commentForClient}</div>
        </div>
      );
    } else return null;
  });

  useEffect(() => {
    dispatch(setContract(contract));
  }, []);

  useEffect(() => {
    setTasks(contract?.tasks || []);
  }, [contract]); //* Obtaining a list of tasks

  useEffect(() => {
    if (openChatParam) {
      openChat();
    }
    const getHotlines = async () => {
      const { hotlines } = await fetchHotlines();
      dispatch(setHotlines(hotlines));
    };
    getHotlines();
  }, []);
  const countContractsDebt = () => {
    // const notArchivedContracts = contracts.filter((contract) => !contract?.ad);
    setLoading(true);
    const onSuccess = (res) => {
      const debtContracts = res?.data?.filter((item) => item?.sumToPayWithoutAssignments > 0 || item?.assignmentsToPay?.length > 0) || [];
      setDebtContracts(debtContracts);
      setLoading(false);
    };

    const onError = (err) => {
      error(err);
      setLoading(false);
    };
    request('/contracts/calculateContractsDebt', { contracts }, onSuccess, onError);
  };

  const { payContractDebt, isPending } = usePayContractDebt();

  useEffect(() => {
    countContractsDebt();
  }, [contracts]);
  // todo: add 404 page
  if (!contract) return null;

  const contractButtonsActive = contract?.rb ? countContractDebt(contract) <= 0 : true;

  const [eventsOpenModal, tasksOpenModal] = [
    (value) => setMegaState((prev) => ({ ...prev, eventsModal: value })),
    (value) => setMegaState((prev) => ({ ...prev, tasksModal: value })),
  ];

  const [eventsCloseModal, tasksCloseModal] = [
    (value) => {
      removeNotifEventsClick();
      setMegaState((prev) => ({ ...prev, eventsModal: value }));
    },
    (value) => {
      removeNotifTasksClick();
      setMegaState((prev) => ({ ...prev, tasksModal: value }));
    },
  ];
  return (
    <>
      <section className="client-contract-info">
        <div className="client-contract-info__body">
          <div className="curator">
            <CuratorImage user={curator} redButton={!curator} onClick={curator && toCvPage} />

            <div className="curator__btns">
              {curator && (
                <>
                  <p>{translation.clientCInfCuratorTitle[lang]}</p>
                  <Link className="curator__name-wrapper" to={`/cv/${curator?._id}`}>
                    <span className="curator-purple">{curator?.name}</span>
                  </Link>
                </>
              )}

              <CuratorButton
                text={translation.clientCInfCuratorButton1[lang]}
                icon={icon_message}
                modal={contractButtonsActive ? () => openChat() : () => setOpenBalancePayModal(true)}
              />
              <CuratorButton
                text={translation.clientCInfCuratorButton2[lang]}
                icon={icon_call}
                modal={contractButtonsActive ? null : setOpenBalancePayModal}
                phoneNumber={contractButtonsActive && (curator?.userPersonalData?.workPhone || PHONE_OFFICE_VIEW_CRM_SHORT)}
              />
              <CuratorButton text={translation.clientCInfCuratorButton3[lang]} bg={'danger'} icon={icon_danger} modal={openComplaint} />
              {/* {contract?.mg && <CuratorButton text={'Оплата'} icon={icon_danger} modal={() => setAutoPay(true)} />} */}
            </div>
          </div>
          {debtContracts.filter((item) => item.contractIndex === contract.i).length > 0 && (
            <DebtContractItem
              disabled={isPending}
              contract={debtContracts.find((item) => item.contractIndex === contract.i)}
              payContractDebt={payContractDebt}
              clientInfo={clientInfo}
              lang={lang}
            />
          )}
          <LastEvents
            hotlines={hotlines}
            userId={clientInfo._id}
            tasks={tasks}
            users={users}
            FILII={FILII}
            contract={contract}
            contractTransactions={contractTransactions}
            eventsOpenModal={eventsOpenModal}
            tasksOpenModal={tasksOpenModal}
            openChat={openChat}
          />
          <Details contract={contract} curator={curator} />
          <div className="client-contract-info__menu">
            <ClientButton
              setModal={eventsOpenModal}
              text={translation.clientCInfCuratorButton5[lang]}
              icon={icon_events}
              badge={bargeNotificationEvents}
            />

            <CorrespondenceBtnAndModal />
            <ClientButton setModal={setAutoPay} text={translation.clientCInfCuratorButton7[lang]} icon={icon_moneyRules} badge={0} />
            <ClientButton
              setModal={tasksOpenModal}
              text={translation.clientCInfCuratorButton8[lang]}
              icon={icon_tasks}
              badge={bargeNotificationTasks + bargeNotificationTasksComments}
            />
            {!contract?.editPMonceAccess && (
              <>
                <ClientButton
                  setModal={setPaymentManagementModal}
                  text={translation.clientCInfCuratorButton11[lang]}
                  icon={icon_payments}
                  badge={0}
                />
                <ClientPaymentManagement
                  modal={paymentManagementModal}
                  setModal={setPaymentManagementModal}
                  contract={contract}
                  transactions={transactionsModalList}
                />
              </>
            )}
            <TabBtnAndModal />
            {/* <ClientButton setModal={setTransactionsModal} text={translation.clientCInfCuratorButton10[lang]} icon={icon_payments} badge={0} /> */}

            <ModalWindow modal={transactionsModal} setModal={setTransactionsModal} children={transactionsModalList} />
            <ModalWindow modal={megaState.eventsModal} setModal={eventsCloseModal} children={eventsList} />

            <AutoPayModal contracts={contracts} contract={contract} autoPay={autoPay} setAutoPay={setAutoPay} paymentCard={paymentCard} />

            <ContractTasks open={megaState.tasksModal} setOpen={tasksCloseModal} tasks={tasks} contractId={contract._id} />
            <ComplaintForm open={open} setOpen={setOpen} curator={curator} contractId={params.id} />
            <ClientBalancePayModal
              arrayPayment={[250, 500, 1000, 1500, 2500, 5000]}
              open={openBalancePayModal}
              setOpen={setOpenBalancePayModal}
              title={'Поповнення балансу'}
              placeholder={'Введіть суму поповнення'}
              buttonText={'Поповнити'}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientContractInfo;
