import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { List, AutoSizer, CellMeasurerCache } from 'react-virtualized';
import { tools } from 'common_components';
import { translation } from 'common_constants/translation';
import { CHAT_TYPES } from 'common_constants/business';

import { addNewMessageToContractChat, addPersonalConsultantChatMessage } from '../../store/commonReducer';
import { Spin, ContextMenu } from '..';
import { error } from '../../tools';
import UnverifiedPhoneOverlay from './UnverifiedPhoneOverlay';
import AddClientEmail from '../../Modals/AddClientEmail/AddClientEmail';
import ChatMessageWrapper from './ChatMessageWrapper';
import InputFormWrapper from './InputFormWrapper';

import './style.scss';

const Chat = ({
  onSendMessage,
  onSendFile,
  onGetMessages,
  onGetFile,
  heightFull = false,
  sendFileDirectly,
  chatId,
  chatType = CHAT_TYPES.chatWithClient.key,
  onEditMessage = () => {},
  isContractChat = false,
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const contextMenuRef = useRef(null);

  const lang = useSelector((state) => state.common.language);
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const personalConsultantChat = useSelector((state) => state.common.personalConsultantChat);

  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [sendLoader, setSendLoader] = useState(false);
  const [messagesLoader, setMessagesLoader] = useState(false);
  const [showPhoneVerify, setShowPhoneVerify] = useState(false);
  const [contextMenu, setContextMenu] = useState({
    position: {
      x: 0,
      y: 0,
    },
    toggled: false,
    messageData: {},
  });
  const [editMode, setEditMode] = useState({
    isEditing: false,
    messageId: '',
    prevMessageValue: '',
  });

  const isPersonalConsultantChat = chatType === CHAT_TYPES.personalConsultantChat.key;

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    }),
  );

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const makeMyFileMessage = ({ fileId, fileName, fileSize }) => ({
    fileId,
    fileName,
    fileSize,
    date: new Date(),
    sender: clientAuth._id,
    saw: [clientAuth._id],
    isSentByCurrentUser: true,
  });

  const handleSend = () => {
    if (inputValue === '') return;
    setSendLoader(true);
    setInputValue('');

    if (editMode.isEditing) {
      onEditMessage({
        messageId: editMode.messageId,
        message: inputValue,
        callback: (res) => {
          resetEditModeData();
          setSendLoader(false);
        },
      });

      return;
    }

    onSendMessage({
      message: inputValue,
      callback: (newMessage) => {
        if (isPersonalConsultantChat) {
          dispatch(addPersonalConsultantChatMessage(newMessage));
        } else if (isContractChat) {
          dispatch(addNewMessageToContractChat({ chatId: chatId, data: newMessage }));
        } else {
          setMessages((prev) => [...prev, newMessage]);
        }
        setSendLoader(false);
      },
    });
  };

  const handleAttachFile = (event) => {
    const files = event.target.files;

    if (!files || files.length === 0) return;
    setSendLoader(true);

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append('files', file, tools.translit(file.name));
    }

    onSendFile({
      formData: formData,
      callback: ({ data }) => {
        fileInputRef.current.value = '';

        if (Array.isArray(data)) {
          const newMessages = data.map((fileData) => makeMyFileMessage(fileData));

          for (const fileData of newMessages) {
            if (isPersonalConsultantChat) {
              dispatch(addPersonalConsultantChatMessage(fileData));
            } else {
              dispatch(addNewMessageToContractChat({ chatId: chatId, data: fileData }));
            }
          }

          setMessages([...messages, ...newMessages]);
        } else {
          const newMessage = makeMyFileMessage(data);

          if (isPersonalConsultantChat) {
            dispatch(addPersonalConsultantChatMessage(newMessage));
          } else {
            dispatch(addNewMessageToContractChat({ chatId: chatId, data: newMessage }));
          }
          setMessages([...messages, newMessage]);
        }

        setSendLoader(false);
      },
      onError: () => {
        setSendLoader(false);
        fileInputRef.current.value = '';
        error(translation.sendingFileError[lang]);
      },
    });
  };

  const handleDownloadFile = (fileId, fileName) => {
    if (!fileId) return;
    setMessagesLoader(true);
    onGetFile({ fileId, fileName, callback: () => setMessagesLoader(false) });
  };

  const handleOnContextMenu = (e, item) => {
    e.preventDefault();

    if (!(isContractChat || isPersonalConsultantChat)) return;

    const contextMenuArr = contextMenuRef.current.getBoundingClientRect();

    const isLeft = e.clientX < window?.clientX / 2;

    let x;
    let y = e.clientY;

    if (isLeft) {
      x = e.clientX;
    } else {
      x = e.clientX - contextMenuArr?.width;
    }

    setContextMenu({
      position: {
        x: x,
        y: y,
      },
      toggled: true,
      messageData: item,
    });
  };

  const handleEditMode = (item) => {
    setInputValue(item.message);
    setEditMode({
      isEditing: true,
      messageId: item._id,
      prevMessageValue: item.message,
    });
  };

  const resetContextMenu = () => {
    setContextMenu({
      position: {
        x: 0,
        y: 0,
      },
      toggled: false,
    });
  };

  const onClickHandleEditMode = (item) => {
    handleEditMode(item);
    resetContextMenu();
  };

  const onCloseEditingModeButton = () => {
    resetEditModeData();
    setInputValue('');
  };

  const resetEditModeData = () => {
    setEditMode({
      isEditing: false,
      messageId: null,
      prevMessageValue: '',
    });
  };

  useEffect(() => {
    if (isPersonalConsultantChat) return;

    setMessagesLoader(true);
    onGetMessages((messages) => {
      setMessages(messages);
      setMessagesLoader(false);
    });
  }, [onGetMessages]);

  useEffect(() => {
    if (!isPersonalConsultantChat) return;

    setMessagesLoader(true);
    onGetMessages(() => {
      setMessagesLoader(false);
    });
  }, [chatType]);

  useEffect(() => {
    if (sendFileDirectly === true) {
      fileInputRef.current.click();
    }
  }, []);

  const chatContainerHeight = heightFull ? window.innerHeight - 135 + 'px' : '400px';

  return (
    <div className="chat-container">
      {!clientAuth?.phoneVerified ? <UnverifiedPhoneOverlay setShowPhoneVerify={setShowPhoneVerify} showPhoneVerify={showPhoneVerify} /> : null}
      <Spin spinning={messagesLoader}>
        <div style={{ width: '100%', height: chatContainerHeight }}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={cache.current.rowHeight}
                deferredMeasurementCache={cache.current}
                rowCount={isPersonalConsultantChat ? personalConsultantChat?.messages?.length : messages.length}
                scrollToIndex={isPersonalConsultantChat ? personalConsultantChat?.messages?.length - 1 : messages.length - 1}
                overscanRowCount={0}
                rowRenderer={({ key, index, style, parent }) => (
                  <ChatMessageWrapper
                    key={key}
                    index={index}
                    style={style}
                    parent={parent}
                    messages={isPersonalConsultantChat ? personalConsultantChat?.messages : messages}
                    handleDownloadFile={handleDownloadFile}
                    handleOnContextMenu={handleOnContextMenu}
                    cache={cache}
                  />
                )}
              />
            )}
          </AutoSizer>
        </div>
        <InputFormWrapper
          handleAttachFile={handleAttachFile}
          handleSend={handleSend}
          handleInput={handleInput}
          fileInputRef={fileInputRef}
          sendLoader={sendLoader}
          inputValue={inputValue}
          onCloseEditingModeButton={onCloseEditingModeButton}
          editMode={editMode}
        />
      </Spin>
      <ContextMenu
        contextMenuRef={contextMenuRef}
        positionX={contextMenu.position.x}
        positionY={contextMenu.position.y}
        isToggled={contextMenu.toggled}
        rightClickItem={contextMenu.messageData}
        buttons={[
          ...(contextMenu.messageData?.isSentByCurrentUser && contextMenu.messageData?.message
            ? [
                {
                  text: translation.edit[lang],
                  icon: EditOutlined,
                  onClick: (e, item) => onClickHandleEditMode(item),
                },
              ]
            : []),
        ]}
      />
      {showPhoneVerify && !clientAuth.phoneVerified ? <AddClientEmail /> : null}
    </div>
  );
};

export default Chat;
