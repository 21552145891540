import React from 'react';

import './BonusesInfoModal.scss';
import ModalWindow from '../ModalWindow';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';

const BonusesInfoModal = ({ open, setOpen, title }) => {
  const lang = useSelector((state) => state.common.language);

  return (
    <ModalWindow title={title} modal={open} setModal={setOpen}>
      <div className="bonuses-info">
        <div className="main-content">
          <h3>{translation.bonusHowToUse[lang]}</h3>
          <p>
            {translation.bonusButton[lang]}{' '}
            <button type="button" className="return-button">
              {translation.return30Percents[lang]}
            </button>
          </p>
          <div>
            <h4>{translation.bonusLimits[lang]}</h4>
            <ol>
              <li>
                {translation.bonusLimit1[lang]} {<strong style={{ color: '#389E0D' }}>{translation.bonusLimit1Days[lang]};</strong>}
              </li>
              <li>
                {translation.bonusLimit2[lang]} {<strong style={{ color: '#389E0D' }}> {translation.bonusLimit2Percents[lang]};</strong>}{' '}
              </li>
              {/* <li>
                {translation.bonusLimit3[lang]}
                {<strong style={{ color: '#389E0D' }}>{translation.bonusLimit3Transactions[lang]}:</strong>}
                <ul>
                  {translation.bonusCashbackTransactions[lang].map((label, index) => (
                    <li key={index}>
                      <strong>{label}</strong>
                    </li>
                  ))}
                </ul>
              </li> */}
            </ol>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};

export default BonusesInfoModal;
