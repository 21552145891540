import { useState } from 'react';
import QRCode from '../QRCode/QRCode';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import { CLUB_CARDS_TYPES } from 'common_constants/business';

import CARDBACKSILVER from '../../images/club-card-back-silver.jpg';
import CARDFRONTSILVER from '../../images/club-card-front-silver.jpg';
import CARDBACKGOLD from '../../images/club-card-back-gold.jpg';
import CARDFRONTGOLD from '../../images/club-card-front-gold.jpg';
import CARDBACKPLATINUM from '../../images/club-card-back-platinum.jpg';
import CARDFRONTPLATINUM from '../../images/club-card-front-platinum.jpg';

import './CurrentClubCard.scss';
import { clsx } from 'clsx';
import Button from '../Button';
import { Link } from 'react-router-dom';
import { CLIENT_ROUTES } from 'common_constants/routes';

const CurrentClubCard = () => {
  const [activeCard, setActiveCard] = useState(false);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);
  const { clubCard } = useSelector((state) => state.common.clientInfo);

  let cardImageBack;
  let cardImageFront;
  switch (clubCard?.cardType) {
    case CLUB_CARDS_TYPES.GOLD:
      cardImageBack = CARDBACKGOLD;
      cardImageFront = CARDFRONTGOLD;
      break;
    case CLUB_CARDS_TYPES.PLATINUM:
      cardImageBack = CARDBACKPLATINUM;
      cardImageFront = CARDFRONTPLATINUM;
      break;
    default:
      cardImageBack = CARDBACKSILVER;
      cardImageFront = CARDFRONTSILVER;
      break;
  }

  const handleCardClick = () => (activeCard ? setActiveCard(false) : setActiveCard(true));

  return (
    <div className="current-club-card" onClick={handleCardClick}>
      <div className="wrapper">
        <div className={clsx('front', { rotate: activeCard })}>
          <h3 className="title">{translation.clubCardTitleFront[lang]}</h3>
          <p className="type-card">{translation.clubCardType[clubCard?.cardType.toLowerCase()][lang]}</p>
          <h3 className="text">{translation.clubCard[lang]}</h3>
          <img src={cardImageFront} alt="" className="card-front__img" />
        </div>
        <div className={clsx('back', { rotate: activeCard })}>
          <h3 className="title">{translation.clubCardTitleBack[lang]}</h3>
          <img src={cardImageBack} alt="" className="card-back__img" />
          <Link to={CLIENT_ROUTES.CLUB_CARD_PAGE}>
            <Button>{translation.raiseStatus[lang]}</Button>
          </Link>
          <div className="qr-code-overlay">
            <QRCode value={`${clientInfo._id}`} size={150} icon="/emblema-min4.png" iconSize={30} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentClubCard;
