import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { register } from 'swiper/element/bundle';
import { INSURANCE_BALANCE_MULTIPLIER } from 'common_constants/business';
import { translation } from 'common_constants/translation';
import dayjs from 'dayjs';

import 'swiper/css';
import 'swiper/css/effect-cards';
import Button from '../Button';
import Description from '../../pages/Insurances/Description';
import Spin from '../../components/Spin/Spin';

import './styles.scss';

register();

export const SwiperInsurance = ({ insurances = [], insuranceTypes = [], handleAddClick, setActiveSlide }) => {
  const swiperElRef = useRef(null);
  const lang = useSelector((state) => state.common.language);

  const [swiperRendered, setSwiperRendered] = useState(false);

  const getInsuranceDetails = (itemType) => {
    return insuranceTypes.find((obj) => obj.i === itemType);
  };

  useEffect(() => {
    if (!swiperElRef.current) {
      setTimeout(() => {
        setSwiperRendered(true);
      }, 1000);
      return;
    }
    swiperElRef.current.swiperParams.spaceBetween = 40;
    swiperElRef?.current?.addEventListener('swiperslidechange', (e) => {
      setActiveSlide(e.detail[0]?.activeIndex);
    });
  }, [swiperRendered]);

  useEffect(() => {
    if (swiperElRef.current?.swiper) {
      swiperElRef.current.swiper.update();
    }
  }, [insurances, insuranceTypes]);

  const currentInsuranceType = insuranceTypes[swiperElRef?.current?.swiper?.activeIndex];

  return (
    <div className="swiper-insurace">
      <Spin spinning={swiperRendered} fill={true}>
        <swiper-container className="swiper_i" ref={swiperElRef} slides-per-view="auto" effect="cards" grab-cursor="true">
          {insurances.length !== 0 &&
            insurances.map((item, index) => {
              const matchedType = getInsuranceDetails(item.type);
              const ext = matchedType?.[lang]?.extension === 'svg+xml' ? 'svg' : matchedType?.[lang]?.extension;

              const backgroundImage =
                matchedType && matchedType?.[lang]?.image
                  ? `${process.env.REACT_APP_API}/insuranceTypeImages/${matchedType[lang].image}.${ext}`
                  : null;

              return (
                <swiper-slide
                  style={backgroundImage ? { backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' } : {}}
                  key={dayjs(item.dateReceiving).format('DD-MM-YYYY') + index}
                >
                  <div className="container-info">
                    <div className="type-card">
                      {matchedType && matchedType?.[lang] ? matchedType?.[lang]?.name : translation.insuranceCard[lang]}
                    </div>
                    {!backgroundImage && (
                      <div>
                        <img src="/logo.svg" alt="logo" />
                      </div>
                    )}
                  </div>
                  <div className="container-card">
                    <div className="date">
                      {dayjs(item.dateReceiving).format('DD-MM-YYYY')} - {dayjs(item.dateExpiration).format('DD-MM-YYYY')}
                    </div>
                    <div className="price">{item.balance ? item.balance : item?.amount * INSURANCE_BALANCE_MULTIPLIER} грн</div>
                  </div>
                </swiper-slide>
              );
            })}
          {!insurances.length &&
            insuranceTypes.map((item, index) => {
              const extension = item?.[lang]?.extension === 'svg+xml' ? 'svg' : item?.[lang]?.extension;

              const backgroundImage =
                item && item?.[lang]?.image ? `${process.env.REACT_APP_API}/insuranceTypeImages/${item[lang].image}.${extension}` : null;

              return (
                <swiper-slide
                  style={backgroundImage ? { backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' } : {}}
                  key={item.i + index}
                >
                  <div className="container-info">
                    {!backgroundImage && (
                      <>
                        <div className="type-card">{item?.[lang]?.name}</div>
                        <div>
                          <img src="/logo.svg" alt="logo" />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="container-card">
                    <div className="date">-</div>
                    <div className="price">-</div>
                  </div>
                </swiper-slide>
              );
            })}

          {insurances.length !== 0 && (
            <swiper-slide key={'add'}>
              <div
                className="container-info"
                onClick={handleAddClick}
                style={{ height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
              >
                <div className="type-card">{translation.buyInsuranceBtn[lang]}</div>
                <div>
                  <PlusOutlined style={{ fontSize: '40px', marginTop: 8 }} />
                </div>
              </div>
            </swiper-slide>
          )}
        </swiper-container>
        {insurances.length === 0 && (
          <div style={{ width: 200, margin: 'auto' }}>
            <Button onClick={handleAddClick}>{translation.buyInsuranceBtn[lang]}</Button>
          </div>
        )}
        {insurances.length === 0 && <Description insuranceType={currentInsuranceType} lang={lang} />}
      </Spin>
    </div>
  );
};
