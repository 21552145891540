import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Typography from '../Typography/Typography';
import Card from '../Card/Card';
import dayjs from 'dayjs';

import { POSLUGI, PHONE_OFFICE_VIEW_CRM, PHONE_OFFICE_VIEW_CLIENT, POSLUGI_FOR_EADVOKAT } from 'common_constants/business';
import { CLIENT_LINK, APP_LINK_ANDROID, APP_LINK_IOS, APP_LINK } from 'common_constants/routes';
import { translation } from 'common_constants/translation';

import appstoreImg from '../../images/appstore-app.svg';
import googleplayImg from '../../images/googleplay-app.svg';
import UserAvatar from '../UserAvatar';

import Location from '../../images/location.png';
import Dollar from '../../images/dollar.png';
import Garant from '../../images/garant.png';
import Info from '../../images/info.png';

import './HotlineMeetInvite.scss';
import ModalWindow from '../../Modals/ModalWindow';
import AboutEadvokat from './AboutEadvokat';
import Information from './Information';
import GoogleMap from '../GoogleMap';

const HotlineMeetInvite = ({ crm, hash, filii = [] }) => {
  const meetData = useSelector((state) => state.common.hotlineMeetData);
  const lang = useSelector((state) => state.common.language);
  const users = useSelector((state) => state.common.usersData);
  const [aboutEadvokat, setAboutEadvokat] = useState(false);
  const [adress, setAdress] = useState(false);
  const [info, setInfo] = useState(false);

  const filData = filii?.find((item) => item?.i === +meetData?.fil) || {};

  const { Text } = Typography;

  const [indexRemove, indexInfoCent] = ['3', '6'];
  const [isRemove, isInfoCent, isVip, isСity] = [meetData.type === indexRemove, meetData.type === indexInfoCent, meetData.vip, filData.name];
  const [orRemoveHotline, allOfficeHotline] = [isRemove || isInfoCent, !isRemove && !isInfoCent];

  const [textRemoveMaybeVip, textOfficeMaybeCities] = [
    `${translation.constHotlineMeetInviteText1_1[lang]} ${
      isVip ? translation.constHotlineMeetInviteText1_2[lang] : translation.constHotlineMeetInviteText1_3[lang]
    }`,
    isСity ? `${translation.constHotlineMeetInviteText1_4[lang]} ${isСity} ` : '',
  ];
  // Інфо центр для клієнта відображається лише як дистанційна консультація
  // Vip буває лише в дист консультаціях, що дає клієнту приставку до дист - Преміум
  const textTypeHotline = orRemoveHotline ? textRemoveMaybeVip : POSLUGI_FOR_EADVOKAT[meetData.type][lang];

  const [lawyerTitle, locationInfo] = [
      isVip ? translation.constHotlineMeetInviteText2_1[lang] : translation.constHotlineMeetInviteText2_2[lang], //* only dynamic vip
      allOfficeHotline
        ? `${textOfficeMaybeCities}${translation.constHotlineMeetInviteText2_3[lang]}` //* only office
        : `${translation.constHotlineMeetInviteText2_4[lang]} ${textRemoveMaybeVip}`, //* only remove + dynamic vip
    ],
    dynamicTextTypeAndTime = (
      <>
        <Text children={`${lawyerTitle} ${translation.constHotlineMeetInviteTitle[lang]} ${locationInfo}`} />
        <br />
        <br />
        <b className="time-meet">
          {dayjs(meetData.date).format('DD.MM.YYYY')} о {meetData.startT}
        </b>
        <br />
        <b>
          {translation.on[lang]} {textTypeHotline}{' '}
        </b>
      </>
    );

  const handleClickModalPaymentButton = () => {
    window.location = meetData.paymentUrl;
  };

  const balance = meetData.amr && meetData.amr === '2' ? meetData.balance : meetData.balance - meetData.cost;
  const curator = users?.find((user) => user?._id === meetData?.bmm);

  return (
    <Card className="hotline-meet-invite">
      {/* pay button */}
      {/* {!meetData.free && (
        <Row justify={'center'}>
          {meetData.paymentUrl && balance < 0 ? (
            <Button onClick={handleClickModalPaymentButton}>{translation.payBtn[lang]}</Button>
          ) : (
            <Row style={{ fontWeight: 'bold', marginBottom: 10 }}>{translation.thanksForTheOpal[lang]}</Row>
          )}
        </Row>
      )} */}
      {/*  */}
      <div className="invite-title">{translation.hotlineMeetInviteCardTitle[lang]}</div>
      {translation.hotlineMeetInviteCardText1[lang]}
      <br />№ <b>{meetData.i}</b> {translation.hotlineMeetInviteCardText2[lang]} <b>{dayjs(meetData.date).format('DD.MM.YYYY')}</b>
      <br />
      <br />
      {meetData?.bmm ? (
        <Link to={`/cv/${meetData.bmm}`}>
          <UserAvatar user={curator} />
        </Link>
      ) : null}
      <br />
      <br />
      {dynamicTextTypeAndTime}
      <div className="app-card">
        <h2>
          {translation._appDownload1[lang]}
          <b> {translation._appTitle[lang]} </b>
          {translation._appDownload2[lang]}
        </h2>
        <ul className="benefits">
          <li>✅ {translation._appBenefit1[lang]}</li>
          <li>✅ {translation._appBenefit2[lang]}</li>
          <li>✅ {translation._appBenefit3[lang]}</li>
        </ul>
        <div className="eAdvokat-logo-image-wrapper">
          <img alt="Logo_Image" src={'/logo.svg'}></img>
        </div>
        <div className="app-download">
          <a rel="noopener noreferrer" target="_blank" href={APP_LINK_IOS}>
            <div>
              <img className="app-download-link" src={appstoreImg} alt="appstore" />
            </div>
          </a>
          <a rel="noopener noreferrer" target="_blank" href={APP_LINK_ANDROID}>
            <div>
              <img className="app-download-link" src={googleplayImg} alt="googleplay" />
            </div>
          </a>
        </div>
      </div>
      <div className="bottom-cards">
        <div>
          <button className="bottom-btn" rel="noreferrer" onClick={() => setAdress(true)}>
            <img src={Location} alt="location" />
          </button>
          {translation.lastEventsAddress[lang]}
        </div>
        <div>
          <a
            className="bottom-btn"
            rel="noreferrer"
            target="_blank"
            href={process.env.REACT_APP_API + '/hotline/hotlinePay/' + hash}
            style={{
              pointerEvents: !meetData?.free ? 'auto' : 'none',
              opacity: !meetData?.free ? 1 : 0.5,
            }}
          >
            <img
              src={Dollar}
              alt="dollar"
              style={{
                marginTop: '-10%',
                width: '130%',
                height: '130%',
              }}
            />
          </a>
          {translation.payBtn[lang]}
        </div>
        {/* <div>
          <Link className="bottom-btn" rel="noreferrer" to={CLIENT_LINK}>
            <img src={Garant} alt="rate" />
          </Link>
          {translation.rate[lang]}
        </div> */}
        <div>
          <button className="bottom-btn" rel="noreferrer" onClick={() => setInfo(true)}>
            <img src={Info} alt="info" />
          </button>
          {translation.info[lang]}
        </div>
      </div>
      <div className="footer-links">
        <a rel="noreferrer" target="_blank" href={APP_LINK}>
          {translation._learnMore[lang]}
        </a>
        <Link to={`/cv/${meetData.bmm}`}>{translation._checkPortfolio[lang]}</Link>
        <button onClick={() => setAboutEadvokat(true)}>{translation._learnMoreApp[lang]}</button>

        <a href={PHONE_OFFICE_VIEW_CRM}>{PHONE_OFFICE_VIEW_CLIENT}</a>
      </div>
      <ModalWindow modal={adress} setModal={setAdress}>
        <div className="adress-info">
          <h3>{filData.name}</h3>
          {filData.image && filData.ext && (
            <div className="fil_img">
              <img src={process.env.REACT_APP_API + `/filiiImages/${filData.image}.${filData.ext}`} loading="lazy" alt="Фото філії" />
            </div>
          )}
          <b>{filData.address}</b>
          <br />
          <br />
          <i>{filData.address_description}</i>
          <br />
          <br />
          <GoogleMap address={filData.address} />
        </div>
      </ModalWindow>
      <ModalWindow modal={aboutEadvokat} setModal={setAboutEadvokat}>
        <AboutEadvokat lang={lang} />
      </ModalWindow>
      <ModalWindow modal={info} setModal={setInfo}>
        <Information lang={lang} allOfficeHotline={allOfficeHotline} filData={filData} meetData={meetData} />
      </ModalWindow>
    </Card>
  );
};

export default HotlineMeetInvite;
