import { useSelector, useDispatch } from 'react-redux';
import { translation } from 'common_constants/translation';
import { CHAT_TYPES } from 'common_constants/business';

import { setModal, setPersonalConsultantChat } from '../../store/commonReducer';
import { request, requestFile, error, postFile } from '../../tools';
import { Chat, Modal } from '../../components';

const PersonalConsultantChat = () => {
  const dispatch = useDispatch();

  const clientAuth = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);
  const users = useSelector((state) => state.common.usersData);
  const socket = useSelector((state) => state.common.socket);
  const personalConsultantChat = useSelector((state) => state.common.personalConsultantChat);

  const onSendMessage = (params = {}) => {
    if (!params.message) return error(translation.chatForContractError1[lang]);

    request(
      '/chatPrivate/chatSendMessageClient',
      {
        documentId: personalConsultantChat._id,
        message: params.message,
        clientName: clientAuth.n,
        fromSocketId: socket?.id,
        chatType: CHAT_TYPES.personalConsultantChat.key,
      },
      ({ newMessage }) => {
        params.callback({
          ...newMessage,
          isSentByCurrentUser: newMessage.sender === clientAuth._id,
          name:
            newMessage.sender === clientAuth._id
              ? clientAuth.n
              : users?.find((user) => user._id === newMessage.sender)?.name || translation.chatForContractError4[lang],
        });
      },
      error,
    );
  };

  const onSendFiles = (params = {}) => {
    const { formData, callback, onError } = params;

    if (!formData) return error(translation.chatForContractError2[lang]);
    if (typeof callback !== 'function') return error(translation.chatForContractErrorCallback[lang]);

    formData.append('documentId', personalConsultantChat._id);
    formData.append('userId', clientAuth._id);
    formData.append('clientName', clientAuth.n);
    formData.append('fromSocketId', socket?.id);
    formData.append('chatType', CHAT_TYPES.personalConsultantChat.key);

    postFile(
      '/chatPrivate/chatSendFilesClient',
      formData,
      ({ newMessage }) => {
        callback({ data: newMessage });
      },
      onError,
    );
  };

  const onGetFile = (params = {}) => {
    const { fileId, fileName, callback, onError } = params;

    if (!fileId) return error(translation.chatForContractError3[lang]);
    // if (typeof callback !== 'function') return error(translation.chatForContractErrorCallback[lang]);

    requestFile('/chatPrivate/chatGetFileClient', { fileId, fileName, documentId: personalConsultantChat._id }, callback, onError);
  };

  const onGetMessages = (callback) => {
    if (personalConsultantChat?.messages?.length > 1) {
      callback();
      return;
    }

    request(
      '/chatPrivate/getPersonalConsultantMessages',
      { chatType: CHAT_TYPES.personalConsultantChat.key },
      ({ data }) => {
        const messages =
          data?.messages?.map((item) => ({
            ...item,
            isSentByCurrentUser: item.sender === clientAuth._id,
            name:
              item.sender === clientAuth._id
                ? clientAuth.n
                : users?.find((user) => user._id === item.sender)?.name || translation.chatForContractError4[lang],
          })) ?? [];

        dispatch(setPersonalConsultantChat({ ...data, messages: messages }));
        callback();
      },
      error,
    );
  };

  const onEditMessage = (params = {}) => {
    if (!params.message) return error('Пусте повідомлення');
    if (typeof params.callback !== 'function') return error('Не вказана функція callback');

    const requestData = {
      chatId: personalConsultantChat._id,
      type: CHAT_TYPES.personalConsultantChat.key,
      messageId: params.messageId,
      newMessageText: params.message,
    };

    request(
      '/chatPrivate/editMessageForClient',
      requestData,
      (res) => {
        params.callback();
      },
      error,
    );

    return;
  };

  return (
    <Modal open title={translation.personalConsultantChatTitle[lang]} onCancel={() => dispatch(setModal())} footer={false}>
      <Chat
        onSendMessage={onSendMessage}
        onSendFile={onSendFiles}
        onGetMessages={onGetMessages}
        onGetFile={onGetFile}
        onEditMessage={onEditMessage}
        // sendFileDirectly={data.sendFileDirectly}
        chatId={personalConsultantChat?._id}
        chatType={CHAT_TYPES.personalConsultantChat.key}
      />
    </Modal>
  );
};

export default PersonalConsultantChat;
