import React from 'react';
import { useSelector } from 'react-redux';

import * as MODALS from 'common_constants/modals';

import QuestionnaireHotline from './QuestionnaireHotline';
import QuestionnaireArchive from './QuestionnaireArchive';
import QuestionnaireHotlineCertificate from './QuestionnaireHotlineCertificate';
import QuestionnaireArchiveAdvertising from './QuestionnaireArchiveAdvertising';
import ChatForContract from './ChatForContract';
import { AddClientEmail } from './AddClientEmail';
import FirstLoginOfTheClient from './FirstLoginOfTheClient';
import Notification from './Notification';
import ConfirmEmailAnonim from './ConfirmEmailAnonim';
import ContractTasks from './ContractTasks';
import PaymentFrame from './PaymentFrame';
import ChatForTender from './ChatForTender';
import FirstLoginAutoPay from './FirstLoginAutoPay';
import LeaveSuggestionModal from './LeaveSuggestionModal';
import FeedbackBonusModal from './FeedbackBonusModal';
import DozhimsAI from './DozhimsAI';
import FunctionsNotUsed from './FunctionsNotUsed';
import AddTips from './AddTips';
import BuyWebinarModal from './BuyWebinarModal';
import QuestionnaireHotlineAdvertising from './QuestionnaireHotlineAdvertising';
import ChatWithAccountant from './ChatWithAccountant';
import ClubCardGiftModal from './ClubCardGiftModal';
import QuestionnaireInterimServiceQualityAssessment from './QuestionnaireInterimServiceQualityAssessment';
import PersonalConsultantChat from './PersonalConsultantChat/PersonalConsultantChat';
import ApproveDeleteAccountModal from './ApproveDeleteAccountModal';
import FirstloginBonus from './FirstloginBonus';
import './style.scss';
import HotlineBonus from './HotlineBonus';

const Modals = () => {
  const name = useSelector((state) => state.common.modal?.name);

  switch (name) {
    case MODALS.QUESTIONNAIRE_HOTLINE:
      return <QuestionnaireHotline />;
    case MODALS.QUESTIONNAIRE_HOTLINE_ADVERTISING:
      return <QuestionnaireHotlineAdvertising />;
    case MODALS.QUESTIONNAIRE_ARCHIVE:
      return <QuestionnaireArchive />;
    case MODALS.QUESTIONNAIRE_HOTLINE_CERTIFICATE:
      return <QuestionnaireHotlineCertificate />;
    case MODALS.QUESTIONNAIRE_ARCHIVE_ADVERTISING:
      return <QuestionnaireArchiveAdvertising />;
    case MODALS.ADD_CLIENT_EMAIL:
      return <AddClientEmail />;
    case MODALS.FIRST_LOGIN_OF_THE_CLIENT:
      return <FirstLoginOfTheClient />;
    case MODALS.NOTIFICATION:
      return <Notification />;
    case MODALS.CHAT_FOR_CONTRACT:
      return <ChatForContract />;
    case MODALS.CHAT_FOR_TENDER:
      return <ChatForTender />;
    case MODALS.CHAT_FOR_DOZHIMS_AI:
      return <DozhimsAI />;
    case MODALS.CONFIRM_EMAIL_ANONIM:
      return <ConfirmEmailAnonim />;
    case MODALS.CONTRACT_TASKS:
      return <ContractTasks />;
    case MODALS.PAYMENT_FRAME:
      return <PaymentFrame />;
    case MODALS.FIRST_LOGIN_AUTO_PAY:
      return <FirstLoginAutoPay />;
    case MODALS.LEAVE_SUGGESTION_MODAL:
      return <LeaveSuggestionModal />;
    case MODALS.FEEDBACK_BONUS_MODAL:
      return <FeedbackBonusModal />;
    case MODALS.FUNCTIONS_NOT_USED:
      return <FunctionsNotUsed />;
    case MODALS.BUY_WEBINAR:
      return <BuyWebinarModal />;
    case MODALS.ADD_TIPS:
      return <AddTips />;
    case MODALS.CHAT_WITH_ACCOUNTANT:
      return <ChatWithAccountant />;
    case MODALS.CLUB_CARD_GIFT:
      return <ClubCardGiftModal />;
    case MODALS.QUESTIONNAIRE_INTERIM_SERVICE_QUALITY_ASSESSMENT:
      return <QuestionnaireInterimServiceQualityAssessment />;
    case MODALS.PERSONAL_CONSULTANT_CHAT:
      return <PersonalConsultantChat />;
    case MODALS.APPROVE_DELETE_ACCOUNT:
      return <ApproveDeleteAccountModal />;
    case MODALS.FIRST_LOGIN_BONUS:
      return <FirstloginBonus />;
    case MODALS.FIRST_HOTLINE_BONUS:
      return <HotlineBonus />;
    default:
      return null;
  }
};

export default Modals;
