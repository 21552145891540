import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClientPhoneVerified } from '../../store/commonReducer';
import { translation } from 'common_constants/translation';
import { setMainLoader } from '../../store/uiReducer';
import MainLoader from '../MainLoader';

import { CLIENT_ROUTES } from 'common_constants/routes';
import Form from '../Form/Form';
import Button from '../Button';
import Input from '../Input/Input';
import PhoneCodeSwitcher from '../PhoneCodeSwitcher';
import { request, warn, success, getPhonePattern, clientLogOut } from '../../tools';
import { useHistory, useLocation } from 'react-router-dom';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import './ConfirmChangePhone.scss';

dayjs.extend(utc);

const ConfirmChangePhone = ({ setLoading, setModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isSetPhone = location.pathname === '/profile';

  const { verificationSmsSendTime, ph } = useSelector((state) => state.common.clientAuth);
  const { countryPhCode } = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);

  const [newSms, setNewSms] = useState();
  const [newPhone, setNewPhone] = useState();
  const [cancelButtonClicked, setCancelButtonClicked] = useState(false);
  const [inputSmsValue, setInputSmsValue] = useState('');
  const [inputPhoneValue, setinputPhoneValue] = useState(ph ?? '');
  const [countryCode, setCountryCode] = useState(countryPhCode);
  const firstPhoneRules = [
    {
      pattern: getPhonePattern(countryCode),
      message: translation.confirmChangePhoneRules4[lang],
    },
    {
      required: true,
      message: translation.confirmChangePhoneRules4[lang],
    },
  ];
  const secondPhoneRules = [
    {
      required: true,
      message: translation.confirmChangePhoneRules1[lang],
    },
    {
      len: 6,
      message: translation.confirmChangePhoneRules2[lang],
    },
  ];

  const [{ Item }] = [Form, Form.useForm()];

  const currentTime = new Date();
  const MINUTE = 60;
  const diff = dayjs(currentTime).diff(verificationSmsSendTime, 'second');

  const smsVerificationCheck = verificationSmsSendTime && diff < MINUTE;

  useEffect(() => {
    if (smsVerificationCheck) {
      const interval = setInterval(() => {
        setNewSms(dayjs(currentTime).diff(verificationSmsSendTime, 'second'));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [newSms, verificationSmsSendTime]);

  const onFinish = async (newCode) => {
    const values = {
      clientInputCode: newCode,
    };
    const { clientInputCode } = values;

    dispatch(setMainLoader(true));

    const actions = [
      { url: 'phoneVerify', phone: {}, ph: {} },
      { url: 'changeClientPhone', phone: { phone: newPhone, countryPhCode: countryCode }, ph: { ph: newPhone } },
    ][ph === newPhone ? 0 : 1];

    const transaction = { token: clientInputCode, ...actions.phone };
    await request(
      `/auth/${actions.url}`,
      transaction,
      (res) => {
        if (res && res.verificationSmsSendTime && res.phoneVerified) {
          dispatch(
            setClientPhoneVerified({
              verificationSmsSendTime: res.verificationSmsSendTime ?? null,
              phoneVerified: res.phoneVerified ?? null,
              ...actions.ph,
            }),
          );
          setInputSmsValue('');
          success(translation.success[lang], translation.confirmChangePhoneSuccess1[lang]);
          setNewPhone('');
          isSetPhone ? setModal(false) : history.push(CLIENT_ROUTES.CLIENT_HOME.route);
        } else {
          setInputSmsValue('');
          warn(translation.confirmChangePhoneWarn1[lang], ' ');
        }
      },
      () => {
        warn(translation.confirmChangePhoneWarn2[lang], ' ');
      },
    );
    dispatch(setMainLoader(false));
  };

  const sendSms = (_data) => {
    dispatch(setMainLoader(true));

    const dataTransaction = {
      phone: typeof _data === 'string' ? _data : _data?.phone,
      countryCode,
    };

    request(
      '/auth/PhoneForConfirmation',
      dataTransaction,
      (res) => {
        dispatch(setMainLoader(false));
        if (res.phoneExists) return warn(translation.confirmChangePhoneWarn3[lang], ' ');
        setNewPhone(dataTransaction.phone);
        dispatch(setClientPhoneVerified({ phoneVerified: res.phoneVerified, verificationSmsSendTime: res.verificationSmsSendTime }));
        success(translation.success[lang], translation.confirmChangePhoneSuccess2[lang]);
      },
      () => {
        warn(translation.confirmChangePhoneWarn4[lang], ' ');
        dispatch(setMainLoader(false));
      },
    );
  };
  const onChange = (code) => setCountryCode(code);

  return (
    <>
      <div className="ConfirmChangePhone-wrapper">
        <MainLoader />
        {newPhone ? (
          <Form onFinish={onFinish} style={{ overflow: 'hidden' }}>
            <span>
              {translation.regStep2Confirm4[lang]} <b>{newPhone}</b> {translation.regStep2Confirm5[lang]}
            </span>
            <Item
              rules={secondPhoneRules}
              className="form-item"
              label={translation.regStep1Confirm4[lang]}
              name="clientInputCode"
              wrapperCol={{ offset: 2, span: 4 }}
            >
              <Input
                rules={secondPhoneRules}
                className="confirmPhone-input"
                maxLength={6}
                style={{ maxWidth: 80 }}
                value={inputSmsValue}
                onChange={(e) => setInputSmsValue(e.target.value)}
              />
            </Item>
            <Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="submit" variant="default" htmlType="submit">
                {translation.confirmButton[lang]}
              </Button>
            </Item>
            {/* Не бачите листа? Перевірте папку "Спам" або <a onClick={resetEmail}> почніть з початку </a> */}
            <div style={{ textAlign: 'center' }}>
              {cancelButtonClicked && (
                <div style={{ marginBottom: 10 }}>
                  {translation.regStep2Confirm6[lang]}{' '}
                  {smsVerificationCheck ? `${translation.inAMinute[lang]} ${MINUTE - diff} ${translation.second[lang]}` : null}
                </div>
              )}

              <Button
                style={{ marginRight: 10 }}
                onClick={
                  diff >= MINUTE
                    ? (e) => {
                        setInputSmsValue('');
                        e.preventDefault();
                        sendSms(newPhone);
                      }
                    : null
                }
                variant="default"
                htmlType="submit"
                disabled={diff < MINUTE}
              >
                {translation.sendButton[lang]}
              </Button>

              <Button
                onClick={() => {
                  setInputSmsValue('');
                  setNewPhone('');
                  setCancelButtonClicked(true);
                }}
              >
                {translation.changePhoneButton[lang]}
              </Button>
            </div>
          </Form>
        ) : (
          <Form onFinish={sendSms} initialValues={{ phone: ph }} style={{ overflow: 'hidden' }}>
            <div>{isSetPhone ? '' : translation.regStep2Confirm1[lang]}</div>
            <Item name="phone" rules={firstPhoneRules} className="form-item">
              <div className="phone-item-wrapper">
                <div className="PhoneCodeSwitcher-wrapper">
                  <PhoneCodeSwitcher countryPhCode={countryCode} setCountryPhCode={setCountryCode} onChange={onChange} value={countryCode} />
                </div>
                <div>
                  <Input
                    label={translation.regStep2Confirm2[lang]}
                    aria-label="input phone client"
                    type="number"
                    rules={firstPhoneRules}
                    className="confirmPhone-input pref"
                    value={inputPhoneValue}
                    onChange={(e) => setinputPhoneValue(e.target.value)}
                    placeholder={ph}
                    autoComplete="tel"
                  />
                </div>
              </div>
            </Item>
            <div className="text">{translation.regStep2Confirm3[lang]}</div>
            <React.Fragment>
              <Button type="submit" variant="default" disabled={smsVerificationCheck}>
                {translation.sendButton[lang]}
              </Button>
              {smsVerificationCheck && (
                <div>
                  {translation.regStep2Confirm7[lang]} {` ${MINUTE - diff}c`}
                </div>
              )}
            </React.Fragment>
          </Form>
        )}
        <div className="question">
          {translation.clientLoginQuestion1[lang]} <span onClick={clientLogOut}>{translation.register[lang]}</span>
        </div>
      </div>
    </>
  );
};

export default ConfirmChangePhone;
