import { TENDER_TERMS_FOR_EADVOKAT } from 'common_constants/business';
import { Link } from 'react-router-dom';
import UserAvatar from '../../../components/UserAvatar';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { request, success, error } from '../../../tools';
import BalanceNewButton from '../../../components/Balance/BalanceNewButton';
import { setTenders, setModal } from '../../../store/commonReducer';
import { CHAT_FOR_TENDER } from 'common_constants/modals';
import './RespondItem.scss';
import { CheckCircleOutlined } from '@ant-design/icons';
import { translation } from 'common_constants/translation';

const RespondItem = ({ data, tender }) => {
  const dispatch = useDispatch();
  const tenders = useSelector((state) => state.common.tenders) || [];
  const lang = useSelector((state) => state.common.language);

  const handleAcceptTenderProposal = () => {
    const body = {
      tenderId: tender._id,
      userId: data.userId,
      proposalId: data._id,
    };
    const onSuccess = (res) => {
      success(translation.respondItemSuccess[lang]);
      dispatch(setTenders(tenders.map((i) => (i._id === tender._id ? { ...i, accepted: true, userId: data.userId, respondList: [data] } : i))));
    };
    const onError = (res) => {
      error();
    };
    request(`/tenders/clientAcceptTenderProposal`, body, onSuccess, onError);
  };
  return (
    <div className="respond-item">
      <div className="respond-item-info">
        <Link to={`/cv/${data.userId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <div className="respond-item-photo">
            <UserAvatar style={{ width: '80px', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} user={data} />
          </div>
        </Link>
        <div>
          <Link to={`/cv/${data.userId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="respond-item-text">
              <b>{translation.name[lang]}</b> {data.name}
            </div>
          </Link>
          <div className="respond-item-text">
            <b>{translation.date[lang]}</b> {dayjs(data.createdAt).format('DD.MM.YYYY')}
          </div>
          <div className="respond-item-text">
            <b>{translation.term[lang]}</b> {TENDER_TERMS_FOR_EADVOKAT[lang].find((i) => i.value === data.term)?.label}
          </div>
          <div className="respond-item-text">
            <b>{translation.sum[lang]}</b> {data.fee}₴
          </div>
        </div>
      </div>
      <div className="respond-item-content">
        <div>
          <b>{translation.proposal[lang]} </b>
          {data.proposal}
        </div>
        <div>
          <b>{translation.result[lang]} </b>
          {data.taskResult}
        </div>
      </div>
      {!tender?.accepted && (
        <BalanceNewButton onClick={handleAcceptTenderProposal} buttonText={translation.agreeBtn[lang]} arrow={false} style={{ marginTop: 20 }} />
      )}
      {tender?.accepted ? <CheckCircleOutlined className="confirmation-icon" /> : null}
      <BalanceNewButton
        onClick={() => dispatch(setModal({ name: CHAT_FOR_TENDER, data: { tenderId: tender._id, respondId: data._id, lawyer: data.name } }))}
        buttonText={translation.chat[lang]}
        arrow={false}
        style={{ marginTop: 20 }}
      />
    </div>
  );
};

export default RespondItem;
