import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import Typography from '../../components/Typography';
import { Box } from 'common_components';
import SearchSelect from '../../components/SearchSelect';
import Space from '../../components/Space';
import Spin from '../../components/Spin';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import InputRange from '../../components/InputRange';
import Textarea from '../../components/Textarea';
import { setModal, editHotline, editHistoryHotline } from '../../store/commonReducer';
import { request, warn, info, error } from '../../tools';
import { translation } from 'common_constants/translation';
import {
  LOGICAL_STARTED_ON_TIME_CHOISE_3_OPTIONS_FOR_EADVOKAT,
  LOGICAL_STANDARD_CHOISE_2_OPTIONS_FOR_EADVOKAT,
  LOGICAL_WOULD_YOU_AGREE_CHOISE_3_OPTIONS_FOR_EADVOKAT,
} from 'common_constants/business/index';
import {
  QUESTIONNAIRE_HOTLINE_CERTIFICATE,
  ADD_CLIENT_EMAIL,
  FIRST_LOGIN_AUTO_PAY,
  QUESTIONNAIRE_HOTLINE_ADVERTISING,
} from 'common_constants/modals';
import './styles.scss';

const QuestionnaireHotline = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.common.modal) || {};
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const missingEmail = useSelector((state) => state.common.missingEmail);
  const lang = useSelector((state) => state.common.language);
  const { Strong } = Typography;

  const [loading, setLoading] = useState(false);
  const [qh, setQH] = useState({
    qc: 1, // * Оцініть якість консультації за 10-бальною шкалою \_\_Number()
    qs: 1, // * Оцініть привітність персоналу АО "Захист" за 10-бальною шкалою \_\_Number()
    qk: undefined, // * Чи вчасно розпочалася консультація? \_\_Number()
    qp: undefined, // * Чи влаштувала Вас ціна консультації? \_\_Number()
    qa: undefined, // * Чи зрозумілою для Вас була консультація? \_\_Number()
    ql: undefined, // * Чи зрозумілою для Вас мовою проводилася консультація? \_\_Number()
    qv: undefined, // * Чи запропонували Вам оформити Клубну карту АО "Захист"? \_\_Number()
    qd: undefined, // * Чи уклали Ви договір з нашою компанією? \_\_Number()
    qt: '', // * Якщо у Вас залишилися питання чи пропозиції до нас, напишіть про це \_\_String()
  });
  const handleDescriptionChange = (e) => {
    setQH({ ...qh, qt: e.target.value });
  };

  const handleChainFirstModals = () => {
    if (missingEmail) {
      dispatch(setModal({ name: ADD_CLIENT_EMAIL }));
    } else if (sessionStorage.getItem('modalFirst') !== 'true') {
      dispatch(setModal({ name: FIRST_LOGIN_AUTO_PAY }));
    } else {
      dispatch(setModal());
    }
  };

  const validateForm = () => {
    const requiredFields = ['qc', 'qs', 'qk', 'qp', 'qa', 'ql', 'qv', 'qd'];
    for (const field of requiredFields) {
      if (qh[field] === undefined || qh[field] === '') {
        warn('', translation[`questionnaireHotlineWarn${requiredFields.indexOf(field) + 1}`][lang]);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    if (!clientInfo._id) {
      warn('', translation.questionnaireArchiveWarn1[lang]);
      return;
    }

    setLoading(true);
    const transaction = {
      qh,
      _id: clientInfo._id,
      ph: clientInfo.ph,
      H: data.H,
      chn: !!data.chn,
      filName: data.filName,
      fbBonus: qh.qc >= 7 && qh.qs >= 7 && qh.ql === 0 && qh.qa === 0,
    };

    const handleSuccess = (req) => {
      if (req.data) {
        dispatch(editHotline(req.data));
        dispatch(editHistoryHotline(req.data));
      }

      if (transaction.fbBonus) {
        dispatch(setModal({ name: QUESTIONNAIRE_HOTLINE_ADVERTISING, transaction }));
      } else if (data.chn === undefined || data.chn === null) {
        info('', translation.questionnaireHotlineInfo1[lang]);
        dispatch(setModal({ name: QUESTIONNAIRE_HOTLINE_CERTIFICATE, transaction }));
      } else if (missingEmail) {
        info('', translation.questionnaireHotlineInfo2[lang]);
        dispatch(setModal({ name: ADD_CLIENT_EMAIL }));
      } else {
        info('', translation.questionnaireHotlineInfo3[lang]);
        handleChainFirstModals();
      }
    };

    request('/hotline/QuestionnaireHotlineSave', transaction, handleSuccess, error);
    setLoading(false);
  };

  const handleCancel = () => {
    info('', translation.questionnaireHotlineInfo4[lang], '', lang);
    handleChainFirstModals();
  };

  const styleSpanSlider = { display: 'flex', alignItems: 'center', justifyContent: 'space-between' };
  const styleSlider = { width: '100%', margin: '12px 15px 8px' };

  const styleSliderIcon = (place, value) => {
    const colors = { Frown: value > 5 ? '#bfbfbf' : '#1677ff', Smile: value > 5 ? '#1677ff' : '#bfbfbf' };
    return { fontSize: '22px', color: colors[place] || '#bfbfbf' };
  };

  return (
    <Modal open={true} className="questionnaire-hotline" title={translation.questionnaireHotlineTitle[lang]} onCancel={handleCancel} footer={null}>
      <Spin tip={translation.loader1[lang]} spinning={loading}>
        <Box mt={16}>
          <Space direction="vertical">
            <Strong>{translation.questionnaireHotlineText1[lang]}</Strong>

            <Box mt={16}>
              <Strong>{translation.questionnaireHotlineText2[lang]}</Strong>
              <span style={styleSpanSlider}>
                <FrownOutlined style={styleSliderIcon('Frown', qh.qc)} />
                <InputRange value={qh.qc} onChange={(value) => setQH({ ...qh, qc: value })} style={styleSlider} />
                <SmileOutlined style={styleSliderIcon('Smile', qh.qc)} />
              </span>
            </Box>

            <Box mt={16}>
              <Strong>{translation.questionnaireHotlineText3[lang]}</Strong>
              <span style={styleSpanSlider}>
                <FrownOutlined style={styleSliderIcon('Frown', qh.qs)} />
                <InputRange value={qh.qs} onChange={(value) => setQH({ ...qh, qs: value })} style={styleSlider} />
                <SmileOutlined style={styleSliderIcon('Smile', qh.qs)} />
              </span>
            </Box>

            <Box mt={16}>
              <Strong>{translation.questionnaireHotlineText4[lang]}</Strong>
              <SearchSelect
                allowClear
                list={LOGICAL_STARTED_ON_TIME_CHOISE_3_OPTIONS_FOR_EADVOKAT[lang]}
                value={qh.qk}
                placeholder={translation.placeholderChooseOption[lang]}
                onChange={(item) => setQH({ ...qh, qk: item })}
              />
            </Box>

            <Box mt={16}>
              <Strong>{translation.questionnaireHotlineText5[lang]}</Strong>
              <SearchSelect
                allowClear
                list={LOGICAL_STANDARD_CHOISE_2_OPTIONS_FOR_EADVOKAT[lang]}
                value={qh.qp}
                placeholder={translation.placeholderChooseOption[lang]}
                onChange={(item) => setQH({ ...qh, qp: item })}
              />
            </Box>

            <Box mt={16}>
              <Strong>{translation.questionnaireHotlineText6[lang]}</Strong>
              <SearchSelect
                allowClear
                list={LOGICAL_STANDARD_CHOISE_2_OPTIONS_FOR_EADVOKAT[lang]}
                value={qh.qa}
                placeholder={translation.placeholderChooseOption[lang]}
                onChange={(item) => setQH({ ...qh, qa: item })}
              />
            </Box>

            <Box mt={16}>
              <Strong>{translation.questionnaireHotlineText7[lang]}</Strong>
              <SearchSelect
                allowClear
                list={LOGICAL_STANDARD_CHOISE_2_OPTIONS_FOR_EADVOKAT[lang]}
                value={qh.ql}
                placeholder={translation.placeholderChooseOption[lang]}
                onChange={(item) => setQH({ ...qh, ql: item })}
              />
            </Box>

            <Box mt={16}>
              <Strong>{translation.questionnaireHotlineText8[lang]}</Strong>
              <SearchSelect
                allowClear
                list={LOGICAL_STANDARD_CHOISE_2_OPTIONS_FOR_EADVOKAT[lang]}
                value={qh.qv}
                placeholder={translation.placeholderChooseOption[lang]}
                onChange={(item) => setQH({ ...qh, qv: item })}
              />
            </Box>

            <Box mt={16}>
              <Strong>{translation.questionnaireHotlineText9[lang]}</Strong>
              <SearchSelect
                allowClear
                list={LOGICAL_WOULD_YOU_AGREE_CHOISE_3_OPTIONS_FOR_EADVOKAT[lang]}
                value={qh.qd}
                placeholder={translation.placeholderChooseOption[lang]}
                onChange={(item) => setQH({ ...qh, qd: item })}
              />
            </Box>

            <Box mt={16}>
              <Strong>{translation.questionnaireHotlineText10[lang]}</Strong>
              <Textarea
                placeholder={translation.questionnaireHotlinePlaceholder[lang]}
                aria-label="Write proposals and complaints"
                value={qh.qt}
                onChange={handleDescriptionChange}
              />
            </Box>
          </Space>
        </Box>
        <Box className="group-btn-questionnaire" mt={16}>
          <Button className="btn-questionnaire" onClick={handleSubmit}>
            {translation.sendButton[lang]}
          </Button>
          <Button className="btn-questionnaire light danger" onClick={handleCancel}>
            {translation.leaveForLaterBtn[lang]}
          </Button>
        </Box>
      </Spin>
    </Modal>
  );
};

export default QuestionnaireHotline;
