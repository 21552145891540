import ModalWindow from '../ModalWindow';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { request, error as handleError } from '../../tools';
import { useSelector } from 'react-redux';
import { PaymentManagementBox } from 'common_components';
import { translation } from 'common_constants/translation';
import dayjs from 'dayjs';
import './ClientPaymentManagement.scss';
import Button from '../../components/Button/Button';
import { Spin } from '../../components';
import { useToaster } from '../../tools/hooks';
import { CURRENCIES } from 'common_constants/business';

const PAY_FOR_ASSIGNMENT = '4';

const ClientPaymentManagement = ({ modal, setModal, contract, transactions }) => {
  const lang = useSelector((state) => state.common.language);

  return (
    <ModalWindow title={`${translation.contractTransactions[lang]}`} modal={modal} setModal={setModal} onCancel={() => setModal(false)}>
      {transactions.length > 0 && <div>{transactions}</div>}
    </ModalWindow>
  );
};

export default ClientPaymentManagement;
