import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { locales } from '../locales';

export default function DatePicker({ onDateSelect, fil, data, lang }) {
  const pointerEvents = fil === undefined ? 'none' : '';

  const opacity = pointerEvents === 'none' ? 0.6 : 1;

  const calendarValue = data?.date
    ? {
        year: data?.date?.year(),
        month: data?.date?.month() + 1,
        day: data?.date?.date(),
      }
    : utils().getToday();

  return (
    <Calendar
      locale={locales[lang]}
      value={calendarValue}
      style={{ pointerEvents, opacity }}
      onChange={onDateSelect}
      minimumDate={utils().getToday()}
    />
  );
}
