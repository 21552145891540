import './InputNew.scss';
import Button from '../Button';
import ShowPassword from './ShowPassword';

const InputNew = (props) => {
  if (props?.id === 'submit')
    return (
      <Button type="submit" variant="rhf-submit default" {...props?.options}>
        {props?.options?.value}
      </Button>
    );
  if (props?.rHFormOptions) {
    const { options = {}, id, rHFormOptions, register, inputName } = props;
    return (
      <div className="wrapper">
        {props?.prefix && <span className="prefix">{props?.prefix}</span>}
        <input className={`input-new ${props?.prefix ? 'with-prefix' : ''}`} {...options} {...register(id, rHFormOptions)} />
        {options.type === 'password' && <ShowPassword inputName={inputName}></ShowPassword>}
      </div>
    );
  }
  return <input className="input-new" {...props} />;
};

export default InputNew;
