import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spin, Popconfirm } from '../../components';
import { PAYMENT_FRAME } from 'common_constants/modals';
import { CLIENT_ROUTES } from 'common_constants/routes';
import RedButtonContactModal from '../../Modals/RedButtonContactModal';
import { ReactComponent as RedBtn } from '../../images/redbtn-icon.svg';
import { RED_BUTTON_PRICE, CONTRACT_AUTOPAY_CASHBACK, PAYMENT_STATUS, CURRENCIES } from 'common_constants/business';

import { error, request, success } from '../../tools/index.js';
import { setModal } from '../../store/commonReducer';
import { CheckClientInfo } from '../../tools/hooks';
import { countContractDebt } from '../../tools';
import reactNativeService from '../../tools/reactNativeService.js';
import BalanceNewButton from '../../components/Balance/BalanceNewButton';
import ModalWindow from '../../Modals/ModalWindow/ModalWindow.jsx';
import { translation } from 'common_constants/translation';

import './RedButtonPage.scss';

const RedButtonPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);
  const contracts = useSelector((state) => state.common.contracts);
  const [openRedButtonModal, setOpenRedButtonModal] = useState(false);
  const { paymentCard, clientBalance } = userInfo;

  const [loading, setLoading] = useState(false);
  const [addCardModal, setAddCardModal] = useState(false);

  const redButton = contracts.find((contract) => contract.rb === true && !contract.ad);
  const redButtonActive = countContractDebt(redButton) <= 0;

  const buyRedButton = () => {
    if (!paymentCard && clientBalance > RED_BUTTON_PRICE) {
      setAddCardModal(true);
      return;
    }

    const body = { clientId: userInfo._id, ph: userInfo.ph, amount: RED_BUTTON_PRICE, currency: userInfo.currency };
    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location = res.checkoutUrl;
        return;
      }
      if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
        setTimeout(() => {
          CheckClientInfo(dispatch);
          history.push(CLIENT_ROUTES.CLIENT_HOME.route);
          success(translation.buyRedButtonSuccess[lang]);
          setLoading(false);
        }, 1000);
      }
      if (res.paymentStatus === PAYMENT_STATUS.PROCESSING) {
        const timerId = setInterval(() => {
          const onSuccess = (res) => {
            if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
              clearInterval(timerId);
              CheckClientInfo(dispatch);
              history.push(CLIENT_ROUTES.CLIENT_HOME.route);
              success(translation.buyRedButtonSuccess[lang]);
              setLoading(false);
            }
            if (res.paymentStatus === PAYMENT_STATUS.FAILURE) {
              clearInterval(timerId);
              CheckClientInfo(dispatch);
              error(translation.addInsuranceError[lang]);
              setLoading(false);
            }
          };
          request('/payment/mono/status', { invoiceId: res.invoiceId }, onSuccess, onError);
        }, 1000);
      }
    };
    const onError = (_, __, axiosError) => {
      error(translation.addInsuranceError[lang], axiosError.error);
    };
    setLoading(true);
    request('/payment/mono/redButton/link', body, onSuccess, onError);
  };

  const handleOpenPayWindow = () => {
    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location.href = res.checkoutUrl;
      }
    };
    const onError = (_, __, axiosError) => error('', 'axiosError.error');
    request('/payment/mono/bindCard/link', { redirectUrl: '/redButton' }, onSuccess, onError);
  };
  return (
    <Spin spinning={loading} tip={translation.loader1[lang]}>
      {redButtonActive ? (
        <div className="redButton_page">
          <h2 className="text">{translation.activeRedButtonTitle[lang]}</h2>

          <div onClick={() => setOpenRedButtonModal(true)} className={`menu-red-button visible ${redButtonActive ? '' : 'disabled'} `}>
            <div className="menu-red-button-text">
              {translation.redButton[lang]} <br />№{redButton.i}
            </div>
            <RedBtn className="menu-red-button-image" height={150} width={150} />
          </div>

          <RedButtonContactModal open={openRedButtonModal} setOpen={setOpenRedButtonModal} redButton={redButton} />
        </div>
      ) : (
        <div className="redButton_page">
          <div>
            <h2 className="text">{translation.buyRedButtonTitle[lang]}</h2>
            <p className="info-description">{translation.redButtonDescription[lang]}</p>
            <p className="info-title">{translation.redButtonInfoTitle1[lang]}</p>
            <ul className="info-list">
              <li>
                {translation.redButtonInfoList1_1[lang]} {RED_BUTTON_PRICE} {CURRENCIES[userInfo.currency].symbol}
              </li>
              <li>{translation.redButtonInfoList1_2[lang]}</li>
            </ul>
            <p className="info-title">{translation.redButtonInfoTitle2[lang]}</p>
            <ul className="info-list">
              <li>{translation.redButtonInfoList2_1[lang]}</li>
              <li>{translation.redButtonInfoList2_2[lang]}</li>
              <li>{translation.redButtonInfoList2_3[lang]}</li>
              <li>{translation.redButtonInfoList2_4[lang]}</li>
            </ul>
            <iframe
              src="https://www.youtube.com/embed/yaL9cAkrbWo?si=WD9XzK2ML8A5oR24"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
              className="video"
            ></iframe>
            <h2 className="text">
              {translation.cost[lang]} {RED_BUTTON_PRICE} {CURRENCIES[userInfo.currency].symbol}
            </h2>
            <BalanceNewButton onClick={!loading ? buyRedButton : null} buttonText={translation.buyBtn[lang]} arrow={false} />
            <br />
            <p className="text">
              {translation.redButtonText[lang]} <b>{CONTRACT_AUTOPAY_CASHBACK * 100}%</b>
            </p>
          </div>
          <ModalWindow modal={addCardModal} setModal={setAddCardModal} title={translation.redBtnModalTitle[lang]}>
            <>
              {paymentCard ? (
                <div className="redButton_page">
                  <div className="buyButton-text">{translation.redBtnModalText1[lang]}</div>
                  <br />
                  <br />
                  <BalanceNewButton onClick={!loading ? buyRedButton : null} buttonText={translation.buyBtn[lang]} arrow={false} />
                </div>
              ) : (
                <div className="redButton_page">
                  <div className="buyButton-text">
                    {translation.redBtnModalText2[lang]}
                    <br />
                    <br />
                    <Popconfirm
                      title={translation.popconfirmTitle[lang]}
                      description={
                        <div>
                          <div>{translation.popconfirmText1[lang]}</div>
                          <div>{translation.popconfirmText2[lang]}</div>
                        </div>
                      }
                      onConfirm={handleOpenPayWindow}
                      okText={translation.yes[lang]}
                      cancelText={translation.no[lang]}
                    >
                      <div className="cardForPayments_button">+</div>
                    </Popconfirm>
                    <br />
                  </div>
                </div>
              )}
            </>
          </ModalWindow>
        </div>
      )}
    </Spin>
  );
};

export default RedButtonPage;
