import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, NiceBtn } from 'common_components';
import { CLIENT_ROUTES } from 'common_constants/routes';
import { translation } from 'common_constants/translation';
import Modal from '../../components/Modal';

import { setModal } from '../../store/commonReducer';
import { info } from '../../tools';
import './styles.scss';

import { ADD_CLIENT_EMAIL } from 'common_constants/modals';

const QuestionnaireHotlineCertificate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const missingEmail = useSelector((state) => state.common.missingEmail);
  const lang = useSelector((state) => state.common.language);

  const onCancel = () => {
    info('', translation.questionnaireArchiveAdvertisingInfo[lang]);
    missingEmail ? dispatch(setModal({ name: ADD_CLIENT_EMAIL })) : dispatch(setModal());
  };

  const onClick = () => {
    dispatch(setModal());
    history.push(CLIENT_ROUTES.ANONIM_CERTIFICATE_.route + clientInfo.ph);
  };

  return (
    <Modal className="questionnaire-archive-certificate" title="" open onCancel={onCancel} footer={null}>
      <Box
        style={{
          borderBottom: '1px solid #040404',
          paddingBottom: '16px',
          marginBottom: '16px',
        }}
      >
        {translation.qualityOk[lang]}
      </Box>
      {translation.cert1[lang]} <b> {translation.cert2[lang]}</b>
      <br />
      <br />
      {translation.cert3[lang]}
      <b> {translation.cert4[lang]}</b>
      <br />
      <br />
      {translation.cert5[lang]}
      <br />
      <br />
      {translation.cert6[lang]}
      <Box
        style={{
          textAlign: 'center',
          margin: '24px auto 8px',
        }}
      >
        <NiceBtn onClick={onClick}>{translation.cert7[lang]}</NiceBtn>
      </Box>
    </Modal>
  );
};

export default QuestionnaireHotlineCertificate;
