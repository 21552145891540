import { useDispatch } from 'react-redux';
import HotlinesList from '../../components/HotlinesList';
import { useSelector } from 'react-redux';
import { error, request } from '../../tools';
import { setPartnerHotlines } from '../../store/commonReducer';
import { useEffect, useState } from 'react';

export default function PartnerHotlines() {
  const hotlines = useSelector((state) => state.common.partnerHotlines);
  const dispatch = useDispatch();
  const countryPhCode = useSelector((state) => state.common.clientInfo?.countryPhCode);
  const phone = useSelector((state) => state.common.clientInfo?.ph);

  const [isLoading, setIsLoading] = useState(false);

  const getHotlines = async () => {
    setIsLoading(true);
    await request(
      '/partnersHotlines/getByPhoneClient',
      { phone, countryPhCode },
      ({ data }) => {
        dispatch(setPartnerHotlines(data ?? []));
      },
      error,
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (Array.isArray(hotlines)) {
      return;
    }

    getHotlines();
  }, [hotlines]);

  const historyHotlines = hotlines?.filter((hotline) => hotline.confirmDate);
  const currentHotlines = hotlines?.filter((hotline) => !hotline.confirmDate);

  return (
    <HotlinesList
      historyHotlines={historyHotlines}
      clientHotlines={currentHotlines ? [currentHotlines] : undefined}
      hotlinesLoaded={!isLoading && Array.isArray(hotlines)}
      isPartner
    />
  );
}
