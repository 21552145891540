import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { CHAT_FOR_CONTRACT, CHAT_WITH_ACCOUNTANT } from 'common_constants/modals';

import {
  ArrowLeftOutlined,
  BoldOutlined,
  ClockCircleOutlined,
  FileTextOutlined,
  HistoryOutlined,
  HomeOutlined,
  LogoutOutlined,
  MessageOutlined,
  TeamOutlined,
  PlusCircleFilled,
  ContactsOutlined,
  LikeOutlined,
  HddOutlined,
  FireOutlined,
  SolutionOutlined,
  FileSearchOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { NOTIFICATION, RED_BUTTON } from 'common_constants/business';
import { CONFIRM_EMAIL_ANONIM } from 'common_constants/modals';
import { CLIENT_ROUTES, WEB_SITE_LINK } from 'common_constants/routes';
import { translation } from 'common_constants/translation';
import clsx from 'clsx';

import Contacts from '../Contacts/Contacts';
import UserAvatar from '../UserAvatar/UserAvatar';
import { editClientInfo, setContractChat, setModal } from '../../store/commonReducer';
import { setMobSideBar } from '../../store/uiReducer';
import { clientLogOut, countContractDebt, request } from '../../tools';
import ClientBalancePayModal from './ClientBalancePayModal';
import { fetchRemoveNotifications, fetchRemovePushes } from '../../pages/ClientContracts/ClientContractsHelpers';
import { PAY_ICON } from '../Partner/PartnerButtons/Icons';
import QRCodeScannerContainer from '../QRCodeReader';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import RedButtonContactModal from '../../Modals/RedButtonContactModal';
import { ReactComponent as RedBtn } from '../../images/redbtn-icon.svg';
import payRedButtonDebt from './PayRedButtonDebt';
import Badge from '../Badge/Badge';
import Button from '../ProfileButton/Button';
import Dropdown from '../Dropdown/Dropdown';
import './ClientMenu.scss';
import QRCodeScanButton from '../QRCodeReader/QRCodeScanButton';

export const ClientMenu = () => {
  const location = useLocation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [clientInfo, clientAuth, contracts, hotlines, { mobSidebar }, contractId, lang, pushes] = [
    useSelector((state) => state.common.clientInfo),
    useSelector((state) => state.common.clientAuth),
    useSelector((state) => state.common.contracts) ?? [],
    useSelector((state) => state.common.hotlines) ?? [],
    useSelector((state) => state.ui),
    useSelector((state) => state.common.contract?._id),
    useSelector((state) => state.common.language),
    useSelector((state) => state.common.clientInfo.push_notifications),
  ];

  const contract = contracts?.find((item) => item._id === contractId);

  const combinedNotifEventsPartHotline = hotlines?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0,
    [combinedNotifCorrespondence, combinedNotifTasks, combinedNotifEvents, combinedUnreadComments] = [
      contracts.reduce((combined, contract) => {
        combined.push(
          ...(contract.correspondence || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true)),
        );
        return combined;
      }, [])?.length ?? 0,
      contracts.reduce((combined, contract) => {
        combined.push(...(contract.tasks || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true)));
        return combined;
      }, [])?.length ?? 0,
      combinedNotifEventsPartHotline +
        (hotlines?.filter((item) => item.s)?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0) +
        (contracts.reduce((combined, contract) => {
          combined.push(...(contract.assignments || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true)));
          return combined;
        }, [])?.length ?? 0),
      contracts.reduce(
        (sum, contract) =>
          sum + contract?.tasks?.reduce((sum, item) => sum + (item?.comments?.reduce((acc, curr) => (curr.userNotSaw ? acc + 1 : acc), 0) ?? 0), 0),
        0,
      ),
    ],
    multiNotificationsInContracts = combinedNotifCorrespondence + combinedNotifTasks + combinedNotifEvents + combinedUnreadComments;

  const redButton = contracts.find((contract) => contract.rb === true && !contract?.ad);

  const debt = countContractDebt(redButton);
  const redButtonActive = debt <= 0;
  const { notif } = clientInfo;
  const partnerTransactionsNewCount = clientInfo?.partner_transactions?.filter?.((i) => !i.saw).length || 0;

  const [isMenuBtnVisible, setMenuBtnVisible] = useState(false);
  const [selected, setSelected] = useState(pathname);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [activeClass, setActiveClass] = useState(false);
  const [open, setOpen] = useState(false);
  const [openRedButtonModal, setOpenRedButtonModal] = useState(false);
  const [redButtonDisabled, setRedButtonDisabled] = useState(false);
  const [isScannerVisible, setScannerVisible] = useState(false);

  function getItem(label, routeData = {}, icon, children, type) {
    const { route } = routeData;

    return {
      key: route,
      icon,
      children,
      label,
      type,
    };
  }

  const notifications = {
    1: {
      label: translation.RegistrationSuccess[lang],
      getRoute: () => CLIENT_ROUTES.CLIENT_HOME.route,
    },
    2: {
      label: translation.changedConsultation[lang],
      getRoute: (hotlineId) => '/' + hotlineId,
    },
    3: {
      label: translation.newConsultation[lang],
      // route: ROUTES.CLIENT_MY_HOTLINES,
      getRoute: (hotlineId) => '/' + hotlineId,
    },
    4: {
      label: translation.newContract[lang],
      getRoute: () => CLIENT_ROUTES.CLIENT_CONTRACTS.route,
    },
  };

  const handleClick = (item) => {
    toggleMenuBtn();
    history.push(item.key);
  };

  const handleBackToSite = () => {
    window.open(WEB_SITE_LINK, '_self');
  };

  const badge_next = (type, color) => {
    return (
      <span>
        &nbsp;
        <Badge className="nav-menu-badge" count={type || ''} color={color ? color : 'white'} />
      </span>
    );
  };

  let unwatchedNotif = notif ? notif.filter((item) => !item.watched) : [];
  let unwatchedPushes = pushes ? pushes.filter((item) => !item.watched) : [];

  const removeNotificationClick = async () => {
    if (unwatchedNotif?.length && clientInfo?._id) {
      const notif = await fetchRemoveNotifications(NOTIFICATION.CLIENTS, clientInfo._id, null, null);
      if (notif) dispatch(editClientInfo({ notif }));
    }

    setOpenDropdown(!openDropdown);
  };

  const content = notif
    ? notif
        .map((elem, i) => {
          const { type } = elem;
          return getItem(
            <Link className={elem.watched ? 'watched' : ''} to={notifications[type].getRoute(type === 3 ? elem.hotlineId : null)}>
              {notifications[type].label}
            </Link>,
            i,
          );
        })
        .reverse()
    : [];
  const pushesContent = unwatchedPushes.map((elem, i) => {
    const defaultOnclick = async () => {
      if (clientInfo?._id) {
        await fetchRemovePushes([elem._id]);
        dispatch(editClientInfo({ push_notifications: pushes.map((p) => (p._id === elem._id ? { ...elem, watched: true } : p)) }));
      }
    };
    if (elem.chatId) {
      let onClick;
      if (elem.contract) {
        const contractId = contracts.find((c) => c.i === elem.contract)?._id;

        onClick = () => {
          defaultOnclick();
          dispatch(setModal({ name: CHAT_FOR_CONTRACT, data: { contractId } }));
        };
      } else if (elem.chatType === 'accountant') {
        onClick = () => {
          defaultOnclick();
          dispatch(setModal({ name: CHAT_WITH_ACCOUNTANT, data: { _id: elem.chatId } }));
        };
      }
      return getItem(
        <button className={elem.watched ? 'watched' : ''} onClick={onClick}>
          {elem.title}
        </button>,
        i,
      );
    } else if (elem.contract) {
      const contractId = contracts.find((c) => c.i === elem.contract)?._id;

      return getItem(
        <Link className={elem.watched ? 'watched' : ''} to={'/contractInfo/' + contractId} onClick={defaultOnclick}>
          {elem.title}
        </Link>,
        i,
      );
    } else {
      return getItem(
        <button className={elem.watched ? 'watched' : ''} onClick={defaultOnclick}>
          {elem.title}
        </button>,
        i,
      );
    }
  });
  const dropdownItems = [...pushesContent, ...content].slice(0, 5);
  const menuProps = {
    items: dropdownItems,
    onClick: () => removeNotificationClick(),
  };

  const items = clientInfo.createdFromPartner
    ? [getItem(<span>{translation.partnerHotline[lang]}</span>, CLIENT_ROUTES.PARTNER_HOTLINE, <ClockCircleOutlined />)]
    : [
        // getItem(<div onClick={() => console.log(1)}>Поповнити баланас</div>, 'balance'),
        getItem(translation.home[lang], CLIENT_ROUTES.CLIENT_HOME, <HomeOutlined />),
        getItem(
          <span className="badge_next">
            {translation.hotline[lang]} {badge_next(combinedNotifEventsPartHotline)}
          </span>,
          CLIENT_ROUTES.CLIENT_HOTLINE,
          <ClockCircleOutlined />,
        ),
        !window?.ReactNativeWebView && getItem(<span>{translation.clientChats[lang]}</span>, CLIENT_ROUTES.CLIENT_CHATS, <MessageOutlined />),
        getItem(<span>{translation.autoPay[lang]} </span>, CLIENT_ROUTES.AUTOPAY_PAGE, <WalletOutlined />),
        getItem(<span>{translation.quickQuestion[lang]}</span>, CLIENT_ROUTES.CLIENT_QUICK_CHAT, <MessageOutlined />),
        getItem(
          <span className="badge_next">
            {translation.myBusiness[lang]} {badge_next(contracts?.length > 0 ? multiNotificationsInContracts : 0)}
          </span>,
          CLIENT_ROUTES.CLIENT_CONTRACTS,
          <FileTextOutlined />,
        ),
        getItem(<span>{translation.tenders[lang]} </span>, CLIENT_ROUTES.CLIENT_TENDERS, <HddOutlined />),
        getItem(<span>{translation.insuranceCard[lang]}</span>, CLIENT_ROUTES.CLIENT_INSURANCE, <TeamOutlined />),
        getItem(translation.balance[lang], CLIENT_ROUTES.CLUB_CARD, <BoldOutlined />),
        getItem(
          <span className="badge_next">
            {translation.partnerCabinet[lang]} {badge_next(partnerTransactionsNewCount)}
          </span>,
          CLIENT_ROUTES.INVITE_FRIEND,
          <TeamOutlined />,
        ),
        {
          type: 'divider',
        },
        getItem(<span>{translation.registerPage[lang]}</span>, CLIENT_ROUTES.REGISTER, <FileSearchOutlined />),
        getItem(translation.clubCard[lang], CLIENT_ROUTES.CLUB_CARD_PAGE, <SolutionOutlined />),
        !window?.ReactNativeWebView && getItem(<span>{translation.contacts[lang]}</span>, CLIENT_ROUTES.CLIENT_CONTACT_US, <ContactsOutlined />),
        getItem(<span>{translation.reviews[lang]}</span>, CLIENT_ROUTES.CLIENTS_FEEDBACK, <LikeOutlined />),
        !redButton && getItem(translation.buyAlarmButton[lang], CLIENT_ROUTES.RED_BUTTON, <FireOutlined />),
      ];

  useEffect(() => {
    if (location.search.includes('emailActivated=true') && !clientAuth) {
      const params = new URLSearchParams(location.search);
      const verificationCode = params.get('verificationCode');
      const email = params.get('email');
      dispatch(setModal({ name: CONFIRM_EMAIL_ANONIM, data: { email, verificationCode } }));
    }
  }, []);

  const profile = () => {
    setMenuBtnVisible(false);
    history.push(CLIENT_ROUTES.CLIENT_PROFILE);
  };

  const handleCollapse = (value) => dispatch(setMobSideBar(!value));

  useEffect(() => {
    setSelected(pathname);
    handleCollapse(true);
  }, [pathname]);

  const menuSliderRef = useRef(null);

  const headerText = {
    [CLIENT_ROUTES.CLIENT_HOME.route]: translation.homePage[lang],
    [CLIENT_ROUTES.CLIENT_HOTLINE.route]: translation.hotline[lang],
    [CLIENT_ROUTES.AUTOPAY_PAGE.route]: translation.autoPay[lang],
    [CLIENT_ROUTES.CLIENT_CONTRACTS.route]: translation.myBusiness[lang],
    [CLIENT_ROUTES.CLIENT_BONUS.route]: translation.bonusCard[lang],
    [CLIENT_ROUTES.INVITE_FRIEND.route]: translation.partnerCabinet[lang],
    [CLIENT_ROUTES.CLUB_CARD.route]: translation.balance[lang],
    [CLIENT_ROUTES.CLIENT_TENDERS.route]: translation.tenders[lang],
    [CLIENT_ROUTES.CLIENT_INSURANCE.route]: translation.insuranceCard[lang],
    [CLIENT_ROUTES.CREATE_TENDER.route]: translation.createTender[lang],
    [CLIENT_ROUTES.CLIENT_TENDER_INFO_HEADER_TEXT.route]: translation.tenderDetails[lang],
    [CLIENT_ROUTES.LAWYER_CV_HEADER_TEXT.route]: translation.portfolio[lang],
    [CLIENT_ROUTES.CLIENT_CHATS.route]: translation.clientChats[lang],
    [CLIENT_ROUTES.CLIENT_QUICK_CHAT.route]: translation.quickQuestion[lang],
    [CLIENT_ROUTES.CLIENT_CONTACT_US.route]: translation.contacts[lang],
    [CLIENT_ROUTES.CLIENTS_FEEDBACK.route]: translation.reviews[lang],
    [CLIENT_ROUTES.RED_BUTTON.route]: '',
    [CLIENT_ROUTES.CLIENT_CONTRACT_INFO_HEADER_TEXT.route]: contract?.n
      ? `«${contract.n}»`
      : contract?.cn
      ? contract.cn === RED_BUTTON.value
        ? `«${RED_BUTTON.label}»`
        : `«${contract.cn}»`
      : `${translation.contractItem[lang]} №${contract?.i}`,
    [CLIENT_ROUTES.CLUB_CARD_PAGE.route]: translation.clubCard[lang],
    [CLIENT_ROUTES.REGISTER.route]: translation.registerPage[lang],
    [CLIENT_ROUTES.REGISTER_LIST.route]: translation.registerPage[lang],
    [CLIENT_ROUTES.REGISTER_SEARCH.route]: translation.registerPage[lang],
    [CLIENT_ROUTES.REGISTER_SELF_SEARCH.route]: translation.registerPage[lang],
    [CLIENT_ROUTES.PARTNER_HOTLINE.route]: translation.partnerHotline[lang],
  };

  const currentPath = pathname.match(/\/(.*?)(\/|$)/)[0];

  const toggleMenuBtn = () => {
    setMenuBtnVisible(!isMenuBtnVisible);
  };

  const handleRedButtonClick = () => {
    if (redButtonDisabled) {
      return;
    }
    if (redButtonActive) {
      setOpenRedButtonModal(true);
    } else {
      payRedButtonDebt(dispatch, setOpen, setOpenRedButtonModal, setRedButtonDisabled, lang);
    }
  };

  const handleOpenScanner = () => {
    setScannerVisible(true);
  };

  const handleCloseScanner = () => {
    setScannerVisible(false);
  };

  return (
    <>
      <div className="menu-header">
        <div className={clsx('client-menu-page', currentPath === CLIENT_ROUTES.CLIENT_CONTRACT_INFO_HEADER_TEXT && '_quotes')}>
          {headerText[currentPath]}
        </div>
        <Button type="danger" className="profile" onClick={profile}>
          <UserAvatar user={clientInfo} style={{ width: 32, height: 32 }} />
        </Button>
      </div>

      <Contacts setActiveClass={setActiveClass} activeClass={activeClass} />
      <Dropdown className="client-notifications" menu={menuProps} removeNotificationClick={removeNotificationClick} />
      <div className={`sidebar-toggler-wrapper ${isMenuBtnVisible ? 'visible' : ''}`} onClick={toggleMenuBtn}></div>
      <div ref={menuSliderRef}>
        <div className={`sidebar ${isMenuBtnVisible ? 'visible' : ''}`}>
          <span className={`btn-icon ${isMenuBtnVisible ? 'visible' : ''}`} onClick={toggleMenuBtn}>
            {/*Three divs for close and open button animation, do not remove*/}
            <div></div>
            <div></div>
            <div></div>
          </span>
          <div className={`menu-header-container ${isMenuBtnVisible ? 'visible' : ''}`}>
            <LanguageSwitcher />
            <Link className={`logo-link ${isMenuBtnVisible ? 'visible' : ''}`} to={CLIENT_ROUTES.CLIENT_HOME}>
              <img src="/logo_no_bg.svg" alt="zahist" width={40} />
            </Link>
          </div>
          <div>
            <ul className="menu-group">
              {items.map((item, index) =>
                item?.key ? (
                  <Link
                    key={index}
                    className={`menu-link ${isMenuBtnVisible ? 'visible' : ''} ${item.key === selected ? 'selected' : ''}`}
                    onClick={handleClick}
                    to={item.key}
                  >
                    {item.icon} {item.label}
                  </Link>
                ) : null,
              )}
              {!clientInfo.createdFromPartner && (
                <>
                  <li onClick={() => setOpen(true)} className={`menu-link ${isMenuBtnVisible ? 'visible' : ''}`}>
                    {PAY_ICON}
                    {translation.topUpYourBalance[lang]}
                    <PlusCircleFilled style={{ color: 'green' }} />
                  </li>
                  <li className={`qr-code-div ${isMenuBtnVisible ? 'visible' : ''}`}>
                    <QRCodeScanButton
                      onClick={handleOpenScanner}
                      className={`menu-link ${isMenuBtnVisible ? 'visible' : ''}`}
                      label={translation.scanQRcode?.[lang] ?? ''}
                    />
                  </li>
                </>
              )}
              <li className={`menu-link ${isMenuBtnVisible ? 'visible' : ''}`} onClick={handleBackToSite}>
                <ArrowLeftOutlined />
                {translation.backToTheSite[lang]}
              </li>
              <li href="#" onClick={clientLogOut} className={`menu-link ${isMenuBtnVisible ? 'visible' : ''}`}>
                <LogoutOutlined />
                {translation.exitTheApplication[lang]}
              </li>
              {redButton && (
                <>
                  <div
                    onClick={handleRedButtonClick}
                    className={`menu-red-button ${redButtonActive ? '' : 'disabled'} ${isMenuBtnVisible ? 'visible' : ''}`}
                  >
                    {redButtonActive ? (
                      <div className="menu-red-button-text">
                        {translation.redButton[lang]} <br /> №{redButton.i}
                      </div>
                    ) : (
                      <div className="menu-red-button-text">
                        {translation.redButtonDebt[lang]}: {debt}₴
                      </div>
                    )}

                    <RedBtn className="menu-red-button-image" height={150} width={150} />
                  </div>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
      {mobSidebar && (
        <div
          className="overlay"
          onClick={() => {
            handleCollapse(mobSidebar);
          }}
        ></div>
      )}
      <QRCodeScannerContainer
        isScannerVisible={isScannerVisible}
        handleCloseScanner={handleCloseScanner}
        modalTitle={translation.scanQRcodeModalTitle?.[lang] ?? ''}
      />
      <ClientBalancePayModal
        open={open}
        setOpen={setOpen}
        arrayPayment={[250, 500, 1000, 1500, 2500, 5000]}
        title={translation.replenishmentBalance[lang]}
        placeholder={translation.clientBalancePayModalPlaceholder[lang]}
        buttonText={translation.clientBalancePayModalButtonText[lang]}
      />
      <RedButtonContactModal open={openRedButtonModal} setOpen={setOpenRedButtonModal} redButton={redButton} />
    </>
  );
};

export default ClientMenu;
