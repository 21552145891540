import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import 'dayjs/locale/uk';
import 'dayjs/locale/ru';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/cs';

import './BonusesTransactionsButton.scss';
import emptyHotline from './../../images/empty-hotlines.svg';
import { translation } from 'common_constants/translation';
import { useSelector } from 'react-redux';

dayjs.extend(isSameOrAfter);

const TransactionsButton = ({ transactions = [], modal }) => {
  const lang = useSelector((state) => state.common.language);
  dayjs.locale(`${lang === 'ua' ? 'uk' : lang}`);
  const lastTransaction = transactions[transactions.length - 1];
  const transactionTime = dayjs(lastTransaction?.createdAt).format('DD MMM YYYY');

  return (
    <div onClick={modal ? () => modal(true) : null} className="transactions-button">
      {lastTransaction ? (
        <>
          <div className="transactions-button-text">
            <span>{translation.transactions[lang]}</span>
            <div className="transactions-arrow-button"></div>
          </div>
          <div className="transactions-bonuses">
            <div className="transactions-bonuses-operation">
              {/* {lastTransaction.de ? <span className="error">(відхилено)</span> : !lastTransaction.a && '(на підтвердженні)'} */}
              <div className="transactions-bonuses-operation-text">
                {lastTransaction?.requestPayment ? translation.transactionsBonuses[lang] : lastTransaction?.invitedClientPhone}
              </div>
              <div className="transactions-bonuses-operation-date">{transactionTime}</div>
            </div>
            <div style={{ textAlign: 'right' }}>
              <div className="transactions-bonuses-value">{`₴${lastTransaction?.amount}`}</div>
              <div className="transactions-bonuses-value-rest">{`${translation.transactionsBonusesValue[lang]} ₴${lastTransaction?.balanceRest}`}</div>
            </div>
          </div>
        </>
      ) : (
        <div className="no-transactions-button">
          <div>{translation.noTransactions[lang]}</div>
          <img src={emptyHotline} alt="emptyHotline" />
        </div>
      )}
    </div>
  );
};

export default TransactionsButton;
