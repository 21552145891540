import dayjs from 'dayjs';

import { WORK_TIME, PSYCHOLOGIST_WORK_TIME } from 'common_constants/business/index';
import { translation } from 'common_constants/translation';
import { Button } from '../../../components';

const HotlineTimePicker = ({
  startT,
  endT,
  blockedArr,
  blockedArrInfo,
  onTimeClick,
  onTimeClear,
  isForCRM,
  appointmentForToday,
  clientHotlinesPerWeek,
  date,
  lang = 'ua',
  psychologistOnly,
  edit,
  dayIndex,
  type,
}) => {
  const isShowTimePicker = (!isForCRM && !appointmentForToday && clientHotlinesPerWeek < 3) || !!isForCRM;
  const isThereDayIndex = dayIndex !== undefined && dayIndex !== null;

  if (!isShowTimePicker) {
    return null;
  }

  const currentDate = dayjs();
  const currentWorkTime = WORK_TIME;

  const workTime = currentWorkTime.map((i, index) => {
    if (!isForCRM && (index >= 39 || index < 8)) return null;
    const startIndex = currentWorkTime.indexOf(startT);
    const endIndex = currentWorkTime.indexOf(endT);
    const betwenTimes = index > startIndex && index < endIndex;
    const selectedClass = startT === i || endT === i || betwenTimes ? 'selected' : '';

    const timeConst = dayIndex ? dayjs(date).add(dayIndex, 'day') : date;

    const [hours, minutes] = i.split(':');
    const time = dayjs().set('hour', hours).set('minute', minutes);

    const blockedClass =
      blockedArr.includes(index) || currentDate.isAfter(timeConst, 'day') || (currentDate.isSame(timeConst, 'day') && time.isBefore(currentDate))
        ? ' blocked'
        : '';

    const blockedInfo = blockedArrInfo ? blockedArrInfo[index] : null;

    const buttonContent = (
      <Button
        style={{
          height: '32px',
          width: '65px',
          padding: 0,
        }}
        key={i}
        disabled={!!blockedClass}
        className={[selectedClass, blockedClass, 'time-button'].join(' ')}
        onClick={(e) => onTimeClick(e, { dayIndex: dayIndex })}
        variant="light"
      >
        {i}
      </Button>
    );

    return blockedClass && blockedInfo && psychologistOnly ? (
      <Button key={i} title={`Клієнт: ${blockedInfo.clientName}, Час: ${blockedInfo.startT} - ${blockedInfo.endT}`}>
        {buttonContent}
      </Button>
    ) : (
      buttonContent
    );
  });

  return (
    <div className="time">
      {workTime}
      <br />
      <br />
      {!isThereDayIndex && !edit && (
        <Button danger onClick={onTimeClear}>
          {translation.clear[lang]}
        </Button>
      )}
    </div>
  );
};

export default HotlineTimePicker;
