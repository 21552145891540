import React from 'react';
import ResizeObserver from 'rc-resize-observer';
import { CellMeasurer } from 'react-virtualized';
import dayjs from 'dayjs';

import Message from './Message';

const nowYear = dayjs().format('YYYY');

const ChatMessageWrapper = ({ key, index, style, parent, messages, handleDownloadFile, cache, handleOnContextMenu }) => {
  const message = messages[index];
  const lastMessageDate = index === 0 ? null : dayjs(messages?.[index - 1].date).format('DD.MM.YYYY');
  const messageDate = dayjs(message.date).format('DD.MM.YYYY');
  const messageDateYear = dayjs(message.date).format('YYYY');
  const sameSender = message.sender === messages?.[index + 1]?.sender && messageDate === dayjs(messages?.[index + 1]?.date).format('DD.MM.YYYY');
  let showDataBlock = false;

  if (messageDate !== lastMessageDate) {
    showDataBlock = true;
  }

  return (
    <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
      {({ measure }) => (
        <div key={key} style={style} onContextMenu={(e) => handleOnContextMenu(e, message)}>
          {showDataBlock ? (
            <div className="date-block">{`${dayjs(message.date).format('DD MMMM')} ${nowYear === messageDateYear ? '' : messageDateYear}`}</div>
          ) : null}
          <ResizeObserver
            onResize={() => {
              if (message.fileId) {
                measure();
              }
            }}
          >
            <Message item={message} sameSender={sameSender} onDownload={() => handleDownloadFile(message.fileId, message.fileName)} />
          </ResizeObserver>
        </div>
      )}
    </CellMeasurer>
  );
};

export default ChatMessageWrapper;
