import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { setContract } from '../../../../store/commonReducer';

import { translation } from 'common_constants/translation';
import { error, request } from '../../../../tools';
import { icon_files } from '../../images';

import ModalWindow from '../../../../Modals/ModalWindow';

import { ClientButton } from '../../../../components';
import AddFileTabList from '../../AddFileTabList/AddFileTabList';
import MultyItemCorAndTab from '../MultyItemCorAndTab';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import 'dayjs/locale/ru';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/cs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import '../../ClientContractInfo.scss';

const TabBtnAndModal = () => {
  dayjs.extend(isSameOrAfter);

  const dispatch = useDispatch();

  const params = useParams(); // take id from link

  const [lang, contracts, clientInfo] = [
    useSelector((state) => state.common.language),
    useSelector((state) => state.common.contracts) ?? [],
    useSelector((state) => state.common.clientInfo) ?? [],
  ];

  dayjs.locale(`${lang === 'ua' ? 'uk' : lang}`);

  const [tab, setTab] = useState(false);
  const [massegeList, setMassegeList] = useState([]);

  const [contract, correspondence] = [
    contracts.find((i) => i._id === params.id),
    Array.isArray(contracts)
      ? JSON.parse(JSON.stringify(contracts.find((i) => i._id === params.id)?.correspondence ?? []))?.sort((a, b) => new Date(b.t) - new Date(a.t)) ??
        []
      : [],
  ];

  const filesTabList = [
    ...(massegeList?.filter((item) => typeof item.fileId === 'string')?.map((item) => ({ ...item, whereIsTheFileFrom: 'chatClientToСurator' })) ??
      []),
    ...(correspondence?.filter((item) => item.df?.length > 0)?.map((item) => ({ ...item, whereIsTheFileFrom: 'correspondence', date: item.t })) ??
      []),
  ]
    ?.sort((a, b) => dayjs(b.date).diff(dayjs(a.date)))
    ?.map((item) => <MultyItemCorAndTab key={item.fileId ?? item.date ?? dayjs().valueOf()} item={item} clientInfo={clientInfo} />);

  const onGetMessages = (callback) => {
    request(
      '/chatPrivate/getchatClient',
      { documentId: params.id },
      (req) => {
        callback(
          req?.data?.map((item) => ({
            ...item,
          })) ?? [],
        );
      },
      error,
    );
  };

  const setNewMessage = (value) => {
    setMassegeList((prev) => [...prev, value]);
  };

  useEffect(() => {
    dispatch(setContract(contract));
  }, []);

  useEffect(() => {
    if (!tab) return;
    onGetMessages(setMassegeList);
  }, [tab]); //* Getting the list from the chat via the files button

  // todo: add 404 page
  if (!contract) return null;

  return (
    <Fragment>
      <ClientButton setModal={setTab} text={translation.clientCInfCuratorButton9[lang]} icon={icon_files} badge={0} />

      <ModalWindow
        modal={tab}
        setModal={setTab}
        children={[<AddFileTabList key={dayjs().valueOf()} auditClose={tab} contractId={params.id} massegeList={setNewMessage} />, ...filesTabList]}
      />
    </Fragment>
  );
};

export default TabBtnAndModal;
