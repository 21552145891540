import './FirstHotlineBonus.scss';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Modal } from '../../components';
import { setClientBalance, setModal, setHotlineBonus } from '../../store/commonReducer';
import { error, request } from '../../tools';
import { translation } from 'common_constants/translation';
import { setConfetti } from '../../store/uiReducer';

const HotlineBonus = () => {
  const dispatch = useDispatch();
  const bonus = useSelector((state) => state.common?.clientInfo?.hotlineBonus);
  const balance = useSelector((state) => state.common?.clientInfo?.clientBalance);
  const lang = useSelector((state) => state.common.language);

  console.log(balance);
  const onClose = () => {
    dispatch(setModal());
    dispatch(setConfetti({ visible: false }));
    dispatch(setHotlineBonus());
    request(
      '/clients/claimHotlineBonus',
      { sugarId: bonus.sugarId },
      (res) => {
        const newBalance = (balance ?? 0) + res.amount;
        dispatch(setClientBalance(newBalance));
      },
      error,
    );
  };

  return (
    <Modal title={translation.firstHotlineBonusTitle[lang]} open footer={false} onCancel={onClose} className={'FirsthotlineBonus'}>
      <p>
        {translation.firstHotlineBonusText1[lang]} {bonus.amount}
      </p>
      <Button onClick={onClose}>{translation.firstHotlineAccept[lang]}</Button>
    </Modal>
  );
};

export default HotlineBonus;
