import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Input from '../Input';
import Form from '../Form';
import Modal from '../Modal';
import Button from '../Button';
import { translation } from 'common_constants/translation';

import { error, info, success } from '../../tools';

import './PaymentCard.scss';

const PaymentCard = ({ paymentCardModal, setPaymentCardModal, paymentCard, setPaymentCard }) => {
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const lang = useSelector((state) => state.common.language);
  const [cardNumber, setCardNumber] = useState(['', '', '', '']);
  const [form, setFields] = Form.useForm();

  const handleInputChange = (event, index) => {
    const filteredValue = event.target.value.replace(/[^\d]/g, '');
    event.target.value = filteredValue;
    const newCardNumber = [...cardNumber];
    newCardNumber[index] = filteredValue;
    setCardNumber(newCardNumber);

    if (filteredValue.length === 4 && index < 3) {
      const nextInput = document.querySelectorAll('.payment-card-input')[index + 1];
      nextInput.focus();
    }
  };

  const handleInputBlur = (event, index) => {
    const { value } = event.target;
    if (value.length < 4) {
      setFields(`field${index}`, {
        value,
        errors: ['4 цифри'],
      });
    }
  };

  const onFinish = () => {
    const cardNumberValue = cardNumber.join('');

    if (cardNumberValue === paymentCard) {
      info(translation.paymentCardinfo[lang]);
      setFields({});
      return;
    }

    // todo: make it request call instead of fetch
    fetch(process.env.REACT_APP_API + '/partners/setPaymentCard', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + clientAuth.token },
      body: JSON.stringify({ partnerId: clientAuth._id, paymentCardNumber: cardNumberValue }),
    })
      .then((res) => res.json())
      .then(({ status, err }) => {
        if (status) {
          setPaymentCard(cardNumberValue);
          setFields({});
          setCardNumber(['', '', '', '']);
          success(
            `${translation.paymentCardSuccess1[lang]} ${
              paymentCard ? translation.paymentCardSuccess2[lang] : translation.paymentCardSuccess3[lang]
            }!`,
          );
          return;
        }

        if (err) {
          error(err, ' ');
          setFields({});
        }
      });
  };

  const inputsLabel = paymentCard ? translation.paymentCardUpdate[lang] : translation.paymentCardAdd[lang];

  const inputs = Object.keys(cardNumber).map((key) => {
    const currentIndex = Number(key);
    const nextIndex = currentIndex + 1;
    const INPUT_LENGTH = 4;

    return (
      <Form.Item key={key} name={`field${nextIndex}`} rules={[{ required: true, message: `4 ${translation.numbers[lang]}`, len: INPUT_LENGTH }]}>
        <Input
          className="payment-card-input"
          maxLength={INPUT_LENGTH}
          onInput={(event) => handleInputChange(event, currentIndex)}
          onBlur={(event) => handleInputBlur(event, nextIndex)}
        />
      </Form.Item>
    );
  });

  return (
    <Modal
      className="paymentCard"
      title={translation.paymentCardTitle1[lang]}
      open={paymentCardModal}
      onCancel={() => setPaymentCardModal(false)}
      footer={null}
    >
      <div className="paymentCard-number-inner">
        {paymentCard ? (
          <>
            <span className="paymentCard-title">{translation.paymentCardTitle2[lang]} </span>
            <span className="paymentCard__number">{paymentCard?.maskedPan?.match(/.{1,4}/g).join(' ')}</span>
          </>
        ) : (
          <span className="paymentCard-title">{translation.paymentCardTitle3[lang]}</span>
        )}
      </div>

      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600, overflow: 'hidden' }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        className="paymentCard__form"
      >
        <span className="paymentCard__form-label">{`${inputsLabel} ${translation.card[lang]}`}:</span>
        {inputs}
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="submit">{inputsLabel}</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PaymentCard;
