import React from 'react';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';

import InviteFriendImage from '../../images/invite-friend.webp';

const InviteAdvert = () => {
  const lang = useSelector((state) => state.common.language);

  return (
    <div className="invite-advert">
      <p className="text">{translation.inviteShareLinkText[lang]}</p>
      <br />
      <iframe
        width="100%"
        height="200"
        src="https://www.youtube.com/embed/T_2NkjHKeCA?si=zB_GnYjG9QQjbUI_"
        title="YouTube video player"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      {/* <p className="description">{translation.inviteShareLinkDescription[lang]}</p> */}
      <div className="image-wrapper">
        <img src={InviteFriendImage} />
      </div>
    </div>
  );
};

export default InviteAdvert;
