import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { List } from '../../components';

import { request, error } from '../../tools';
import ModalWindow from '../ModalWindow';
import ContractTaskItem from './ContractTaskItem/ContractTaskItem';
import { editContracts, addNewMessageToContractChat } from '../../store/commonReducer';
import { translation } from 'common_constants/translation';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';

const ContractTasks = ({ open, setOpen, contractId }) => {
  const dispatch = useDispatch();

  const contracts = useSelector((state) => state.common.contracts);

  const lang = useSelector((state) => state.common.language);

  const ITEMS_PER_PAGE = 10;

  const [currentTasks, setCurrentTasks] = useState([]);

  const [deadline, setDeadline] = useState(null);
  const [filter, setFilter] = useState(null);
  const [openTaskBlock, setOpenTaskBlock] = useState(false);
  const [title, setTitle] = useState('');

  const onCancel = () => setOpen(false);

  const dateFilterRef = useRef(null);
  const dateDeadlineRef = useRef(null);
  const [isMobileFilter, setIsMobileFilter] = useState(false);
  const [isMobiledeadline, setIsMobileDeadline] = useState(false);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent;

    const isAndroid = /android/i.test(userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    const isIpadDesktop = navigator.maxTouchPoints > 1 && /iPad/.test(userAgent);

    return isAndroid || isIOS || isIpadDesktop;
  };

  useEffect(() => {
    setIsMobileFilter(isMobileDevice());
    setIsMobileDeadline(isMobileDevice());
  }, []);

  const openCalendar = (ref) => {
    if (ref.current) {
      ref.current.showPicker();
    }
  };

  const isSameDay = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d1.getUTCFullYear() === d2.getUTCFullYear() && d1.getUTCMonth() === d2.getUTCMonth() && d1.getUTCDate() === d2.getUTCDate();
  };

  useEffect(() => {
    const contract = contracts.find((item) => item._id === contractId);

    const sortedTasks = contract?.tasks ? contract?.tasks.map((task) => task).sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)) : [];

    if (filter) {
      const filteredTasks = sortedTasks.filter((obj) => isSameDay(obj.createdAt, filter));
      setCurrentTasks(filteredTasks);
    } else {
      setCurrentTasks(sortedTasks);
    }
  }, [contractId, filter]);

  const rejectTask = (index) => {
    onChangeInputValue({ value: true, index, field: 'rejected' });
  };

  const setDoneTask = (index) => {
    onChangeInputValue({ value: true, index, field: 'done' });
  };

  const onChangeInputValue = ({ value, index, field }) => {
    const updatedTasks = currentTasks.map((item, itemIndex) => (itemIndex === index ? { ...item, [field]: value } : item));
    setCurrentTasks(updatedTasks);
    saveData(updatedTasks);
  };

  const addCreatingTask = () => {
    const formattedDeadline = deadline ? new Date(deadline).toISOString() : null;

    const newTask = {
      deadline: formattedDeadline,
      createdAt: new Date().toISOString(),
      title,
    };

    const updatedTasks = [newTask, ...currentTasks];

    setCurrentTasks(updatedTasks);
    setDeadline(null);
    setTitle('');
    saveData(updatedTasks);
    setOpenTaskBlock(false);
  };

  const saveData = (tasks) => {
    const requestData = {
      tasks: tasks.length > 0 ? tasks : currentTasks,
      _id: contractId,
    };

    request(
      '/contracts/addTasks',
      requestData,
      ({ status, newChatItem }) => {
        if (status) {
          dispatch(editContracts(requestData));
          if (newChatItem) {
            dispatch(addNewMessageToContractChat({ chatId: contractId, data: newChatItem }));
          }
        }
      },
      error,
    );
  };

  return (
    <ModalWindow modal={open} title={translation.contractTasksTitle2[lang]} setModal={onCancel} footer={null} className="tasksModal">
      {open && (
        <div className="tasks">
          <div className="filter-wrapper">
            <p className="filter-text">{translation.contractTasksTextFilter[lang]}</p>
            <div className="date-container">
              <input
                ref={dateFilterRef}
                type="date"
                className={`date-input ${isMobileFilter ? 'mobile' : ''}`}
                value={filter || ''}
                onChange={(e) => setFilter(e.target.value)}
                placeholder={isMobileFilter ? 'дд.мм.рррр' : ''}
              />
              <div className="calendar-icon" onClick={() => openCalendar(dateFilterRef)}></div>
              {isMobileFilter && !filter && <div className="mobile-placeholder">дд.мм.рррр</div>}
              {filter && (
                <button className="close-button" onClick={() => setFilter(null)}>
                  <CloseOutlined />
                </button>
              )}
            </div>
          </div>
          {openTaskBlock ? (
            <>
              <div className="task-item">
                <div className="task-item-date create date-container">
                  <input
                    ref={dateDeadlineRef}
                    type="date"
                    className={`date-input ${isMobiledeadline ? 'mobile' : ''}`}
                    value={deadline || ''}
                    onChange={(e) => setDeadline(e.target.value)}
                    placeholder={isMobiledeadline ? 'дд.мм.рррр' : ''}
                  />
                  <div className="calendar-icon" onClick={() => openCalendar(dateDeadlineRef)}></div>
                  {isMobiledeadline && !deadline && <div className="mobile-placeholder">дд.мм.рррр</div>}
                  <button className="close-button" onClick={() => setOpenTaskBlock(false)}>
                    <CloseOutlined />
                  </button>
                </div>

                <div className="row-bottom">
                  <div className="form-group">
                    <input
                      value={title || ''}
                      className="form-input _title"
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder={translation.contractTasksInputPlaceholder[lang]}
                    />
                  </div>
                </div>

                <div className="add-new-task">
                  <button className={`${!title ? 'disabled' : 'save-task'}`} disabled={!title} onClick={addCreatingTask}>
                    <PlusOutlined /> <p>{translation.contractTasksTextSaveTask[lang]}</p>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="add-new-task">
              <button onClick={() => setOpenTaskBlock(true)}>
                <PlusOutlined /> <p>{translation.contractTasksText3[lang]}</p>
              </button>
            </div>
          )}

          <List
            pagination={{
              pageSize: ITEMS_PER_PAGE,
              style: { justifyContent: 'center', marginTop: 15 },
            }}
            dataSource={currentTasks}
            renderItem={(item, index, page) => {
              return (
                <ContractTaskItem
                  item={item}
                  key={index}
                  index={index}
                  page={page}
                  ITEMS_PER_PAGE={ITEMS_PER_PAGE}
                  rejectTask={rejectTask}
                  setDoneTask={setDoneTask}
                  onChangeInputValue={onChangeInputValue}
                  lang={lang}
                />
              );
            }}
          ></List>
        </div>
      )}
    </ModalWindow>
  );
};

export default ContractTasks;
