import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DAILY_PARTNER_INVITE_LIMIT, COUNTRY_PHONE_CODES } from 'common_constants/business';
import { translation } from 'common_constants/translation';

import { info, warn, request, error } from '../../tools';
import { RHForm, Modal } from '../../components';

import './styles.scss';

const Invite = ({ open, setInviteModal, invitedClients, setInvitedClients, setRemainingInvitationsToday }) => {
  const lang = useSelector((state) => state.common.language);

  const [countryPhCode, setCountryPhCode] = useState(COUNTRY_PHONE_CODES.UA);

  const onFinish = ({ countryPhCode, login }) => {
    request(
      '/partners/inviteClient',
      { phone: login, countryPhCode },
      ({ message, exceededDailyLimit, invitedClient, todayInvites }) => {
        if (!exceededDailyLimit) {
          const updatedInvitedClients = [...invitedClients, invitedClient];
          setInvitedClients(updatedInvitedClients);
          setRemainingInvitationsToday(DAILY_PARTNER_INVITE_LIMIT - todayInvites);
          setInviteModal(false);
          return;
        }

        warn(message, ' ');
      },
      error,
    );
  };

  return (
    <Modal className="invite-modal" title={translation.inviteTitle[lang]} open={open} onCancel={() => setInviteModal(false)} footer={null}>
      <RHForm onFinish={onFinish} className="invite-form" countryPhCode={countryPhCode} setCountryPhCode={setCountryPhCode}>
        {[
          {
            name: 'phone',
            type: 'phone',
            rHFormOptions: {
              className: 'invite-form-item',
              required: translation.phoneRule1[lang],
              pattern: { value: /^[0-9]{10}$/, message: translation.phoneRule1[lang] },
            },
            options: { label: translation.clientBtn3[lang] },
          },
          {
            name: 'submit',
            options: { className: 'invite-submit-btn', value: translation.inviteBtn[lang] },
          },
        ]}
      </RHForm>
    </Modal>
  );
};

export default Invite;
