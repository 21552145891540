import dayjs from 'dayjs';
import 'dayjs/locale/uk';
dayjs.locale('uk');

export const getFormattedDateWithRelativeDays = (itemTime, lang) => {
  const DAY_LIST = { TODAY: 'Today', YESTERDAY: 'Yesterday', TOMORROW: 'Tomorrow' };

  const isDayjsInstance = (value) => {
    return value && typeof value === 'object' && '$d' in value;
  };

  const isDateInstance = (value) => {
    return value instanceof Date;
  };

  const isString = (value) => {
    return typeof value === 'string' ? value.trim() !== '' : false;
  };

  const isDayjsValid = (value) => {
    return dayjs(value).isValid();
  };

  const isDateValid = (value) => {
    return !isNaN(new Date(value));
  };

  if (!isDayjsInstance(itemTime) && !isDateInstance(itemTime)) {
    if (isString(itemTime)) {
      if (!isDayjsValid(itemTime) || !isDateValid(itemTime)) return '-';
    } else return '-';
  }

  const itemDate = dayjs(itemTime),
    currentDate = dayjs();

  const [isToday, isYesterday, isTomorrow] = [
    itemDate.isSame(currentDate, 'day'),
    itemDate.isSame(currentDate.subtract(1, 'day'), 'day'),
    itemDate.isSame(currentDate.add(1, 'day'), 'day'),
  ];

  const setDayName = (name) => {
    if (name === DAY_LIST.TODAY) {
      switch (lang) {
        case 'ru': {
          return 'Сегодня';
        }
        case 'en': {
          return 'Today';
        }
        case 'de': {
          return 'Heute';
        }
        case 'fr': {
          return "Aujourd'hui";
        }
        case 'es': {
          return 'Hoy';
        }
        case 'cs': {
          return 'Dnes';
        }
        default: {
          return 'Сьогодні';
        }
      }
    }

    if (name === DAY_LIST.YESTERDAY) {
      switch (lang) {
        case 'ru': {
          return 'Вчера';
        }
        case 'en': {
          return 'Yesterday';
        }
        case 'de': {
          return 'Gestern';
        }
        case 'fr': {
          return 'Hier';
        }
        case 'es': {
          return 'Ayer';
        }
        case 'cs': {
          return 'Včera';
        }
        default: {
          return 'Вчора';
        }
      }
    }

    if (name === DAY_LIST.TOMORROW) {
      switch (lang) {
        case 'ru': {
          return 'Завтра';
        }
        case 'en': {
          return 'Tomorrow';
        }
        case 'de': {
          return 'Morgen';
        }
        case 'fr': {
          return 'Demain';
        }
        case 'es': {
          return 'Mañana';
        }
        case 'cs': {
          return 'Zítra';
        }
        default: {
          return 'Завтра';
        }
      }
    }
  };

  return (
    {
      [isToday]: setDayName(DAY_LIST.TODAY),
      [isYesterday]: setDayName(DAY_LIST.YESTERDAY),
      [isTomorrow]: setDayName(DAY_LIST.TOMORROW),
    }[true] || itemDate.format('DD.MM.YYYY')
  );
};

export const getCurrentMounth = (startD, endD) => {
  const startDate = dayjs(startD);
  const endDate = dayjs(endD);
  const currentDate = dayjs();
  let correntMonth;
  const datesOfMonthEnd = [];
  let currentMonthStart = startDate.clone();
  let currentMonthEnd = startDate.clone().endOf('month').date(startDate.date());

  while (currentMonthStart.isBefore(endDate)) {
    datesOfMonthEnd.push(currentMonthEnd);
    currentMonthStart = currentMonthStart.add(1, 'month');
    currentMonthEnd = currentMonthStart.endOf('month').date(startDate.date());
  }

  datesOfMonthEnd.forEach((month, i) => {
    if (currentDate.startOf('day').isAfter(month.startOf('day')) || currentDate.startOf('day').isSame(month.startOf('day'))) {
      correntMonth = i + 1;
    }
  });

  return correntMonth;
};
