import React, { useState } from 'react';
import { translation } from 'common_constants/translation';
import { PRAVO_TYPES, PRAVO_FOR_CLIENT, PASSPORT_TYPE, PRAVO_TYPES_FOR_EADVOKAT } from 'common_constants/business';
import { DownOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { PaymentManagement, EditableContractName } from '../../../components';

import './Details.scss';

const Details = ({ contract, curator }) => {
  const lang = useSelector((state) => state.common.language);
  const FILII = useSelector((state) => state.common.FILII);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const hotlines = useSelector((state) => state.common.hotlines);
  const crmUsers = useSelector((state) => state.common.usersData);
  const [isFullOpen, setIsFullOpen] = useState(false);

  const contractHotline = hotlines?.find((hotline) => hotline?._id === contract?.H);
  const hotlineAmmUser = crmUsers?.find((user) => user?._id === contractHotline?.amm?.[0]?._id) ?? {};

  const onExpandDetailsHandle = () => {
    setIsFullOpen(true);
  };

  return (
    <div className={clsx('contract-details', isFullOpen ? '' : 'hide')}>
      <EditableContractName contract={contract} className="contract-details-name" />
      <div className="info-wrapper">
        <div className="info-column">
          <div className="">
            <p className="info-list-title">{translation.clientCHotlineInf[lang]}</p>
            <ul>
              <li>
                {translation.clientCHotlineDate[lang]} {contractHotline?.date ? dayjs(contractHotline.date).utc(true).format('DD.MM.YYYY') : ''}
              </li>
              <li>
                {translation.clientCHotlineConducted[lang]} {hotlineAmmUser?.name}
              </li>
            </ul>
          </div>
          <div className="">
            <p className="info-list-title">{translation.clientCInf[lang]}</p>
            <ul>
              <li>
                {translation.clientCNum[lang]} {contract.i}
              </li>
              <li>
                {translation.clientCDate[lang]} {dayjs(contract.sd).format('DD.MM.YYYY')}
              </li>
              <li>
                {translation.clientCFil[lang]} {FILII?.[contract.f]}
              </li>
              <li>
                {translation.CuratorTitle[lang]} {curator?.name}
              </li>
              <li>
                {translation.clientCPravo[lang]} {PRAVO_TYPES_FOR_EADVOKAT[lang][contract?.pr]}
              </li>
              <li>
                {translation.clientCPravoExactly[lang]} {PRAVO_FOR_CLIENT[contract?.prc][lang]}
              </li>
            </ul>
          </div>
        </div>
        <div className="info-column">
          <div className="">
            <p className="info-list-title">{translation.clientInfo[lang]}</p>
            <ul>
              <li>
                {translation.clientProfileFullName[lang]}: {clientInfo?.n}
              </li>
              <li>
                {translation.clientCPPhonePlaceholder[lang]}: {clientInfo?.ph}
              </li>
              <li>
                {translation.birthTitle[lang]}: {clientInfo?.b ? dayjs(clientInfo.b)?.utc(true)?.format('DD.MM.YYYY') ?? '' : ''}
              </li>
              <li>
                {translation.clientContactUsInfo1[lang]} {clientInfo?.address}
              </li>
            </ul>
          </div>
          <div className="">
            <p className="info-list-title">{translation.clientPassportInf[lang]}:</p>
            <ul>
              <li>
                {translation.type[lang]}: {PASSPORT_TYPE?.[clientInfo?.pt] ?? ''}
              </li>
              <li>
                {translation.number[lang]}: {clientInfo?.pn}
              </li>
              <li>
                {translation.clientPassportIssued[lang]} {clientInfo?.pkv}
              </li>
              <li>
                {translation.clientPassportIssueDate[lang]} {clientInfo?.pdv ? dayjs(clientInfo.pdv)?.utc(true)?.format('DD.MM.YYYY') ?? '' : ''}
              </li>
              <li>
                {translation.clientPassportValidDate[lang]} {clientInfo?.pdd ? dayjs(clientInfo.pdd)?.utc(true)?.format('DD.MM.YYYY') ?? '' : ''}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <PaymentManagement contract={contract} />
      <button onClick={onExpandDetailsHandle} className={clsx('btn-expand', isFullOpen ? 'hidden' : '')}>
        <DownOutlined />
      </button>
    </div>
  );
};

export default Details;
