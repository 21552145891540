import React, { useState, useEffect } from 'react';
import Spin from '../../components/Spin/Spin';
import Image from '../../components/Image';

const AchievementItem = ({ item, lawyerId }) => {
  const token = window.localStorage.getItem('clientAccessToken');
  const [imageLoading, setImageLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const timerId = setTimeout(() => {
      const fileId = item.fileId;

      fetch(process.env.REACT_APP_API + `/profile/achievementGetForClient/${lawyerId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ fileId }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          setImageUrl(url);
          setImageLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching image from server:', error);
        });
    }, 2000);
  }, []);

  return (
    <>
      <div className="achievement-item">
        {imageLoading ? <Spin spinning /> : <Image width="100%" height="auto" className="achievement-item-image" src={imageUrl} alt="test_image" />}
      </div>
    </>
  );
};

export default AchievementItem;
