import React, { useRef } from 'react';
import './CarouselTab.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useResize } from 'common_components';

const CarouselTab = ({ arrHTML, arrLength = 0 }) => {
  const resize = useResize();
  const carouselRef = useRef(null);

  const [scrollRight, scrollLeft] = [
    () => carouselRef.current.scrollBy({ left: 100, behavior: 'smooth' }),
    () => carouselRef.current.scrollBy({ left: -100, behavior: 'smooth' }),
  ];

  const dynamicAmountOfElementsForScreen = resize[0] >= 500 ? 4 : 3;
  const viewButton = arrLength >= dynamicAmountOfElementsForScreen;

  return (
    <div className="carousel-container">
      <div className="carousel" ref={carouselRef}>
        {arrHTML}
      </div>
      {viewButton && (
        <button className="carousel-btn left" onClick={scrollLeft}>
          <LeftOutlined style={{ fontSize: '150%' }} />
        </button>
      )}
      {viewButton && (
        <button className="carousel-btn right" onClick={scrollRight}>
          <RightOutlined style={{ fontSize: '150%' }} />
        </button>
      )}
    </div>
  );
};

export default CarouselTab;
