import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import { CURRENCIES } from 'common_constants/business';
import clsx from 'clsx';

import BalanceNewButton from './BalanceNewButton';

import './BalanceNew.scss';
import { useState } from 'react';
import BonusesInfoModal from '../../Modals/BonusesInfo';

const BalanceNew = ({ bonusesBalance = '0.00', balance = '0.00', loading, menuItems = [], balanceName, inviteFriendPage, modal }) => {
  const lang = useSelector((state) => state.common.language);
  const client = useSelector((state) => state.common.clientInfo);

  const [bonusesInfoModalOpened, setBonusesInfoModalOpened] = useState(false);

  return (
    <div className={clsx('container_balance-new', inviteFriendPage ? '_center' : '')}>
      <div className="balance-wrapper">
        <div className="balance-text">{inviteFriendPage ? translation.partnershipBalance[lang] : translation.account[lang]}</div>
        <div className="balance-bonuses">
          {balance < 0
            ? `-${CURRENCIES[client.currency]?.symbol ?? '₴'}${Math.abs(balance)?.toLocaleString('uk-UA')}`
            : `${CURRENCIES[client.currency]?.symbol ?? '₴'}${balance?.toLocaleString('uk-UA')}`}
        </div>
        {balanceName && <div className="balance-invite-text">{balanceName}</div>}
        <div className="balance-text">
          <div className="bonus-info">
            <a style={{ color: '#389E0D' }} onClick={() => setBonusesInfoModalOpened((prev) => !prev)}>
              {translation.bonusHowToUseTitle[lang]}
            </a>
          </div>
        </div>
      </div>
      {!inviteFriendPage ? (
        <div className="invite-content">
          <BalanceNewButton onClick={modal} buttonText={translation.clientBalancePayModalButtonText[lang]} />
        </div>
      ) : null}
      <BonusesInfoModal
        open={bonusesInfoModalOpened}
        setOpen={setBonusesInfoModalOpened}
        title={`${translation.bonusMainTitle[lang]}`}
      ></BonusesInfoModal>
    </div>
  );
};

export default BalanceNew;
