import React from 'react';
import { useSelector } from 'react-redux';
import { FileOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { Row, Column, List, UserAvatar } from '../';
import Typography from '../Typography';
import { formatBytes } from '../../tools';
import ChatImage from './ChatImage';

const { Text } = Typography;

const Message = ({ item, onDownload, sameSender, avatarUrl }) => {
  const crmUsers = useSelector((state) => state.common.usersData);
  const clientInfo = useSelector((state) => state.common.clientInfo);

  const renderChatImage = () => <ChatImage item={item} />;

  const renderChatFile = () => (
    <Row className={clsx('file')}>
      <FileOutlined style={{ marginRight: 8 }} onClick={onDownload} />
      <Column>
        <Text>{item.fileName}</Text>
        <Text>{formatBytes(item.fileSize)}</Text>
      </Column>
    </Row>
  );

  const componentToShow = item.fileName && /\.(png|jpg|jpeg)$/.test(item.fileName) ? renderChatImage() : renderChatFile();

  return (
    <List className={clsx('message-wrapper', item.isSentByCurrentUser ? 'message-wrapper-sent' : 'message-wrapper-received')}>
      <div className={clsx('message', item.isSentByCurrentUser ? 'sent' : 'received', sameSender ? 'avatar-hidden' : '')}>
        {!item.isSentByCurrentUser ? <Text className="author">{item.name}</Text> : null}
        <div className="message-content-wrapper">
          {item.message ? <Text className="message-text">{item.message}</Text> : item?.fileId ? componentToShow : null}
          <Text className="message-time">{dayjs(item.date).format('HH:mm')}</Text>
        </div>
      </div>
      {!sameSender ? (
        <UserAvatar
          className="author-avatar"
          size={30}
          customUrl={avatarUrl}
          user={item.isSentByCurrentUser ? clientInfo : crmUsers?.find((user) => user._id === item.sender)}
        />
      ) : null}
    </List>
  );
};

export default Message;
