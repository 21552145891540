import React from 'react';

import Col from '../Col';
import ClientMainPageHotlines from './ClientMainPageHotlines';

import './ClientMainPage.scss';

const HotlinesList = ({ clientHotlines, hotlinesLoaded, certHotline, historyHotlines, paymentByClubCard, isPartner }) => {
  return (
    <section className="clientMain_page">
      <Col className="clientMain-body">
        <ClientMainPageHotlines
          clientHotlines={clientHotlines}
          hotlinesLoaded={hotlinesLoaded}
          certHotline={certHotline}
          clientHistoryHotlines={historyHotlines ?? []}
          paymentByClubCard={paymentByClubCard}
          isPartner={isPartner}
        />
      </Col>
    </section>
  );
};

export default HotlinesList;
