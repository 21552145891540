import React from 'react';
import { Switch } from 'common_components';
import { TextArea, InputNew, PhoneCodeSwitcher, SelectNew } from '..';
import { useForm, Controller } from 'react-hook-form';
import { COUNTRY_PHONE_CODES } from 'common_constants/business';

import './RHForm.scss';

const RHForm = ({
  onFinish,
  onChange,
  onPhoneNumberChange,
  phoneNumber,
  onKeyPress,
  children,
  className,
  defaultValues,
  countryPhCode,
  setCountryPhCode,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onBlur',
  });

  const onSubmit = (data) => {
    onFinish(data);
  };

  if (onChange) onChange(watch());
  if (onPhoneNumberChange) onPhoneNumberChange(watch());
  if (onKeyPress) onKeyPress(watch());

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className ? className : 'rHForm-base'}>
      {children.map(({ name, rHFormOptions, options, prefix, component, type, loginValue }) => {
        if (name === 'submit') return <InputNew key={name} id={name} options={options} />;
        if (type === 'checkbox') {
          return (
            <Controller
              key={name}
              name={name}
              control={control}
              defaultValue={false}
              rules={rHFormOptions}
              render={({ field: { onChange, value } }) => (
                <div key={name} className="inputContainer ">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Switch value={value} onToggle={() => onChange(!value)} activeColor="#334768" />
                    &nbsp; &nbsp; &nbsp;{component}
                  </div>
                  <p className="errorMessage">{errors[name]?.message}</p>
                </div>
              )}
            />
          );
        }
        if (type === 'textarea') {
          return (
            <Controller
              key={name}
              name={name}
              control={control}
              rules={rHFormOptions}
              render={({ field: { onChange, value } }) => (
                <div key={name} className="inputContainer">
                  {options?.label ? (
                    <label htmlFor={name} className={rHFormOptions?.required ? 'required label' : 'label'}>
                      {options?.label}
                    </label>
                  ) : null}
                  <TextArea
                    id={name}
                    register={register}
                    rHFormOptions={rHFormOptions}
                    options={options}
                    value={value}
                    onChange={onChange}
                    isError={errors?.[name]}
                  />
                  {errors?.[name] ? <p className="errorMessage">{errors[name].message}</p> : null}
                </div>
              )}
            />
          );
        }

        if (type === 'phone') {
          return (
            <div key={name} className="inputContainer">
              {options?.label ? (
                <label htmlFor={name} className={rHFormOptions?.required ? 'required label' : 'label'}>
                  {options?.label}
                </label>
              ) : null}
              <div className="phone-inner">
                <Controller
                  name="countryPhCode"
                  control={control}
                  defaultValue={COUNTRY_PHONE_CODES.UA}
                  rules={{ required: 'Country code is required' }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneCodeSwitcher countryPhCode={countryPhCode} setCountryPhCode={setCountryPhCode} onChange={onChange} value={value} />
                  )}
                />
                <Controller
                  name="login"
                  control={control}
                  defaultValue=""
                  rules={rHFormOptions}
                  render={({ field: { onChange, value } }) => (
                    <InputNew
                      type={options?.type}
                      id="login"
                      register={register}
                      rHFormOptions={rHFormOptions}
                      options={options}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              {errors?.countryPhCode ? <p className="errorMessage">{errors.countryPhCode.message}</p> : null}
              {errors?.login ? <p className="errorMessage">{errors.login.message}</p> : null}
            </div>
          );
        }

        if (type === 'select') {
          return (
            <div key={name} className="inputContainer">
              {options?.label ? (
                <label htmlFor={name} className={rHFormOptions?.required ? 'required label' : 'label'}>
                  {options?.label}
                </label>
              ) : null}
              <SelectNew
                placeholder={options?.placeholder}
                name={name}
                control={control}
                rHFormOptions={rHFormOptions}
                options={options?.selectoptions}
              ></SelectNew>
              <p className="errorMessage">{errors[name]?.message}</p>
            </div>
          );
        }

        return (
          <div key={name} className="inputContainer">
            <label htmlFor={name} className={rHFormOptions?.required ? 'required label' : 'label'}>
              {options?.label}{' '}
            </label>
            <InputNew id={name} inputName={name} register={register} rHFormOptions={rHFormOptions} options={options} prefix={prefix} />
            <p className="errorMessage">{errors[name]?.message}</p>
          </div>
        );
      })}
    </form>
  );
};

export default RHForm;
