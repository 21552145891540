import { useState } from 'react';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { translation } from 'common_constants/translation';
import { Button } from '../../../components';

export const TaskActions = ({ item, rejectTask, setDoneTask, lang }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const openModal = (value) => {
    setIsConfirm(value);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  const handleConfirm = () => {
    setIsModalOpen(false);
    (isConfirm ? setDoneTask : rejectTask)();
  };

  if (!item.rejected && !item.done) {
    return (
      <div className="buttons-wrapper">
        <div className="done-task-btn" onClick={() => openModal(true)}>
          <CheckOutlined />
        </div>

        <div className="cancel-task-btn" onClick={() => openModal(false)}>
          <CloseOutlined />
        </div>
        {isModalOpen && (
          <div className="local-modal-overlay" onClick={closeModal}>
            <div className="local-modal">
              <div className="title-container">
                <p style={{ fontWeight: 'bold' }}>{isConfirm ? translation.taskDoneConfirmText[lang] : translation.taskRejectConfirmText[lang]}</p>
              </div>
              <div className="buttons">
                <Button style={{ padding: '0px 5px', margin: '0 4px' }} variant="light" onClick={closeModal}>
                  {translation.no[lang]}
                </Button>
                <Button style={{ padding: '0px 5px', margin: '0 4px' }} onClick={handleConfirm}>
                  {translation.yes[lang]}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
};
