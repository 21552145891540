import { CLIENT_ROUTES } from 'common_constants/routes';
import { Route, Switch, Redirect } from 'react-router-dom';

import AnonimBonusHome from './pages/AnonimBonusHome';
import AnonimCertificateHome from './pages/AnonimCertificateHome';
import ClientHome from './pages/ClientHome';
import InviteFriend from './pages/InviteFriend';
import PaymentForHotline from './components/PaymentForHotline';
import ClientChangePass from './pages/ClientChangePass';
import ClientConfirmNewPassword from './pages/ClientConfirmNewPassword';
import ClientContractInfo from './pages/ClientContractInfo';
import { PaymentSuccess } from './pages/ClientContracts/ClientContractsPartials';
import ClientContracts2 from './pages/ClientContracts2';
import ClientAutoPayment from './pages/ClientAutoPayment';
import ClientHotline from './pages/ClientHotline';
import Insurances from './pages/Insurances';
import ClientLogin from './pages/ClientLogin';
import ClientProfile from './pages/ClientProfile';
import ClientReg from './pages/ClientReg';
import Homepage from './pages/Homepage';
import HotlineMeetInfo from './pages/HotlineMeetInfo';
import ClientTenders from './pages/ClientTenders';
import CreateTender from './pages/ClientTenders/CreateTender';
import ClientTenderInfo from './pages/ClientTenders/ClientTenderInfo';
import LawyerCV from './pages/LawyerCV';
import QuickChat from './pages/QuickChat';
import ClientContactUs from './pages/ClientContactUs';
import ClientFeedbacks from './pages/ClientFeedbacks';
import FiliiState from './pages/FiliiState';
import RedButtonPage from './pages/RedButtonPage';
import ClubCardPage from './pages/ClubCardPage';
import Chats from './pages/Chats';
import Webinar from './pages/Webinar';
import Register from './pages/Register';
import RegisterList from './pages/RegisterList';
import RegisterSearch from './pages/RegisterSearch';
import RegisterSelfSearch from './pages/RegisterSelfSearch';
import ConfirmChangePhone from './components/ConfirmChangePhone';
import PolicyConfirm from './Modals/PolicyConfirm';
import { useDispatch } from 'react-redux';
import { setModal } from './store/commonReducer';
import PartnerHotlines from './pages/PartnerHotlines';

export default function RouterSwitch() {
  return (
    <Switch>
      <Route exact path={CLIENT_ROUTES.CLIENT_LOGIN.route}>
        <ClientLogin />
      </Route>
      <Route exact path={CLIENT_ROUTES.CLIENT_REGISTRATION.route}>
        <ClientReg />
      </Route>
      <Route exact path={CLIENT_ROUTES.CLIENT_CHANGE_PASSWORD.route}>
        <ClientChangePass />
      </Route>
      <Route exact path={CLIENT_ROUTES.CLIENT_CONFIRM_NEW_PASSWORD.route}>
        <ClientConfirmNewPassword />
      </Route>
      <Route exact path={CLIENT_ROUTES.CLUB_CARD.route}>
        <ClientHome />
      </Route>
      <Route exact path={CLIENT_ROUTES.CLIENT_HOTLINE.route}>
        <ClientHotline />
      </Route>
      <Route exact path={CLIENT_ROUTES.INVITE_FRIEND.route}>
        <InviteFriend />
      </Route>
      <Route exact path={CLIENT_ROUTES.ANONIM_BONUS_HOME.route}>
        <AnonimBonusHome />
      </Route>
      <Route exact index path={CLIENT_ROUTES.CLIENT_HOME.route}>
        <Homepage />
      </Route>
      <Route exact path={CLIENT_ROUTES.CLIENT_VERIFICATION_PHONE.route}>
        <ConfirmChangePhone />
      </Route>
      <Route exact path={CLIENT_ROUTES.CLIENT_POLICY_CONFIRM.route}>
        <PolicyConfirm />
      </Route>
      <Route exact path={CLIENT_ROUTES.PARTNER_HOTLINE.route}>
        <PartnerHotlines />
      </Route>

      <Route exact path={CLIENT_ROUTES.CLIENT_INSURANCE.route}>
        <Insurances />
      </Route>
      <Route path={CLIENT_ROUTES.ANONIM_CERTIFICATE_OLD_.route}>
        <AnonimCertificateHome />
      </Route>
      <Route path={CLIENT_ROUTES.ANONIM_CERTIFICATE_.route}>
        <AnonimCertificateHome />
      </Route>
      <Route path={CLIENT_ROUTES.CLIENT_CONTRACTS.route}>
        <ClientContracts2 />
      </Route>
      <Route path={CLIENT_ROUTES.AUTOPAY_PAGE.route}>
        <ClientAutoPayment />
      </Route>
      <Route path={CLIENT_ROUTES.CLIENT_CONTRACT_INFO.route}>
        <ClientContractInfo />
      </Route>
      <Route path={CLIENT_ROUTES.CLIENT_PROFILE.route}>
        <ClientProfile />
      </Route>
      <Route path={CLIENT_ROUTES.PAYMENT_SUCCESS.route}>
        <PaymentSuccess />
      </Route>
      <Route path={CLIENT_ROUTES.PAYMENT_FOR_HOTLINE.route}>
        <PaymentForHotline />
      </Route>
      <Route path={CLIENT_ROUTES.CLIENT_TENDERS.route}>
        <ClientTenders />
      </Route>
      <Route path={CLIENT_ROUTES.CREATE_TENDER.route}>
        <CreateTender />
      </Route>
      <Route path={CLIENT_ROUTES.CLIENT_TENDER_INFO.route}>
        <ClientTenderInfo />
      </Route>
      <Route path={CLIENT_ROUTES.CLIENT_QUICK_CHAT.route}>
        <QuickChat />
      </Route>
      <Route path={CLIENT_ROUTES.CLIENT_CONTACT_US.route}>
        <ClientContactUs />
      </Route>
      <Route path={CLIENT_ROUTES.CLIENTS_FEEDBACK.route}>
        <ClientFeedbacks />
      </Route>
      <Route path={CLIENT_ROUTES.FILII_STATE.route}>
        <FiliiState />
      </Route>
      <Route path={CLIENT_ROUTES.RED_BUTTON.route}>
        <RedButtonPage />
      </Route>
      <Route path={CLIENT_ROUTES.CLUB_CARD_PAGE.route}>
        <ClubCardPage />
      </Route>
      <Route path={CLIENT_ROUTES.CLIENT_CHATS.route}>
        {!window?.ReactNativeWebView ? <Chats /> : <Redirect to={CLIENT_ROUTES.CLIENT_HOME.route} />}
      </Route>
      <Route exact path={CLIENT_ROUTES.WEBINAR_ID.route}>
        <Webinar />
      </Route>
      <Route exact path={CLIENT_ROUTES.REGISTER.route}>
        <RegisterSelfSearch />
      </Route>
      {/* <Route exact path={CLIENT_ROUTES.REGISTER_LIST.route}>
        <RegisterList />
      </Route>
      <Route exact path={CLIENT_ROUTES.REGISTER_SEARCH.route}>
        <RegisterSearch />
      </Route>
      <Route exact path={CLIENT_ROUTES.REGISTER_SELF_SEARCH.route}>
        <RegisterSelfSearch />
      </Route> */}
      {/* should be last */}
      <Route path={CLIENT_ROUTES.INVITE_LINK.route}>
        <ClientHotline />
      </Route>

      <Route exact path={CLIENT_ROUTES.LAWYER_CV.route}>
        <LawyerCV />
      </Route>
      <Route exact path={CLIENT_ROUTES.HOTLINE_MEET_INFO.route}>
        <HotlineMeetInfo />
      </Route>

      <Route exact path={CLIENT_ROUTES.PUSH_MODALS.INTERIM_SERVICE_QUALITY_ASSESSMENT.route}>
        <ShowPushModal modal={CLIENT_ROUTES.PUSH_MODALS.INTERIM_SERVICE_QUALITY_ASSESSMENT.modal} />
      </Route>
    </Switch>
  );
}

function ShowPushModal({ modal, redirectTo = CLIENT_ROUTES.CLIENT_HOME.route }) {
  const dispatch = useDispatch();
  dispatch(setModal({ name: modal }));

  return <Redirect to={redirectTo} />;
}
