import React, { useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import 'dayjs/locale/uk';
import 'dayjs/locale/ru';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/cs';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { getFormattedDateWithRelativeDays } from 'common_components';
import { translation } from 'common_constants/translation';
import { NAME_ZONE_FILES, TYPES_OF_MAIL } from 'common_constants/business';
import { Spin, AvatarFile, PreviewImage, PreviewDoc } from '../../../../components';
import { requestFile, warn, error, success } from '../../../../tools';

import CuratorButton from '../../CuratorButton/CuratorButton';
import CarouselTab from '../../CarouselTab/CarouselTab';

import { icon_download } from '../../images';

dayjs.extend(isSameOrAfter);

const MultyItemCorAndTab = ({ item, clientInfo, isModalCor }) => {
  const lang = useSelector((state) => state.common.language);
  const params = useParams();

  dayjs.locale(`${lang === 'ua' ? 'uk' : lang}`);

  const [loading, setLoading] = useState(false);

  const timingFunc = (date, times) => {
    const isTimeString = typeof times === 'string' && /^\d{1,2}:\d{2}$/.test(times);

    const [validNewDate, validDayjs, validStringDate] = [
      date instanceof Date,
      dayjs(date).isValid(),
      typeof date === 'string' && date.trim() !== '' && !isNaN(new Date(date)),
    ];

    if (isTimeString) {
      const [hours, minutes] = times.split(':').map(Number);
      if (!isNaN(hours) && !isNaN(minutes) && hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
        date = dayjs(date).set('hour', hours).set('minute', minutes);
      }
    }

    const textTimeFixed = <div className="date-time">{`${getFormattedDateWithRelativeDays(date)} - ${dayjs(date).format('HH:mm')}`}</div>;

    return validNewDate || validDayjs || validStringDate ? textTimeFixed : null;
  };

  const typePost = (value, value2) => {
    const type = TYPES_OF_MAIL.find((item) => item.value === Number(value));

    const dynamic =
      {
        1: [type.on, TYPES_OF_MAIL[TYPES_OF_MAIL.length - 1].on],
        2: [type.from, TYPES_OF_MAIL[TYPES_OF_MAIL.length - 1].from],
      }[value2] || '';

    return type ? dynamic?.[0] ?? '' : dynamic?.[1] ?? '';
  };

  const boxAllFuncCorrecpondence = (item) => {
    const arrListFiles =
      item.df?.map((i) => {
        const formatFile = i.fd;
        const prevCombPic = (
          <div
            key={i?.L ?? dayjs().valueOf()}
            className="service-type"
            children={
              <PreviewImage
                key={i?.L ?? dayjs().valueOf()}
                item={i.L}
                formatFile={formatFile}
                nameZone={NAME_ZONE_FILES.CORRESPONDENCE_UPLOADED_FILE}
              />
            }
          />
        );

        const prevCombDoc = (
          <div
            className="service-type"
            children={
              <div
                key={i?.L ?? dayjs().valueOf()}
                className="service-type"
                children={
                  <PreviewDoc
                    key={i?.L ?? dayjs().valueOf()}
                    item={i.L}
                    formatFile={formatFile}
                    nameZone={NAME_ZONE_FILES.CORRESPONDENCE_UPLOADED_FILE}
                  />
                }
              />
            }
          />
        );

        const result = {
          png: prevCombPic,
          jpg: prevCombPic,
          jpeg: prevCombPic,
          img: prevCombPic,
          docx: prevCombDoc,
          doc: prevCombDoc,
        }[formatFile] || <AvatarFile key={i?.L ?? dayjs().valueOf()} item={formatFile} />;
        return result;
      }) ?? [];

    const minElementsToScreen = 2;
    const dynamicListFilesForCarouse =
      item.df?.length >= minElementsToScreen ? <CarouselTab arrHTML={arrListFiles} arrLength={item.df?.length} /> : arrListFiles;

    return (
      <>
        {item.o && item.p && (
          <div className="service-type">
            {({ 1: translation.clientCInfCorrecpondenceType1[lang], 2: translation.clientCInfCorrecpondenceType2[lang] }[item.o] || '') +
              typePost(item.p, item.o)}
          </div>
        )}
        {item.h && <div className="service-type">{item.h}</div>}
        {item.df?.length > 0 && (
          <div>
            <CuratorButton
              icon={icon_download}
              text={`${translation.clientCInfCorrecpondenceText1[lang]}${
                item.df?.length > 1 ? translation.clientCInfCorrecpondenceText2[lang] : ''
              } ${translation.clientCInfCorrecpondenceText3[lang]} (${item.df?.length} ${translation.clientCInfCorrecpondenceText4[lang]})`}
              modal={() => downloadFiles(item.df, item)}
            />
            <div className="service-type" />
            {dynamicListFilesForCarouse}
          </div>
        )}
      </>
    );
  };

  const boxAllFuncChat = (item) => (
    <>
      <div
        className="service-type"
        children={
          item.sender === clientInfo._id ? translation.clientCInfCorrecpondenceFileType1[lang] : translation.clientCInfCorrecpondenceFileType2[lang]
        }
      />
      {item.comment && <div className="service-type" children={item.comment} />}
      <div className="service-type" children={translation.chat[lang]} />
      {item.fileSize > 0 && (
        <div>
          <CuratorButton
            icon={icon_download}
            text={translation.clientCInfChatDownload[lang]}
            modal={() => onGetFile({ fileId: item.fileId, fileName: item.fileName })}
          />
        </div>
      )}
      <div className="service-type" />
      {(() => {
        const formatFile = item.fileName?.split('.').pop();
        const prevCombPic = (
          <div
            className="service-type"
            children={<PreviewImage item={item.fileId} formatFile={formatFile} nameZone={NAME_ZONE_FILES.CHAT_IN_CONTRACT} />}
          />
        );

        const prevCombDoc = (
          <div
            className="service-type"
            children={<PreviewDoc item={item.fileId} formatFile={formatFile} nameZone={NAME_ZONE_FILES.CHAT_IN_CONTRACT} />}
          />
        );

        const result = {
          png: prevCombPic,
          jpg: prevCombPic,
          jpeg: prevCombPic,
          img: prevCombPic,
          docx: prevCombDoc,
          doc: prevCombDoc,
        }[formatFile] || <AvatarFile item={formatFile} />;
        return result;
      })()}
    </>
  );

  const download_file_by_fetch = async (itemDocs, doc) => {
    const dynamicItem = itemDocs ?? doc;

    const validation = (_L, _n, _f) => {
      if (!_L) {
        warn('', translation.clientCInfDownloadFileWarn1[lang], { L: !_L });
        return;
      }

      if (!_n) {
        warn('', translation.clientCInfDownloadFileWarn2[lang], { n: !_n });
        return;
      }

      if (!_f) {
        warn('', translation.clientCInfDownloadFileWarn3[lang], { n: !_f });
        return;
      }

      return true;
    };

    const valid_item_Arr = [dynamicItem.L, dynamicItem.nd, dynamicItem.fd];

    if (!validation(...valid_item_Arr)) return;

    const transactionData = {
      clientId: clientInfo._id,
      contractId: params.id,
      fileId: dynamicItem.L,
      formatFile: dynamicItem.fd,
      fileName: item?.im ? 'Документ.' + dynamicItem.fd : dynamicItem.nd + '.' + dynamicItem.fd, //* for data processing in requestFile
      mfoId: item?.im,
    };

    setLoading(true);

    requestFile(
      '/correspondence/getCorrespondenceDriveFileForClientWithSecuredVAF',
      transactionData,
      () => {
        setLoading(false);
        success('', translation.clientCInfDownloadFileSuccess[lang]);
      },
      (err) => {
        setLoading(false);
        error('', translation.clientCInfDownloadFileError[lang], err);
      },
    );
  };

  const downloadFiles = (arrDocs, item) => {
    if (!arrDocs?.length) return;

    for (let i = 0; i < arrDocs?.length; i++) {
      download_file_by_fetch(arrDocs[i], item);
    }
  };

  const onGetFile = (fileGet = {}) => {
    if (!fileGet.fileId) return error(translation.clientCInfOnGetFileError1[lang]);

    const transactionData = {
      fileId: fileGet.fileId,
      fileName: fileGet.fileName, //* for data processing in requestFile
      documentId: params.id,
    };

    setLoading(true);

    requestFile(
      '/chatPrivate/chatGetFileClient',
      transactionData,

      () => {
        setLoading(false);
        success('', translation.clientCInfDownloadFileSuccess[lang]);
      },
      (err) => {
        setLoading(false);
        error('', translation.clientCInfDownloadFileError[lang], err);
      },
    );
  };

  const content =
    {
      correspondence: boxAllFuncCorrecpondence(item),
      chatClientToСurator: boxAllFuncChat(item),
    }[item.whereIsTheFileFrom] || null;

  const hasViewedNotifications = isModalCor && !(item.notif?.some((notifItem) => notifItem.watched) ?? false);

  return (
    <Spin spinning={loading} tip={translation.loader[lang]}>
      <div
        className={clsx({
          assignment: true,
          hasViewedNotifications: hasViewedNotifications,
        })}
      >
        {timingFunc(item.date ?? item.t)}
        {content}
      </div>
    </Spin>
  );
};

export default MultyItemCorAndTab;
