import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CLIENT_ROUTES } from 'common_constants/routes';

import Modal from '../Modal';
import EnhancedQRScanner from './EnhancedQRScanner';
import { info, warn } from '../../tools';

const QRCodeScanModal = ({ visible, onClose, onScan, scannedResult, label }) => {
  const history = useHistory();
  const { token } = useSelector((state) => state.common.clientAuth);
  const [hasInvited, setHasInvited] = useState(false);
  const [onCancelModal, setOnCancelModal] = useState(false);

  const setInviteClientForPartners = (values) => {
    const transaction = { qrcodeId: values };

    fetch(process.env.REACT_APP_API + '/partners/inviteClient', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
      body: JSON.stringify(transaction),
    })
      .then((res) => res.json())
      .then(({ status, message, exceededDailyLimit, existingInvitation }) => {
        if (status && !exceededDailyLimit) {
          info(message);
          history.push(CLIENT_ROUTES.INVITE_FRIEND.route);
          return;
        }
        if (status && exceededDailyLimit) {
          warn(message, ' ');
          return;
        }
        if (!status && existingInvitation) {
          warn(message, ' ');
          return;
        }
      });
  };

  const onCancel = () => {
    setOnCancelModal(true);
    setTimeout(onClose, 0);
  };

  useEffect(() => {
    if (visible) setOnCancelModal(false);
  }, [visible]);

  useEffect(() => {
    if (!scannedResult) return;

    if (!hasInvited || scannedResult !== hasInvited) {
      setInviteClientForPartners(scannedResult);
      setHasInvited(scannedResult);
    }
  }, [scannedResult, hasInvited]);

  return (
    <Modal open={visible} footer={null} title={label} onCancel={onCancel}>
      <EnhancedQRScanner onScan={onScan} onClose={onClose} onCloseModal={onCancelModal} />
    </Modal>
  );
};

export default QRCodeScanModal;
