import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NAME_ZONE_FILES } from 'common_constants/business';
import { translation } from 'common_constants/translation';
import { warn, request, success } from '../../tools';
import { Spin, AvatarFile } from '..';
import { fakePreviewDoc } from '../../images';
import clsx from 'clsx';
import './PreviewDoc.scss';

const PreviewDoc = ({ item, formatFile, nameZone }) => {
  const lang = useSelector((state) => state.common.language);

  const [red, green, blue] = ['#f5222d', '#52c41a', '#1677ff'];

  const core_megaState = {
    loading: true,
    url: '',
    status: blue,
  };

  const [megaState, setMegaState] = useState(core_megaState);

  const [isHovered, setIsHovered] = useState(false);

  const handleImageClick = () => {
    success('', 'Сайт успішно відкрито');

    window.open(megaState.url, '_blank');
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const folder =
    {
      [NAME_ZONE_FILES.CHAT_IN_CONTRACT]: 'GOOGLE_CONTRACTS_CHAT_FILES_FOLDER_ID',
      [NAME_ZONE_FILES.CORRESPONDENCE_UPLOADED_FILE]: 'GOOGLE_CORRESPONDENCE_FILES_FOLDER_ID',
    }[nameZone] || null;

  useEffect(() => {
    const timerId = setTimeout(() => {
      const transactionData = {
        fileId: item,
        folder: folder,
      };

      const validation = ({ fileId }) => {
        if (!fileId) {
          warn('', 'Виникла помилка з індентифікуванням вашого файлу. Зверніться, будь ласка, до адміністрації.', { _fileId: !!fileId });
          return;
        }

        return true;
      };

      if (!validation({ nameZone: nameZone, ...transactionData })) return;

      request(
        '/chatPrivate/chatGetLinkClient',
        transactionData,
        (req) => {
          setMegaState((prev) => ({
            ...prev,
            url: req.fileLink,
            status: green,
            loading: false,
          }));
        },
        (err) => {
          console.error('Error fetching image from server:', err);
          setMegaState((prev) => ({ ...prev, url: '', status: red, err: true }));
        },
      );
    }, 2000);

    return () => clearTimeout(timerId);
  }, []);

  const imgDrive = megaState.loading ? (
    <div className="loading-spinner">
      <Spin tip={translation.loader[lang]} spinning={megaState.loading} />
    </div>
  ) : (
    <img src={fakePreviewDoc} className="img-drive preview-doc" alt="file view" onClick={handleImageClick} />
  );

  return (
    <>
      {/* {megaState.loading && megaState.err ? ( */}
      <AvatarFile item={formatFile} />
      {/* ) : (
        <div className={clsx('preview-image')} onClick={handleImageClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {imgDrive}
          {isHovered && !megaState.loading && (
            <div className="overlay-content">
              <span>{translation.clientInComponentPreview[lang]}</span>
            </div>
          )}
        </div>
      )} */}
    </>
  );
};

export default PreviewDoc;
