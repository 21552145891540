import React from 'react';
import './AdvantagesClubCard.scss';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';

const cardTypes = ['silver', 'gold', 'platinum'];

const AdvantagesClubCard = ({ type }) => {
  const lang = useSelector((state) => state.common.language);
  const currentType = cardTypes[type];
  const advantages = translation.clubCardAdvantages[currentType];
  const managerInfo = useSelector((state) => state.common.managerInfo);

  const imageUrl = managerInfo?.upa
    ? process.env.REACT_APP_API + `/avatars/${managerInfo.upa}_200.jpeg`
    : 'https://th.bing.com/th/id/OIP.NmdbjgnCEbGC6o4A43nhJgHaHs?pid=ImgDet&w=195&h=202&c=7&dpr=1.5';
  const isGold = currentType === cardTypes[1];
  const styleGold = isGold
    ? {
        position: 'absolute',
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat',
        top: '50%',
        left: '17%',
        width: '100px',
        height: '100px',
        backgroundSize: 'contain',
        transform: 'translate(-50%, -50%)',
      }
    : {};

  return (
    <div className="block">
      <div className={`typeCardPurchased-wrapper advantages ${currentType.toLowerCase()}`}>
        {isGold && <div style={styleGold}></div>}
        <ul className="advantages-text">{advantages && Object.keys(advantages).map((key) => <li key={key}>✅{advantages[key][lang]}</li>)}</ul>
      </div>
    </div>
  );
};

export default AdvantagesClubCard;
