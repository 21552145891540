import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleOutlined } from '@ant-design/icons';

import { translation } from 'common_constants/translation';
import { CLIENT_ROUTES } from 'common_constants/routes';
import AddClientEmail from '../../Modals/AddClientEmail/AddClientEmail';
import Modal from '../../components/Modal';
import { Button, Row } from '../../components';
import { setMainLoader } from '../../store/uiReducer';
import SuccessMessage from './SuccessMessage';
import Step1Client from './Step1Client';
import HotlinesList from '../../components/HotlinesList';

import './ClientHotline.scss';
import { setHistoryHotlines, setHotlines } from '../../store/commonReducer';
import { fetchHotlines } from '../ClientContracts/ClientContractsHelpers';
import { error, request } from '../../tools';

const ClientHotline = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token: friendShareLinkToken } = useParams();

  const clientInfo = useSelector((state) => state.common.clientInfo);
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const activeFilii = useSelector((state) => state.common.activeFilii);
  const filii = useSelector((state) => state.common.filii);
  const topFilials = useSelector((state) => state.common.topFilials);
  const lang = useSelector((state) => state.common.language);

  const { emailVerified, phoneVerified } = clientAuth;

  const historyHotlines = useSelector((state) => state.common.historyHotlines);
  const hotlines = useSelector((state) => state.common.hotlines);
  const [currentHotlines, setCurrentHotlines] = useState(hotlines);

  const core_megaState = {
      clientHotlines: [],
      certHotline: [],
      clientHistoryHotlines: [],
      hotlinesLoaded: false,
    },
    [megaState, setMegaState] = useState(core_megaState);

  const [data, setData] = useState({
    type: '0',
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [isHotlineAdded, setIsHotlineAdded] = useState(false);
  const [clientHotlineInfo, setClientHotlineInfo] = useState();

  const success = () => setModalOpen(true);

  const setLoading = (loadingState) => {
    dispatch(setMainLoader(loadingState));
  };

  const modalClose = () => {
    setModalOpen(false);
    history.push(CLIENT_ROUTES.CLIENT_HOME.route);
  };

  useEffect(() => {
    if (friendShareLinkToken) {
      localStorage.setItem('friendShareLinkToken', friendShareLinkToken);
    }
  }, [friendShareLinkToken]);

  useEffect(() => {
    if (isHotlineAdded) {
      success();
    }
  }, [isHotlineAdded]);

  // todo: Корисно було б додати редакс сюди для запису контрактів та хотлайну, та відображення - якщо вони є. А запит зробити затримку десь на 5 хвилин через редакс. Тобто, якщо запит був, то не запитуй знову, якщо клієнт постійно перескакує між сторінками, щоб просто подивитися. Так ми зменшимо навантаження  на додаток через завантаження та обробку даних

  useEffect(() => {
    if (!clientAuth) return;

    const getData = async () => {
      dispatch(setMainLoader(true));

      const { hotlines, _clientHotlines, _certHotline, _historyHotlines } = await fetchHotlines();
      dispatch(setHotlines(hotlines));
      dispatch(setHistoryHotlines(_historyHotlines));
      setMegaState((prev) => ({
        ...prev,
        clientHotlines: _clientHotlines,
        certHotline: _certHotline,
        hotlinesLoaded: true,
      }));

      dispatch(setMainLoader(false));
    };

    getData();
  }, [currentHotlines]);

  const paymentByClubCard = (vip, consultationType, correntMonth, hotlineId) => {
    const requestData = { vip, consultationType, correntMonth, hotlineId };
    const onSuccess = ({ data }) => {
      const _hotlines = hotlines?.map((ho) => (ho?._id === data?.updatedHotline?.value?._id ? data.updatedHotline.value : ho));
      dispatch(setHotlines(_hotlines));
      setCurrentHotlines(_hotlines);
      success('Консультацію оплачено');
      return true;
    };
    const onError = () => {
      error('Не вдалось списати послугу з клубної карти');
      return false;
    };
    request('/hotline/paymentByClubCard', requestData, onSuccess, onError);
  };

  return (
    <>
      <div className="clientHotline_page">
        <Modal
          open={modalOpen}
          title={
            <>
              <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 24 }} /> Запис успішно створено!
            </>
          }
          closable={false}
        >
          <div className="clientHotline_page__modal_content">
            <SuccessMessage clientHotlineInfo={clientHotlineInfo} lang={lang} />
          </div>
          <Row justify={'end'}>
            <Button onClick={modalClose} style={{ width: 'auto', height: 'auto', padding: '2px 6px' }}>
              {translation.clientHotlineSubmitButton[lang]}
            </Button>
          </Row>
        </Modal>
        <div className="card">
          {emailVerified && phoneVerified ? (
            <Step1Client
              data={data}
              activeFilii={activeFilii}
              filii={filii}
              clientInfo={clientInfo}
              token={window.localStorage.getItem('clientAccessToken')}
              host={process.env.REACT_APP_API}
              setIsHotlineAdded={setIsHotlineAdded}
              setLoading={setLoading}
              setClientHotlineInfo={setClientHotlineInfo}
              setData={setData}
              lang={lang}
              topFilials={topFilials}
            />
          ) : (
            <>
              <h1 style={{ textAlign: 'center' }}>{translation.clientHotlineTitle[lang]}</h1>
              <AddClientEmail />
            </>
          )}
        </div>
      </div>
      <HotlinesList
        paymentByClubCard={paymentByClubCard}
        certHotline={megaState.certHotline}
        clientHotlines={megaState.clientHotlines}
        historyHotlines={historyHotlines}
        hotlinesLoaded={megaState.hotlinesLoaded}
      />
    </>
  );
};

export default ClientHotline;
