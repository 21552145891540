import React from 'react';
import { SERVICES_FOR_CLINETS_APP } from 'common_constants/business';
import { translation } from 'common_constants/translation';

import { Button, Popconfirm } from '../../../components';
import { request, error } from '../../../tools';

const ClientHotlineSubmitBtn = (props) => {
  const { showNext, fil, type, date, startT, endT, name, countryPhCode, phone, setLoading, setIsHotlineAdded, filii, lang } = props;

  const currentService = SERVICES_FOR_CLINETS_APP.find((service) => service.index == type);
  const showFil = filii.find((item) => item.i === +fil);

  const handleClickConfirm = () => {
    setLoading(true);

    const inviteToken = localStorage.getItem('friendShareLinkToken');

    request(
      '/hotline/addForClient',
      {
        fil,
        type,
        date,
        startT,
        endT,
        name,
        phone,
        countryPhCode,
      },
      () => {
        setLoading(false);

        props.setClientHotlineInfo({
          fil: showFil?.name ?? '-',
          type: currentService.label[lang],
          startT,
          endT,
          date,
        });
        setIsHotlineAdded(true);
      },
      error,
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '60px',
        pointerEvents: showNext ? 'all' : 'none',
        opacity: showNext ? 1 : 0.6,
      }}
    >
      <Popconfirm
        title={translation.title[lang]}
        description={
          <div>
            <div>
              <strong>{translation.branch[lang]}</strong>
              <span>{showFil?.name ?? '-'}</span>
            </div>
            <div>
              <strong>{translation.service[lang]}</strong>
              <span>{currentService.label[lang]}</span>
            </div>
            <div>
              <strong>{translation.from[lang]}</strong>
              <span>{startT}</span>
            </div>
            <div>
              <strong>{translation.toClient[lang]}</strong>
              <span>{endT}</span>
            </div>
          </div>
        }
        onConfirm={handleClickConfirm}
        okText={translation.confirm[lang]}
        cancelText={translation.cancel[lang]}
      >
          {translation.signUp[lang]}
      </Popconfirm>
    </div>
  );
};

export default ClientHotlineSubmitBtn;
