const defaultSettings = {
  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },
  weekStartingIndex: 6,
};

export const locales = {
  ua: {
    ...defaultSettings,
    months: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
    weekDays: [
      {
        name: 'Понеділок', // used for accessibility
        short: 'Пн', // displayed at the top of days' rows
      },
      {
        name: 'Вівторок',
        short: 'Вт',
      },
      {
        name: 'Середа',
        short: 'Ср',
      },
      {
        name: 'Четвер',
        short: 'Чт',
      },
      {
        name: 'П’ятниця',
        short: 'Пт',
      },
      {
        name: 'Субота',
        short: 'Сб',
      },
      {
        name: 'Неділя',
        short: 'Нд',
      },
    ],
  },
  de: {
    ...defaultSettings,
    months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    weekDays: [
      {
        name: 'Montag', // used for accessibility
        short: 'Mo', // displayed at the top of days' rows
      },
      {
        name: 'Dienstag',
        short: 'Di',
      },
      {
        name: 'Mittwoch',
        short: 'Mi',
      },
      {
        name: 'Donnerstag',
        short: 'Do',
      },
      {
        name: 'Freitag',
        short: 'Fr',
      },
      {
        name: 'Samstag',
        short: 'Sa',
      },
      {
        name: 'Sonntag',
        short: 'So',
      },
    ],
  },
};
