import { useState } from 'react';
import { EditClientProfileForm, CardForPayments, DeleteAccount } from '../../components';

import './ClientProfile.scss';

const ClientProfile = () => {
  const [isProfileEditing, setIsProfileEditing] = useState(false);

  return (
    <div className="clientProfile_page">
      <EditClientProfileForm isProfileEditing={isProfileEditing} setIsProfileEditing={setIsProfileEditing} />
      {isProfileEditing ? <DeleteAccount /> : null}

      <CardForPayments />
    </div>
  );
};

export default ClientProfile;
