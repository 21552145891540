import React, { useState, useEffect, useRef } from 'react';
import { DownOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { PaymentManagement, EditableContractName } from '../../../components';

import './DetailsBalance.scss';

const DetailsBalance = ({ contract, debtContract }) => {
  const [isFullOpen, setIsFullOpen] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const detailsRef = useRef(null);

  const onExpandDetailsHandle = () => {
    setIsFullOpen(true);
  };

  useEffect(() => {
    if (detailsRef.current) {
      const height = detailsRef.current.offsetHeight;
      setIsButtonVisible(height > 350);
    }
  }, []);

  return (
    <div ref={detailsRef} className={clsx('contract-details-balance', isFullOpen ? '' : 'hide')}>
      <EditableContractName contract={contract} className="contract-details-name" />
      <PaymentManagement debtContract={debtContract} contract={contract} totalBalance={true} expandDetails={onExpandDetailsHandle} />
      {isButtonVisible && !isFullOpen && (
        <button onClick={onExpandDetailsHandle} className="btn-expand">
          <DownOutlined />
        </button>
      )}
    </div>
  );
};

export default DetailsBalance;
