import React, { useState } from 'react';
import { SendOutlined, WhatsAppOutlined } from '@ant-design/icons';

import { Box, NiceBtn } from 'common_components';
import {
  PHONE_OFFICE_VIEW_CRM,
  PHONE_OFFICE_VIEW_CLIENT,
  TELEGRAM_CHAT_OFFICE_VIEW_CRM,
  VIBER_CHAT_OFFICE_VIEW_CRM,
} from 'common_constants/business';
import { WEB_SITE_LINK, CLIENT_LINK } from 'common_constants/routes';
import { translation } from 'common_constants/translation';

import './HotlineMeetInvite.scss';

export default function Information({ lang, allOfficeHotline, filData, meetData }) {
  return (
    <>
      {allOfficeHotline && (
        <>
          <h3>
            {' '}
            {translation.clientContactUsInfo1[lang]} {filData.address}
          </h3>
          {translation.filiiState[lang]} {filData.address_description}
          <br />
          {filData.video && (
            <iframe
              width="100%"
              height="315"
              src={filData.video}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
          <br />
          <br />
        </>
      )}
      {translation.hotlineMeetInviteCardText3[lang]}
      <br />
      <br />
      {allOfficeHotline && (
        <>
          {translation.hotlineMeetInviteCardText4[lang]}
          <br />- {translation.hotlineMeetInviteCardText5[lang]}
          <br />- {translation.hotlineMeetInviteCardText6[lang]}
          <br />
        </>
      )}
      <Box
        style={{
          textAlign: 'center',
          margin: '24px auto 8px',
        }}
      >
        <a rel="noopener noreferrer" target="_blank" href={CLIENT_LINK}>
          <NiceBtn> {meetData.chn ? translation.hotlineMeetInviteCardNiceBtn1[lang] : translation.hotlineMeetInviteCardNiceBtn2[lang]}</NiceBtn>
        </a>
      </Box>
      <br />
      {meetData.chn ? translation.hotlineMeetInviteCardMeetData1[lang] : translation.hotlineMeetInviteCardMeetData2[lang]}
      <br />
      <br />
      {translation.hotlineMeetInviteCardText7[lang]}
      <br />
      {translation.hotlineMeetInviteCardText8[lang]}
      <br />
      <br />
      {translation.hotlineMeetInviteCardText9[lang]}
      <br />
      <a href={PHONE_OFFICE_VIEW_CRM}>{PHONE_OFFICE_VIEW_CLIENT}</a>
      <br />
      <br />
      {translation.hotlineMeetInviteCardText10[lang]}
      <div>
        <span>
          <a rel="noopener noreferrer" target="_blank" href={TELEGRAM_CHAT_OFFICE_VIEW_CRM}>
            <SendOutlined />
            &nbsp;{translation.telegram[lang]}
          </a>
          &nbsp;та&nbsp;
          <a rel="noopener noreferrer" target="_blank" href={VIBER_CHAT_OFFICE_VIEW_CRM}>
            <WhatsAppOutlined />
            &nbsp;{translation.viber[lang]}
          </a>
        </span>
      </div>
      <br />
      {translation.hotlineMeetInviteCardText11[lang]}
      <br />
      <a rel="noopener noreferrer" target="_blank" href={WEB_SITE_LINK}>
        {WEB_SITE_LINK}
      </a>
      <br />
    </>
  );
}
