import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { translation } from 'common_constants/translation';
import { CLIENT_LINK } from 'common_constants/routes';
import clsx from 'clsx';

import { BalanceNewButton } from '../../../components/Balance';
import { error, request } from '../../../tools';
import { Spin } from '../../../components';
import QRCode from '../../../components/QRCode';

import './InviteButtons.scss';

const InviteButtonsModal = ({ handleOpenScanner, setInviteState }) => {
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);

  const [shareLink, setShareLink] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClipboardCopy = () => {
    setIsCopied(true);
  };

  const getInviteLinkToken = async () => {
    setIsLoading(true);

    await request(
      '/partners/generateInviteLinkToken',
      {},
      ({ inviteLinkToken }) => {
        setShareLink(`${CLIENT_LINK}/i/${inviteLinkToken}`);
      },
      error,
    );

    setIsLoading(false);
  };

  useEffect(() => {
    if (clientInfo?.inviteLinkToken) {
      setShareLink(`${CLIENT_LINK}/i/${clientInfo.inviteLinkToken}`);
      return;
    }

    getInviteLinkToken();
  }, []);

  const inviteText = translation.inviteText[lang].replace('$LINK$', shareLink);

  return (
    <div className="invite-friend-share-link">
      <Spin spinning={isLoading}>
        <div className="">
          <div className="share-wrapper">
            <BalanceNewButton
              style={{ backgroundColor: 'green', color: 'white' }}
              onClick={() => {
                setInviteState(true);
              }}
              buttonText={translation.inviteClient[lang]}
            />
            <br />
            <hr />
            <h3 style={{ textAlign: 'center' }}>{translation.otherWayToInvite[lang]}</h3>
            {translation.shareLinkTitle[lang]}:
            <br />
            <br />
            <CopyToClipboard text={shareLink} onCopy={handleClipboardCopy}>
              <div className={clsx('copy-clipboard-link', isCopied ? '_copied' : '')}>
                <p>{shareLink}</p>
                <button className="copy-clipboard-btn">{isCopied ? <CheckOutlined /> : <CopyOutlined />}</button>
              </div>
            </CopyToClipboard>
            {/* Cканувати QR-код:
            <br />
            <br /> */}
            {/* <QRCodeScanButton className="qr-link" onClick={handleOpenScanner} label={translation.scanQRcode?.[lang] ?? ''} /> */}
            {/* <div className="buttons-wrapper"> */}
            {/* </div> */}
            <div className="share-links-wrapper">
              <FacebookShareButton url={inviteText}>
                <FacebookIcon className="share-soc-link-icon" size={40} round />
              </FacebookShareButton>
              <TelegramShareButton url={inviteText}>
                <img
                  width={40}
                  height={40}
                  src="https://upload.wikimedia.org/wikipedia/commons/8/83/Telegram_2019_Logo.svg"
                  alt="telegram"
                  className="share-soc-link-icon"
                />
                {/* <TelegramIcon className="share-soc-link-icon" size={40} round /> */}
              </TelegramShareButton>
              <TwitterShareButton url={inviteText}>
                <img
                  width={40}
                  height={40}
                  src="https://upload.wikimedia.org/wikipedia/commons/c/cc/X_icon.svg"
                  alt="x"
                  className="share-soc-link-icon"
                />
                {/* <TwitterIcon className="share-soc-link-icon" size={40} round /> */}
              </TwitterShareButton>
              <ViberShareButton url={inviteText}>
                <ViberIcon className="share-soc-link-icon" size={40} round />
              </ViberShareButton>
              <WhatsappShareButton url={inviteText}>
                <WhatsappIcon className="share-soc-link-icon" size={40} round />
              </WhatsappShareButton>
            </div>
            <br />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <QRCode value={shareLink} size={150} icon="/emblema-min4.png" iconSize={30} />
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default InviteButtonsModal;
