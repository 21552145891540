import React from 'react';
import { useForm } from 'react-hook-form';
import { SelectNew } from '../../../components';
import { SERVICES_FOR_CLINETS_APP } from 'common_constants/business';
import { translation } from 'common_constants/translation';
import { useSelector } from 'react-redux';

export default function SelectType({ type, onChange }) {
  const { control } = useForm();
  const lang = useSelector((state) => state.common.language);
  const options = SERVICES_FOR_CLINETS_APP.map((elem) => ({ value: String(elem.index), label: elem.label[lang] }));

  return (
    <SelectNew
      placeholder={translation.clientHotline.selectHotlineType[lang]}
      requried
      name="type"
      options={options}
      value={type}
      onChange={onChange}
      control={control}
      className="ant-select type"
    />
  );
}
