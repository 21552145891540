import './FirstloginBonus.scss';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Modal } from '../../components';
import { setClientBonusBalance, setModal } from '../../store/commonReducer';
import { error, request } from '../../tools';
import { translation } from 'common_constants/translation';
import { setConfetti } from '../../store/uiReducer';
import { FIRST_LOGIN_BONUS_VALUE } from 'common_constants/business';

const FirstloginBonus = () => {
  const dispatch = useDispatch();
  const bonus = useSelector((state) => state.common?.clientInfo?.B);
  const lang = useSelector((state) => state.common.language);

  const onClose = () => {
    dispatch(setModal());
    dispatch(setConfetti({ visible: false }));
    request(
      '/clients/claimFirstLogin',
      {},
      () => {
        dispatch(setClientBonusBalance((bonus ?? 0) + FIRST_LOGIN_BONUS_VALUE));
      },
      error,
    );
  };

  return (
    <Modal title={translation.firstLoginBonusTitle[lang]} open footer={false} onCancel={onClose} className={'FirstloginBonus'}>
      <p>{translation.firstLoginBonusText1[lang]}</p>
      <Button onClick={onClose}>{translation.firstLoginAccept[lang]}</Button>
    </Modal>
  );
};

export default FirstloginBonus;
