import { translation } from 'common_constants/translation';

const Description = ({ insuranceType, lang = 'ua' }) => {
  const formatPrice = (price) => {
    const priceStr = price?.toString();
    const mainPart = priceStr?.slice(0, -3);
    const lastThreeDigits = priceStr?.slice(-3);
    return `${mainPart} ${lastThreeDigits}`;
  };

  const econom = (big, small) => {
    return big - small;
  };

  return (
    <div className="insuranceType_description" style={{ padding: 16 }}>
      <p>
        <strong>{translation.contractConditions[lang]}</strong>
      </p>
      <p>&nbsp;</p>
      <p>{translation.activationText[lang]}</p>
      <p>&nbsp;</p>
      <p>
        <strong>{translation.definitionOfTerms[lang]}</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <em>{translation.legalInsurance[lang]}</em>
        </strong>
        &nbsp;-
        {translation.legalInsuranceDescription[lang]}
      </p>
      <p>
        <strong>{translation.cases[lang]}</strong>
        <br />
        {insuranceType?.[lang]?.description?.split('\n').map((item, index) => (
          <p style={{ margin: '8px 0 0 8px' }} key={index}>
            {item}
          </p>
        ))}
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>{translation.insuranceCost[lang]}</strong>
      </p>
      <p>&nbsp;</p>
      <table border={1} cellPadding={0} cellSpacing={0}>
        <tbody>
          <tr>
            <td width="226">
              <p>
                <strong>{translation.insuranceTerm[lang]}</strong>
              </p>
            </td>
            <td width="226">
              <p>
                <strong>{translation.insurancePayment[lang]}</strong>
              </p>
            </td>
            <td width="226">
              <p>
                <strong>{translation.insuranceAmount[lang]}</strong>
              </p>
            </td>
            <td width="226">
              <p>
                <strong>{translation.saving[lang]}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="226">
              <p>{translation.months6[lang]}</p>
            </td>
            <td width="226">
              <p>{formatPrice(insuranceType?.minimalPrice[6])}</p>
            </td>
            <td width="226">
              <p>{formatPrice(insuranceType?.minimalPrice[6] * 10)}</p>
            </td>
            <td width="226">
              <p>0</p>
            </td>
          </tr>
          <tr>
            <td width="226">
              <p>{translation.year1[lang]}</p>
            </td>
            <td width="226">
              <p>{formatPrice(insuranceType?.minimalPrice[12])}</p>
            </td>
            <td width="226">
              <p>{formatPrice(insuranceType?.minimalPrice[12] * 10)}</p>
            </td>
            <td width="226">
              <p>{econom(insuranceType?.minimalPrice[6] * 2, insuranceType?.minimalPrice[12])}</p>
            </td>
          </tr>
          <tr>
            <td width="226">
              <p>{translation.years3[lang]}</p>
            </td>
            <td width="226">
              <p>{formatPrice(insuranceType?.minimalPrice[36])}</p>
            </td>
            <td width="226">
              <p>{formatPrice(insuranceType?.minimalPrice[36] * 10)}</p>
            </td>
            <td width="226">
              <p>{econom(insuranceType?.minimalPrice[6] * 6, insuranceType?.minimalPrice[36])}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p
        style={{
          marginTop: 16,
        }}
      >
        <strong>{translation.priceList[lang]}</strong>
      </p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <p>
        {insuranceType?.[lang]?.services?.map((el, index) => (
          <p style={{ margin: '8px 0 0 8px' }} key={index}>
            {index + 1}. {el.name} &ndash; {formatPrice(el.cost)} {translation.perHour[lang]};
          </p>
        ))}
      </p>
    </div>
  );
};

export default Description;
