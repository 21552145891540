import React from 'react';
import clsx from 'clsx';

import './ChatContextMenu.scss';

const ChatContextMenu = ({ rightClickItem, positionX, positionY, isToggled, contextMenuRef, buttons }) => {
  return (
    <menu
      ref={contextMenuRef}
      className={clsx('context-menu', isToggled && buttons?.length > 0 && 'active')}
      style={{ top: positionY + 2 + 'px', left: positionX + 2 + 'px' }}
    >
      {buttons?.map((item, index) => {
        function handleClick(e) {
          e.stopPropagation();
          item?.onClick(e, rightClickItem);
        }

        return (
          <button onClick={handleClick} key={index}>
            <item.icon />
            <span>{item.text}</span>
          </button>
        );
      })}
    </menu>
  );
};

export default ChatContextMenu;
