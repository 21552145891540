import React from 'react';
import { useHistory } from 'react-router-dom';
import ModalWindow from '../ModalWindow';
import Button from '../../components/Button';
import { CLIENT_ROUTES } from 'common_constants/routes';
import { CLUB_CARDS_SERVICES } from 'common_constants/business';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';

const ServiceInfoModal = ({ open, setOpen, service, setOpenGiftModal }) => {
  const lang = useSelector((state) => state.common.language);
  const history = useHistory();

  const handleLinkClick = () => {
    if (service === '2') {
      setOpen(false);
      setOpenGiftModal(true);
    } else {
      history.push(CLIENT_ROUTES[CLUB_CARDS_SERVICES[service].LINK].route);
    }
  };

  return (
    <ModalWindow className="service-info-modal" title={translation.clubCardServices?.[service]?.[lang]} setModal={setOpen} modal={open}>
      <div className="service-info-content">
        <h1>{translation?.descriptionOfService?.[lang]}</h1>
        <p>{translation?.serviceDescription?.[service]?.[lang]}</p>
        <div className="info">
          {service === '2' ? (
            <Button onClick={handleLinkClick} className="info-btn">
              {translation.clubCardServices?.[service]?.[lang]}
            </Button>
          ) : (
            <Button onClick={handleLinkClick}>{translation.clubCardServices?.[service]?.[lang]}</Button>
          )}
        </div>
      </div>
    </ModalWindow>
  );
};

export default ServiceInfoModal;
