import { DoubleRightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import 'dayjs/locale/uk';
import 'dayjs/locale/ru';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/cs';

import { translation } from 'common_constants/translation';
import { useSelector } from 'react-redux';
import { SERVICE_PAYMENT_TYPE, CURRENCIES, SERVICE_PAYMENT_TYPE_FOR_EADVOKAT } from 'common_constants/business';

import './TransactionsButton.scss';
import emptyHotline from './../../../images/empty-hotlines.svg';

dayjs.extend(isSameOrAfter);

const TransactionsButton = ({ transactions, bonusesTransactions, insurance, insuranceType, modal, buttonName }) => {
  const lang = useSelector((state) => state.common.language);
  dayjs.locale(`${lang === 'ua' ? 'uk' : lang}`);
  // const lastTransaction = transactions ? transactions[0] : bonusesTransactions ? bonusesTransactions[0] : insurance ? insurance[0] : null;
  let lastTransaction = [];
  if (transactions) {
    lastTransaction = transactions.slice(0, 3);
  } else if (bonusesTransactions) {
    lastTransaction = bonusesTransactions.slice(0, 3);
  } else if (insurance) {
    lastTransaction = insurance.length > 2 ? insurance.slice(insurance.length - 3).reverse() : insurance.reverse();
  }

  return (
    <div onClick={modal ? () => modal(true) : null} className="transactions-button">
      {lastTransaction && lastTransaction.length !== 0 ? (
        <>
          {lastTransaction.map((lastTransaction, index) => (
            <div key={index}>
              {index === 0 && transactions && <div style={{ fontWeight: 500, fontSize: 12 }}>{translation.clientHomeTransactionsTitle2[lang]}</div>}
              {index === 0 && bonusesTransactions && (
                <div style={{ fontWeight: 500, fontSize: 12 }}>{translation.clientHomeTransactionsTitle1[lang]}</div>
              )}
              {index === 0 && insurance && <div style={{ fontWeight: 500, fontSize: 12 }}>{translation.transactions[lang]}</div>}
              {transactions && <BalanceButton lastTransaction={lastTransaction} buttonName={buttonName} />}
              {bonusesTransactions && <BonusesButton lastTransaction={lastTransaction} buttonName={buttonName} />}
              {insurance && <InsuranceButton lastTransaction={lastTransaction} insuranceType={insuranceType} buttonName={buttonName} lang={lang} />}
            </div>
          ))}
          <div className="show-all-transactions" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 24 }}>
            <span style={{ marginBottom: 6 }}>{translation.showAll[lang]}</span>
            <DoubleRightOutlined style={{ transform: 'rotate(90deg)', fontSize: '16px' }} />
          </div>
        </>
      ) : (
        <div className="no-transactions-button">
          <div className="capitalize-first-letter">{translation.noTransactions[lang]}</div>
          <img src={emptyHotline} alt="emptyHotline" />
        </div>
      )}
    </div>
  );
};

const BonusesButton = ({ lastTransaction, buttonName }) => {
  const lang = useSelector((state) => state.common.language);
  dayjs.locale(`${lang === 'ua' ? 'uk' : lang}`);
  const transactionTime = dayjs(lastTransaction?.T).format('DD MMM YYYY');
  const getTransactionText = (transaction) => {
    if (transaction?.b + transaction?.p === 0) {
      return translation.transactionsCashbackForBonuses[lang];
    } else if (transaction?.b > 0) {
      return translation.transactionsAccrualBonuses[lang];
    } else {
      return;
    }
  };
  return (
    <>
      <div className="transactions-button-text">
        <span>{buttonName}</span>
        {/* <div className="transactions-arrow-button"></div> */}
      </div>
      <div className="transactions-bonuses">
        <div className="transactions-bonuses-operation">
          {lastTransaction.de ? (
            <span className="error">{translation.transactionsRejected[lang]}</span>
          ) : (
            !lastTransaction.a && translation.transactionsPending[lang]
          )}
          <div className="transactions-bonuses-operation-text" style={{ fontSize: '10px', fontWeight: '500' }}>
            {getTransactionText(lastTransaction)}
          </div>
          <div className="transactions-bonuses-operation-date">{transactionTime}</div>
        </div>
        <div className={`transactions-bonuses-value ${lastTransaction?.b < 0 ? 'negative-value' : 'positive-value'}`}>
          {lastTransaction?.b < 0 ? `-₴${Math.abs(lastTransaction?.b)}` : `₴${lastTransaction.b}`}
        </div>
      </div>
    </>
  );
};

const BalanceButton = ({ lastTransaction, buttonName }) => {
  const lang = useSelector((state) => state.common.language);
  dayjs.locale(`${lang === 'ua' ? 'uk' : lang}`);
  const transactionTime = lastTransaction?.date
    ? dayjs(lastTransaction?.date).format('DD MMM YYYY')
    : dayjs(lastTransaction?.order_time).format('DD MMM YYYY');

  return (
    <>
      <div className="transactions-button-text">
        <span>{buttonName}</span>
        {/* <div className="transactions-arrow-button"></div> */}
      </div>
      <div className="transactions-bonuses">
        <div className="transactions-bonuses-operation">
          <div className="transactions-bonuses-operation-text" style={{ fontSize: '10px', fontWeight: '500' }}>
            {lastTransaction?.type
              ? SERVICE_PAYMENT_TYPE_FOR_EADVOKAT[lastTransaction?.type]?.translations[lang]
              : translation.replenishmentBalance[lang]}
          </div>
          <div className="transactions-bonuses-operation-date">{transactionTime}</div>
        </div>
        <div
          className={`transactions-bonuses-value ${
            !lastTransaction?.type || [9, 10, 13, 17, 19, 22, 24].includes(lastTransaction?.type) ? 'positive-value' : 'negative-value'
          }`}
        >
          {!lastTransaction?.type || [9, 10, 13, 17, 19, 22, 24].includes(lastTransaction?.type)
            ? `${lastTransaction.currency ? CURRENCIES[lastTransaction.currency]?.symbol || '₴' : '₴'}${lastTransaction?.amount}`
            : `-${lastTransaction.currency ? CURRENCIES[lastTransaction.currency]?.symbol || '₴' : '₴'}${Math.abs(lastTransaction?.amount)}`}
        </div>
      </div>
    </>
  );
};

const InsuranceButton = ({ lastTransaction = [], buttonName, insuranceType, lang }) => {
  dayjs.locale(`${lang === 'ua' ? 'uk' : lang}`);
  const transactionTime = dayjs(lastTransaction?.date).format('DD MMM YYYY');
  const sum = lastTransaction?.price * lastTransaction?.multiplier;

  return (
    <>
      <div className="transactions-button-text">
        <span>{buttonName}</span>
        {/* <div className="transactions-arrow-button"></div> */}
      </div>
      <div style={{ ':not(:lastChild)': { marginBottom: '8px' } }}>
        <div key={transactionTime} className="transactions-bonuses">
          <div className="transactions-bonuses-operation">
            <div className="transactions-bonuses-operation-text" style={{ fontSize: '10px', fontWeight: '500' }}>
              {`${insuranceType?.[lang]?.services[lastTransaction?.type]?.name} - ${lastTransaction?.multiplier} год.`}
            </div>
            <div className="transactions-bonuses-operation-date">{transactionTime}</div>
          </div>
          <div className={`transactions-bonuses-value negative-value`}>-₴{Math.abs(sum)}</div>
        </div>
      </div>
    </>
  );
};

export default TransactionsButton;
