import React from 'react';
import { useSelector } from 'react-redux';
import { SendOutlined, PaperClipOutlined, CloseOutlined } from '@ant-design/icons';
import { translation } from 'common_constants/translation';
import clsx from 'clsx';

import { Button, InputNew as Input } from '..';

const InputFormWrapper = ({
  handleAttachFile,
  handleSend,
  handleInput,
  fileInputRef,
  sendLoader,
  inputValue,
  onCloseEditingModeButton,
  editMode,
}) => {
  const lang = useSelector((state) => state.common.language);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSend();
    }
  };

  return (
    <div className="input-container">
      <div className={clsx('edit-mode', !editMode.isEditing && '_hidden')}>
        <div className="block">
          <p className="editing-text">Редагування</p>
          <p className="prev-message">{editMode.prevMessageValue}</p>
        </div>
        <Button className="close-btn" onClick={onCloseEditingModeButton} icon={<CloseOutlined />} type="text" />
      </div>
      <div className="new-message-wrapper">
        <input type="file" onChange={handleAttachFile} style={{ display: 'none' }} ref={fileInputRef} multiple aria-label="download files" />
        <Button
          style={{ margin: '0px', width: 32 }}
          variant="orange straight-right"
          onClick={() => fileInputRef.current.click()}
          loading={sendLoader}
          icon={<PaperClipOutlined />}
        />
        <Input
          style={{ borderRadius: '0px' }}
          placeholder={translation.inputQuickQuestion[lang]}
          value={inputValue}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
        />
        <Button
          variant="default straight-left"
          style={{ margin: '0px', width: 32 }}
          onClick={handleSend}
          loading={sendLoader}
          icon={<SendOutlined />}
        />
      </div>
    </div>
  );
};

export default InputFormWrapper;
