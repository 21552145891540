import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { translation } from 'common_constants/translation';
import { RED_BUTTON } from 'common_constants/business';
import dayjs from 'dayjs';

import { request, success, warn, error } from '../../tools';
import { editContracts } from '../../store/commonReducer';
import { Spin, Typography } from '../';

import './EditableContractName.scss';

const { Paragraph } = Typography;

const EditableContractName = ({ contract, setIsEditing = () => {}, ...props }) => {
  const dispatch = useDispatch();
  const [megaState, setMegaState] = useState({
    loading: false,
    isEditing: false,
    editableStr: null,
  });
  const lang = useSelector((state) => state.common.language);

  const statNameContract = (
    <>{contract.cn === RED_BUTTON.value ? translation.redButton[lang] : contract.cn || `${translation.contractItem[lang]} №${contract?.i}`}</>
  );

  const statNameContractAndEditIt = (
    <Spin size="small" spinning={megaState.loading}>
      <Paragraph
        editable={{
          onStart: () => {
            setIsEditing(true);
            setMegaState((prev) => ({ ...prev, isEditing: true }));
          },
          onCancel: () => {
            setIsEditing(false);
            setMegaState((prev) => ({ ...prev, isEditing: false }));
          },
          onSave: (value) => handleSubmit(value),
          onEnd: () => {
            setIsEditing(false);
            setMegaState((prev) => ({ ...prev, isEditing: false }));
          },
        }}
        children={megaState.editableStr ?? contract?.n ?? statNameContract}
      />
    </Spin>
  );

  const handleSubmit = async (value) => {
    const validation = (_contractId, _newNameContract) => {
      if (!_contractId) {
        warn('', 'Для редагування назви, потрібно вказати діючий контракт.', { contractId: !!contract._id });
        return;
      }

      if (!_newNameContract) {
        warn('', 'Для редагування назви, потрібно написати саму цю назву.', { newNameContract: !!megaState.editableStr });
        return;
      }

      return true;
    };

    if (!validation(contract._id, value)) return;

    setMegaState((prev) => ({ ...prev, loading: true }));

    const body = { contractId: contract._id, newNameContract: value };

    await request(
      '/contracts/editNameContract',
      body,
      (req) => {
        success();

        if (req.name) {
          const _contract = {
            ...contract,
            n: req.name,
          };
          dispatch(editContracts(_contract));
        }

        setIsEditing(false);
        setMegaState((prev) => ({ ...prev, editableStr: req.name, isEditing: false }));
      },
      () => {
        error();
      },
    );

    setMegaState((prev) => ({ ...prev, loading: false }));
  };

  return <div className="contract-name" children={statNameContractAndEditIt} {...props} />;
};

export default EditableContractName;
