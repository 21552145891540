import CurrentClubCard from '../../components/CurrentClubCard';
import './ClubCardGiftModal.scss';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Modal } from '../../components';
import { setModal } from '../../store/commonReducer';
import { request } from '../../tools';
import dayjs from 'dayjs';
import { translation } from 'common_constants/translation';
import { setConfetti } from '../../store/uiReducer';

const ClubCardGiftModal = () => {
  const dispatch = useDispatch();
  const clubCard = useSelector((state) => state.common?.clientInfo?.clubCard);
  const lang = useSelector((state) => state.common.language);

  const onClose = () => {
    dispatch(setModal());
    dispatch(setConfetti({ visible: false }));
    request('/clubCards/claimGift', { cardId: clubCard._id }, () => {});
  };

  return (
    <Modal title={translation.ClubCardGift[lang]} open footer={false} onCancel={onClose} className={'clubCardGiftModal'}>
      <CurrentClubCard />
      <p>
        <b>{translation.clubCardGiftValidity[lang]}: </b>
        <span>
          {dayjs(clubCard.createdAt).format('DD.MM.YYYY')}
          {' - '}
          {dayjs(clubCard.expiredAt).format('DD.MM.YYYY')}
        </span>
      </p>
      <Button onClick={onClose}>{translation.clubCardGiftAccept[lang]}</Button>
    </Modal>
  );
};

export default ClubCardGiftModal;
