import dayjs from 'dayjs';
import { CLIENT_TOKEN } from 'common_constants/business';
import axios from 'axios';
import reactNativeService from './reactNativeService';
import { CLIENT_ROUTES } from 'common_constants/routes';
import { store } from '../store/store';
import { addToast } from '../store/toastReducer';
import { COUNTRY_PHONE_CODES } from 'common_constants/business';

const warn = (text, description, noteLog) => {
  const bodyNotif = {
    title: text || 'Увага!',
    description: description || 'Щось пішло не так',
    variant: 'warning',
    id: Date.now(),
  };

  if (noteLog) {
    bodyNotif.onClose = () => {
      console.group('Notification Closed Warn!');
      Array.isArray(noteLog) || typeof noteLog === 'object' ? console.table(noteLog) : console.log(noteLog);
      console.groupEnd('Notification Closed Warn!');
    };
  }

  store.dispatch(addToast(bodyNotif));
}; // при неправильних введених даних адвокатом

const success = (text, description, noteLog) => {
  const bodyNotif = {
    title: text || 'Успіх!',
    id: Date.now(),
    variant: 'success',
  };

  if (description) bodyNotif.description = description;
  if (noteLog) {
    bodyNotif.onClose = () => {
      console.group('Notification Closed Success!');
      Array.isArray(noteLog) || typeof noteLog === 'object' ? console.table(noteLog) : console.log(noteLog);
      console.groupEnd('Notification Closed Success!');
    };
  }

  store.dispatch(addToast(bodyNotif));
};

const error = (text, description, noteLog) => {
  const bodyNotif = {
    title: text || 'Помилка!',
    description: description || 'Щось пішло не так',
    id: Date.now(),
    variant: 'error',
  };

  if (noteLog) {
    bodyNotif.onClose = () => {
      console.group('Notification Closed Error!');
      Array.isArray(noteLog) || typeof noteLog === 'object' ? console.table(noteLog) : console.log(noteLog);
      console.groupEnd('Notification Closed Error!');
    };
  }

  store.dispatch(addToast(bodyNotif));
}; // при помилках на сервері чи інших error

const info = (text, description, noteLog, lang) => {
  let titleText = '';

  switch (lang) {
    case 'ru': {
      titleText = 'Информация!';
      break;
    }
    case 'de': {
      titleText = 'Information!';
      break;
    }
    case 'en': {
      titleText = 'Information!';
      break;
    }
    case 'fr': {
      titleText = 'Informations!';
      break;
    }
    case 'es': {
      titleText = 'Información!';
      break;
    }
    case 'cs': {
      titleText = 'Informace!';
      break;
    }
    default: {
      titleText = 'Інформація!';
      break;
    }
  }

  const bodyNotif = {
    id: Date.now(),
    title: text || titleText,
    variant: 'info',
  };

  if (description) bodyNotif.description = description;
  if (noteLog) {
    bodyNotif.onClose = () => {
      console.group('Notification Closed Info!');
      Array.isArray(noteLog) || typeof noteLog === 'object' ? console.table(noteLog) : console.log(noteLog);
      console.groupEnd('Notification Closed Info!');
    };
  }

  store.dispatch(addToast(bodyNotif));
};

const coreConsoleLog = (_note, _title = dayjs().format('T HH:mm:ss Z [Z]')) => {
  console.group(_title);
  Array.isArray(_note) || typeof _note === 'object' ? console.table(_note) : console.log(_note);
  console.groupEnd(_title);
};

const formatBytes = (bytes) => {
  if (bytes < 1024) {
    return bytes + ' bytes';
  } else if (bytes < 1048576) {
    return (bytes / 1024).toFixed(2) + ' kb';
  } else {
    return (bytes / 1048576).toFixed(2) + ' mb';
  }
};

const request = (url, data, callback, onError) => {
  const token = window.localStorage.getItem('clientAccessToken');

  return fetch(process.env.REACT_APP_API + url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: data && JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((...params) => {
      if (!params[0].status) {
        if (onError) onError('', '', params[0]);
        else error('', '', params[0]);
        return;
      }
      return callback(...params);
    })
    .catch(
      onError ??
        ((err) => {
          error('', '', err);
        }),
    );
};

request.get = (url, callback, onError) => {
  const token = window.localStorage.getItem('clientAccessToken');

  return fetch(process.env.REACT_APP_API + url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((res) => res.json())
    .then((...params) => {
      if (!params[0].status) {
        if (onError) onError('', '', params[0]);
        else error('', '', params[0]);
        return;
      }
      return callback(...params);
    })
    .catch(
      onError ??
        ((err) => {
          error('', '', err);
        }),
    );
};

const clientLogOut = () => {
  window.localStorage.removeItem(CLIENT_TOKEN);
  sessionStorage.removeItem('modalFirst');
  window.location.href = '/clientLogin';
};

const prepareUsers = (list) => {
  window.users = {};
  const _users = {};
  list.forEach((i) => {
    window.users[i.username] = i;
    _users[i._id] = i;
  });
  return _users;
};
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
const requestFile = (url, data, callback, onError) => {
  try {
    const token = window.localStorage.getItem('clientAccessToken');

    reactNativeService.sendMessageToWebview({
      type: 'requestForDownloadFile',
      data: { url: process.env.REACT_APP_API + url, data: data, token: token },
    });

    axios
      .post(process.env.REACT_APP_API + url, data, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.data.type });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.fileName ?? 'no_name'); //* ім`я файлу треба записувати через body API Fetch ключем fileName
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        callback(response.data);
      })
      .catch((err) => {
        coreConsoleLog(err);
        typeof onError === 'function' ? onError('', '', err) : error('', '', 'Помилка завантаження файлу');
      });
  } catch (err) {
    coreConsoleLog(err);
    typeof onError === 'function' ? onError('', '', err) : error('', '', 'Помилка завантаження файлу');
  }
};

const postFile = (url, data, callback, onError) => {
  try {
    const token = window.localStorage.getItem('clientAccessToken');
    const headers = { Authorization: `Bearer ${token}` };

    axios
      .post(`${process.env.REACT_APP_API}${url}`, data, {
        headers: headers,
      })
      .then((response) => {
        if (!response.data.status) {
          if (typeof onError === 'function') onError('', '', response.data);
          else error('', response.data.errorMessage, response.data);
          return;
        }
        return callback(response.data);
      })
      .catch((err) => {
        throw err;
      });
  } catch (err) {
    coreConsoleLog(err);
    if (typeof onError === 'function') onError('', '', err);
  }
};

const getTokenData = (token) => (token ? JSON.parse(window.atob(token.split('.')[1])) : false);

const countContractDebt = (contract) => {
  const today = dayjs();
  const monthlyPaymentSum =
    contract?.monthlyPaymentsManagement?.reduce((acc, item) => (dayjs(item?.date).startOf('day') < today ? acc + Number(item?.amount) : acc), 0) || 0;
  let checkSum = 0;
  if (Number(contract?.fp) < Number(contract?.pa) && !contract?.paymentManagement?.length) {
    checkSum = +contract?.pa || 0;
  } else {
    checkSum = +contract?.fp || 0;
  }
  const installimentSum =
    contract?.paymentManagement?.reduce((acc, item) => (dayjs(item?.date).startOf('day') < today ? acc + Number(item?.amount) : acc), 0) || 0;

  const filteredAssignments =
    contract?.assignments?.filter((item) => !item?.incorrect && dayjs(item?.date).startOf('day') > dayjs(contract?.md)) || [];

  const assignmentsSum = filteredAssignments?.reduce((acc, item) => acc + Number(item?.clientAmount), 0) || 0;

  const totalToPay = Number(monthlyPaymentSum) + Number(installimentSum) + Number(assignmentsSum) + checkSum;

  const debt = totalToPay - contract?.totalSugar;
  return debt;
};

const returnByHistory = (pathname, clientAuth) => {
  if (pathname.includes('/contractInfo/')) {
    return CLIENT_ROUTES.CLIENT_CONTRACTS.route;
  } else if (
    pathname === CLIENT_ROUTES.CLIENT_REGISTRATION.route ||
    pathname === CLIENT_ROUTES.CLIENT_CHANGE_PASSWORD.route ||
    pathname.includes('/clientConfirmNewPassword/')
  ) {
    return CLIENT_ROUTES.CLIENT_LOGIN.route;
  } else if (pathname.includes('/pfh/')) {
    return CLIENT_ROUTES.CLIENT_HOTLINE.route;
  } else if (pathname.includes('/filiiState/')) {
    return CLIENT_ROUTES.CLIENT_CONTACT_US.route;
  } else if (pathname === CLIENT_ROUTES.CREATE_TENDER.route) {
    return CLIENT_ROUTES.CLIENT_TENDERS.route;
  } else if (pathname === CLIENT_ROUTES.CLIENT_HOTLINE.route) {
    return CLIENT_ROUTES.CLIENT_HOTLINE.route;
  } else {
    return clientAuth ? CLIENT_ROUTES.CLIENT_HOME.route : CLIENT_ROUTES.CLIENT_LOGIN.route;
  }
};

const getMaxPhoneLength = (countryPhCode) => {
  if (countryPhCode === COUNTRY_PHONE_CODES.UA) {
    return 10;
  }
  if (countryPhCode === COUNTRY_PHONE_CODES.DE) {
    return 10;
  }
  if (countryPhCode === COUNTRY_PHONE_CODES.CZ) {
    return 9;
  }
};

const getPhonePattern = (countryPhCode) => {
  if (countryPhCode === COUNTRY_PHONE_CODES.UA) {
    return new RegExp(/^\d{10}$/);
  }
  if (countryPhCode === COUNTRY_PHONE_CODES.DE) {
    return new RegExp(/^\d{9,10}$/);
  }
  if (countryPhCode === COUNTRY_PHONE_CODES.CZ) {
    return new RegExp(/^\d{9}$/);
  }
};

export {
  request,
  getTokenData,
  warn,
  success,
  error,
  info,
  prepareUsers,
  clientLogOut,
  formatBytes,
  requestFile,
  getBase64,
  postFile,
  countContractDebt,
  returnByHistory,
  getMaxPhoneLength,
  getPhonePattern,
};
