import { useEffect, useState, useRef } from 'react';
import { register } from 'swiper/element/bundle';
import 'swiper/css';
import 'swiper/css/effect-cards';

import { CLUB_CARDS_TYPES } from 'common_constants/business';

import Spin from '../Spin/Spin';
import ClubCard from '../ClubCard';

import './SwiperClubCard.scss';

register();

const SwiperClubCard = ({ initialSlide = 0, setActiveSlide, currentCardType }) => {
  const swiperElRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const cardTypes = Object.values(CLUB_CARDS_TYPES);
  const currentIndex = cardTypes.indexOf(currentCardType.toUpperCase());
  const visibleCardTypes = cardTypes.slice(currentIndex);

  useEffect(() => {
    const swiperInstance = swiperElRef.current?.swiper;

    if (swiperInstance) {
      swiperInstance.params.spaceBetween = 40;
      swiperInstance.on('slideChange', () => {
        setActiveSlide(swiperInstance.activeIndex + currentIndex);
      });

      setIsLoading(false);
    }

    return () => {
      swiperInstance?.off('slideChange');
    };
  }, [setActiveSlide, currentIndex]);

  useEffect(() => {
    setActiveSlide(swiperElRef.current?.swiper.activeIndex + currentIndex);
  }, [currentCardType]);
  return (
    <div className="swiper-clubcards">
      <Spin spinning={isLoading} fill={true}>
        <swiper-container initial-slide={initialSlide} ref={swiperElRef} slides-per-view="1" effect="cards" grab-cursor="true">
          {visibleCardTypes.map((type) => (
            <swiper-slide key={type}>
              <ClubCard cardType={type} />
            </swiper-slide>
          ))}
        </swiper-container>
      </Spin>
    </div>
  );
};

export default SwiperClubCard;
