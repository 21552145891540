import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import { APPROVE_DELETE_ACCOUNT } from 'common_constants/modals';

import { setModal } from '../../store/commonReducer';
import { Button } from '..';

import './DeleteAccount.scss';

const DeleteAccount = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);

  const handleClickButton = () => {
    dispatch(setModal({ name: APPROVE_DELETE_ACCOUNT }));
  };

  return (
    <div className="delete-account-wrapper">
      <Button onClick={handleClickButton} className="delete-button">
        {translation.accountDeletionTitle[lang]}
      </Button>
    </div>
  );
};

export default DeleteAccount;
