import { useDispatch, useSelector } from 'react-redux';
import { languageOptions } from 'common_constants/translation';
import { useForm } from 'react-hook-form';

import SelectNew from '../SelectNew';
import reactNativeService from '../../tools/reactNativeService';
import { setLanguage } from '../../store/commonReducer';

import GB from 'country-flag-icons/react/3x2/GB';
import UA from 'country-flag-icons/react/3x2/UA';
import DE from 'country-flag-icons/react/3x2/DE';
import FR from 'country-flag-icons/react/3x2/FR';
import ES from 'country-flag-icons/react/3x2/ES';
import CZ from 'country-flag-icons/react/3x2/CZ';

import './LanguageSwitcher.scss';
import RU from './RU.jsx';

const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.common.language);
  const { control } = useForm({
    defaultValues: {
      language: new URL(window.location.href)?.searchParams.get('lang') || currentLang,
    },
  });
  const handleLanguageChange = (value) => {
    dispatch(setLanguage(value));
    reactNativeService.sendMessageToWebview({ language: value });
    localStorage.setItem('language', value);
  };

  const optionsWithFlags = languageOptions
    .map((option) => {
      const flagComponents = {
        ua: <UA />,
        en: <GB />,
        de: <DE />,
        fr: <FR />,
        es: <ES />,
        cs: <CZ />,
        ru: <RU />,
      };

      const flag = flagComponents[option.value] || null;

      return {
        ...option,
        label: <div>{flag}</div>,
      };
    })
    .sort((a, b) => (a.value === currentLang ? -1 : 1))
    .filter((i) => i);

  return (
    <SelectNew name="language" control={control} value={currentLang} className="lang" onChange={handleLanguageChange} options={optionsWithFlags} />
  );
};

export default LanguageSwitcher;
