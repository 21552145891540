import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHAT_WITH_ACCOUNTANT } from 'common_constants/modals';
import { FEMALE_ASSISTANTS, MALE_ASSISTANTS } from 'common_constants/business';
import { translation } from 'common_constants/translation';

import UserAvatar from '../../components/UserAvatar';
import { setModal } from '../../store/commonReducer';

const AccountantChatItem = ({ avatars, elem, index, loading }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);

  const allAssistants = [...MALE_ASSISTANTS, ...FEMALE_ASSISTANTS];
  const found = allAssistants.find((ast) => ast.index === elem.assistantIndex);

  const openChatWithAccountant = (_id) => {
    dispatch(
      setModal({
        name: CHAT_WITH_ACCOUNTANT,
        data: {
          _id,
        },
      }),
    );
  };

  return (
    <div className="chats" key={index} onClick={() => openChatWithAccountant(elem._id)}>
      <UserAvatar
        customUrl={avatars[found.index]}
        size={50}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexShrink: 0 }}
      />
      <div className="info">
        <span className="name">{found?.name}</span>
        {loading ? (
          <div className="spinner-opacity"></div>
        ) : (
          <span className="message">{elem?.chat?.[elem?.chat.length - 1]?.message ?? translation.notMessages[lang]}</span>
        )}
      </div>
    </div>
  );
};

export default AccountantChatItem;
