import React from 'react';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';

import { requestFile, error, success } from '../../tools';
import { Image } from '../../components';
import MicrosoftWordIcon from './icons/microsoft-word.svg';

const CourtPracticeItem = ({ item }) => {
  const lang = useSelector((state) => state.common.language);

  const onClickDownloadFile = () => {
    const fileData = {
      fileId: item.fileId,
      fileName: item.fileName,
    };

    requestFile(
      '/profile/courtPractice/downloadFileForClient',
      fileData,
      () => {
        success('', translation.clientCInfDownloadFileSuccess[lang]);
      },
      () => {
        error('', translation.clientCInfDownloadFileError[lang]);
      },
    );
  };

  return (
    <div className="court-practice-item">
      <b className="file-name">{item.name.slice(0, 100)}</b>

      <div className="top-wrapper">
        <div>
          <Image className="file" onClick={onClickDownloadFile} preview={false} src={MicrosoftWordIcon} />
        </div>
        <div>
          <p className="briefly">{item?.briefly}</p>
        </div>
      </div>
      {item?.description ? <p className="description">{item.description?.substr(0, 150)}</p> : null}
    </div>
  );
};

export default CourtPracticeItem;
