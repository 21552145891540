import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../components/Modal';

import { setModal } from '../../store/commonReducer';
import { request, requestFile, error, postFile } from '../../tools';
import Chat from '../../components/Chat';
import { translation } from 'common_constants/translation';

const ChatForTender = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.common.modal);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);
  const users = useSelector((state) => state.common.users);

  const getFiles = '/tenders/chatTenderGetFileClient';
  const sendFiles = '/tenders/chatTenderSendFilesClient';

  const onSendMessage = (params = {}) => {
    if (!params.message) return error(translation.chatForContractError1[lang]);
    if (typeof params.callback !== 'function') return error(translation.chatForContractErrorCallback[lang]);

    request(
      '/tenders/chatTenderSendMessageClient',
      { tenderId: data.tenderId, respondId: data.respondId, message: params.message },
      ({ newMessage }) => {
        params.callback({
          ...newMessage,
          isSentByCurrentUser: newMessage.sender === clientInfo._id,
          name:
            newMessage.sender === clientInfo._id
              ? clientInfo.n
              : users?.find((user) => user._id === newMessage.sender)?.name || translation.chatForContractError4[lang],
        });
      },
      error,
    );
  };

  const onSendFile = (params = {}) => {
    const { formData, callback, onError } = params;

    if (!formData) return error(translation.chatForContractError2[lang]);
    if (typeof callback !== 'function') return error(translation.chatForContractErrorCallback[lang]);

    formData.append('tenderId', data.tenderId);
    formData.append('respondId', data.respondId);

    postFile(
      sendFiles,
      formData,
      ({ newMessage }) => {
        callback({
          data: newMessage,
        });
      },
      onError,
    );
  };

  const onGetFile = (params = {}) => {
    const { fileId, fileName, callback, onError } = params;

    if (!fileId) return error(translation.chatForContractError3[lang]);
    // if (typeof callback !== 'function') return error(translation.chatForContractErrorCallback[lang]);

    requestFile(getFiles, { fileId, fileName, tenderId: data.tenderId, respondId: data.respondId }, callback, onError);
  };

  const onGetMessages = (callback) => {
    if (typeof callback !== 'function') return error(translation.chatForContractErrorCallback[lang]);
    request(
      '/tenders/chatTenderGetClient',
      { tenderId: data.tenderId, respondId: data.respondId },
      (req) => {
        callback(
          req.data?.map((item) => ({
            ...item,
            isSentByCurrentUser: item.sender === clientInfo._id,
            name: item.sender === clientInfo._id ? clientInfo.n : data.lawyer,
          })) ?? [],
        );
      },
      error,
    );
  };

  // if (!data.contractId) {
  //   error('Відсутнє ID договору');
  //   return null;
  // }

  return (
    <Modal open title={translation.chatForTenderTitle[lang]} onCancel={() => dispatch(setModal())} footer={false}>
      <Chat
        title={translation.chatForTenderTitle[lang]}
        myName={clientInfo.n}
        onSendMessage={onSendMessage} //
        onSendFile={onSendFile} //
        onGetMessages={onGetMessages}
        onGetFile={onGetFile}
      />
    </Modal>
  );
};

export default ChatForTender;
